import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import axios from "axios";
import { api_url } from "../../../url";
import { Link, useParams } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import ReactPaginate from "react-paginate";
import AddClassSubjectModal from "./AddClassSubjectModal";
import { RotatingLines } from "react-loader-spinner";
import { toast } from 'react-toastify'
import Swal from "sweetalert2";

const ClassDetail = () => {
  const { class_id } = useParams();
  const navigations = ["Dashboard", "Academics", "Class Detail"];
  const [classDetails, setClassDetails] = useState({});
  const [classStudents, setClassStudents] = useState([])
  const [subjects, setSubjects] = useState([])
  const [teachers, setTeachers] = useState([])
  const [classSubjects, setClassSubjects] = useState([])
  const [addClassSubjectModal, setAddClassSubjectModal] = useState(false);
  const [deletingSubject,setDeletingSubject] = useState(false)
  const [activeTab,setActiveTab] = useState(1)

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = classStudents.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(classStudents.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % classStudents.length;
    setItemOffset(newOffset);
  };

  const getSubjects = async () =>{
    const config = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `JWT ${localStorage.getItem('access')}`
        }
    }
    await axios.get(`${api_url}academics/subjects/`, config).then((response)=>{
        setSubjects(response.data)
        
    }).catch((error)=>{
        console.error(error)
        
    })
}

const getTeachers = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    },
  };

  await axios
    .get(`${api_url}accounts/teachers`, config)
    .then((response) => {
      setTeachers(response.data);
      
    })
    .catch((error) => {
      
    });
};

  const getClassDetails = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
    await axios
      .get(`${api_url}academics/classes/${class_id}/`, config)
      .then((response) => {
        setClassDetails(response?.data);
        setClassStudents(response?.data?.class_students)
        setClassSubjects(response?.data?.class_subjects)
      })
      .catch((error) => {});
  };

  const deleteClassSubject = (subject_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingSubject(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}academics/class-subjects/${subject_id}/`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Class Subject Deleted Successfully",
            }).then(() => {
              setDeletingSubject(false);
              getClassDetails();
            });
          })
          .catch((error) => {
            setDeletingSubject(false);
            
            toast.error(
              "There was an error deleting the class, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getClassDetails();
    getSubjects();
    getTeachers();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {
        addClassSubjectModal && (
          <AddClassSubjectModal teachers={teachers} getClassDetails={getClassDetails} class_id={class_id} setAddClassSubjectModal={setAddClassSubjectModal} addClassSubjectModal={addClassSubjectModal} subjects = {subjects} />
        )
      }
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div>
            <b>Class Name:</b> {classDetails?.class_name}
        </div>
        <div className="section-list">
            <div className="custom-tabs">
              <div onClick={()=>setActiveTab(1)} className={`custom-tab-section ${activeTab ===  1 && "active"}`}>Class Students</div>
              <div onClick={()=>setActiveTab(2)} className={`custom-tab-section ${activeTab ===  2 && "active"}`}>Class Subjects</div>
            </div>
            
            {
              activeTab === 1 && (
                <div className="section-list-table">
                  
                    <table>
                      <thead>
                        <tr>
                          <th>Student ID</th>
                          <th>Student Name</th>
                          <th>Student Class</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems.map((student, index) => (
                            <tr key={index}>
                              <td>{student?.student_id}</td>
                              <td>{student?.student_name}</td>
                              <td>
                                {student?.student_class}
                              </td>
                              <td>
                              <div className="action-buttons">
                                    <Link
                                      to={`/admin/user-management/students/${student?.id}/details`}
                                      className="view-button"
                                    >
                                      View <AiOutlineEye />
                                    </Link>
                                    
                                  </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    
                    {
                      classStudents.length <= 0 && (
                        <NoData />
                      )
                    }
                  <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div>
                </div>
              )
            }
            {
              activeTab === 2 && (
                <div className="section-list-table">
                    <Link
                      onClick={() => setAddClassSubjectModal(true)}
                      className="primary-button-outline w-max-content"
                      style={{ marginBlock: "5px" }}
                    >
                      Add Class Subject
                    </Link>
                    <table>
                      <thead>
                        <tr>
                          <th>Subject Name</th>
                          <th>Subject Teacher</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          classSubjects.map((class_subject, index) => (
                            <tr key={index}>
                              <td>{class_subject?.subject}</td>
                              <td>
                                {class_subject?.teacher}
                              </td>
                              <td>
                              
                                {deletingSubject ? (
                                  <RotatingLines
                                  strokeColor="#021D53"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="26"
                                  visible={true}
                                />
                                ):(
                                  
                                  <div className="action-buttons">
                                    
                                    <Link
                                      onClick={() => deleteClassSubject(class_subject?.id)}
                                      className="delete-button"
                                    >
                                      Delete <AiOutlineDelete />
                                    </Link>
                                  </div>
                                )
                              }
                              
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    
                    {
                      classSubjects.length <= 0 && (
                        <NoData />
                      )
                    }
                  
                </div>
              )
            }
        </div>
      </div>
    </div>
  );
};

export default ClassDetail;
