import axios from "axios";
import React, { useEffect, useState } from "react";
import { api_url } from "../../../../url";
import DashboardNavigation from "../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../../components/Loading/Loading";
import NoData from "../../../../components/PortalComponents/NoData/NoData";
import { toast } from "react-toastify";
import UploadDocument from "./UploadDocument";
import { LuDownload } from "react-icons/lu";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import ReactPaginate from "react-paginate";

const LibraryUploads = () => {
  const [loadingUploads, setLoadingUploads] = useState(true);
  const [libraryUploads, setLibraryUploads] = useState([]);
  const [uploadDocumentModal, setUploadDocumentModal] = useState(false);
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [deletingDocument, setDeletingDocument] = useState(false);

  const navigations = ["Dashboard", "Anell Library", "Uploads"];

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = libraryUploads.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(libraryUploads.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % libraryUploads.length;
    setItemOffset(newOffset);
  };

  const getLibraryUploads = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}library/teacher/uploads/`, config)
      .then((response) => {
        setLibraryUploads(response.data);
        setLoadingUploads(false);
      })
      .catch((error) => {
        toast.error("Failed to get uploads");
        setLoadingUploads(false);
      });
  };

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setSchoolClasses(response.data);
      })
      .catch((error) => {});
  };

  const getSubjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}academics/subjects/`, config)
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((error) => {});
  };

  const deleteUpload = (upload_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingDocument(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}library/teacher/uploads/${upload_id}/`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Document Deleted Successfully",
            }).then(() => {
              setDeletingDocument(false);
              getLibraryUploads();
            });
          })
          .catch((error) => {
            setDeletingDocument(false);

            toast.error(
              "There was an error deleting the class, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getLibraryUploads();
    getClasses();
    getSubjects();
  }, []);

  return (
    <div>
      {uploadDocumentModal && (
        <UploadDocument
          getLibraryUploads={getLibraryUploads}
          subjects={subjects}
          schoolClasses={schoolClasses}
          setUploadDocumentModal={setUploadDocumentModal}
          uploadDocumentModal={uploadDocumentModal}
        />
      )}

      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Your Library Uploads</div>
          <div
            onClick={() => setUploadDocumentModal(true)}
            className="primary-button-outline w-max-content"
            style={{ marginBlock: "5px" }}
          >
            Upload New Document
          </div>
          {loadingUploads ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Document Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {libraryUploads &&
                      currentItems?.map((upload, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{upload?.school_class}</td>
                          <td>{upload?.subject}</td>
                          <td>{upload?.document_name}</td>
                          {deletingDocument ? (
                            <td>
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            </td>
                          ) : (
                            <td style={{ cursor: "pointer" }}>
                              <Link
                                target="_blank"
                                download
                                to={`${api_url}${upload?.document}`}
                                title="Download Document"
                              >
                                <LuDownload />
                              </Link>
                              <div
                                title="Delete Document"
                                style={{ marginLeft: "5px" }}
                                onClick={() => deleteUpload(upload?.id)}
                              >
                                <AiOutlineDelete />
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
                {libraryUploads.length <= 0 && <NoData />}
              </>
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LibraryUploads;
