import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const AddClassSubjectModal = ({getClassDetails, class_id,teachers, subjects, addClassSubjectModal, setAddClassSubjectModal}) => {
    const [data, setData] = useState({
        "school_class":class_id
    });
    const [creatingClassSubject, setCreatingClassSubject] = useState(false);
  
    const handleFormChange = (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    };

    const createClassSubject = async (e) => {
        e.preventDefault();
        setCreatingClassSubject(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
    
        const body = JSON.stringify(data);
        await axios
          .post(`${api_url}academics/teacher-subjects/`, body, config)
          .then((response) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Class Subject Created Successfully",
            }).then(() => {
                getClassDetails();
              setCreatingClassSubject(false);
              setAddClassSubjectModal(false);
            });
          })
          .catch((error) => {
            console.log(error);
            setCreatingClassSubject(false);
          });
      };
    
    return (
    <div
      onClick={() => setAddClassSubjectModal(!addClassSubjectModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setAddClassSubjectModal(!addClassSubjectModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Add New Class Subject</p>
          <form
            onSubmit={(e) => createClassSubject(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Subject</label>
                <select
                  name="subject"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Subject</option>
                  {
                    subjects?.map((subject,index)=>(
                      <option value={subject?.id} key={index}>{subject?.subject_name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="admission-enquiry-form-input">
                <label htmlFor="">Teacher</label>
                <select
                  name="teacher"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Teacher</option>
                  {
                    teachers?.map((teacher,index)=>(
                      <option value={teacher?.id} key={index}>{teacher?.staff_name}</option>
                    ))
                  }
                </select>
              </div>
            </div>

            

            {creatingClassSubject ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddClassSubjectModal