import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { Link } from "react-router-dom";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { api_url } from "../../../url";
import Loading from "../../../components/Loading/Loading";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import ReactPaginate from "react-paginate";

const StaffList = () => {
  const [staffList, setStaffList] = useState([]);
  const [gettingStaffList, setGettingStaffList] = useState(true);
  const [deletingStaff, setDeletingStaff] = useState(false);
  const navigations = ["Dashboard", "User Management", "Staff List"];

  const itemsPerPage = 20;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = staffList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(staffList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % staffList.length;
    setItemOffset(newOffset);
  };


  const getStaffList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/employee`, config)
      .then((response) => {
        setStaffList(response.data);
        setGettingStaffList(false);
      })
      .catch((error) => {
        setGettingStaffList(false);
      });
  };

  const deleteStaff = (staff_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingStaff(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}accounts/employee/${staff_id}/`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Staff Deleted Successfully",
            }).then(() => {
              setDeletingStaff(false);
              getStaffList();
            });
          })
          .catch((error) => {
            setDeletingStaff(false);

            toast.error(
              "There was an error deleting the staff, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getStaffList();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        {gettingStaffList ? (
          <Loading />
        ) : (
          <div className="section-list">
            <div className="table-section-header">Staffs</div>
            <Link
              to="/admin/user-management/staffs/new"
              className="primary-button-outline w-max-content"
              style={{ marginBlock: "5px" }}
            >
              Add New Staff
            </Link>
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Staff ID</th>
                      <th>Staff Name</th>
                      <th>Username</th>
                      <th>Position</th>
                      <th>Phone Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {staffList &&
                      currentItems.map((staff, index) => (
                        <tr key={index}>
                          <td>{staff?.employee_id}</td>
                          <td>{staff?.staff_name}</td>
                          <td>{staff?.username}</td>
                          <td>{staff?.position}</td>
                          <td>{staff?.phone_number}</td>
                          <td>
                            {deletingStaff ? (
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            ) : (
                              <div className="action-buttons">
                                <Link
                                  to={`/admin/user-management/staffs/${staff?.id}/details`}
                                  className="view-button"
                                >
                                  View <AiOutlineEye />
                                </Link>
                                <Link
                                  onClick={() => deleteStaff(staff.id)}
                                  className="delete-button"
                                >
                                  Delete <AiOutlineDelete />
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {staffList.length <= 0 && <NoData />}
                <div className="pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                containerClassName="pagination-container"
                activeClassName="active-page"
                pageLinkClassName="page-link"
              />
            </div>
              </>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StaffList;
