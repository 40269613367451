import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AcademicsRoutes from './AcademicsRoutes'
import TeacherRoutes from './TeacherRoutes'
import StudentRoutes from './StudentRoutes'
import AdminRoutes from "./AdminRoutes"
import NotFound from '../pages/PortalPages/NotFound'
import FileManager from '../pages/PortalPages/FileManager/FileManager'
import UserProfile from '../pages/PortalPages/UserProfile'


const Layout = () => {
  return (
    <Routes>
        {/* ADMIN ROUTES */}
        <Route path="/admin/*" element={<AdminRoutes />} />

        {/* TEACHER ROUTES */}
        <Route path="/teacher/*" element={<TeacherRoutes />} /> 

        {/* STUDENT ROUTES */}
        <Route path="/student/*" element={<StudentRoutes />} />

        {/* FILE MANAGER */}
        <Route path="/dashboard/file-manager" element={<FileManager />} />
        
        {/* PROFILE */}
        <Route path="/dashboard/profile" element = {<UserProfile />} />

        {/* NOT FOUND */}
        <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default Layout