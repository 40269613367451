import React, { useContext } from "react";
import "./AdminDashboard.scss";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { AiOutlineEye } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api_url } from "../../../url";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import AuthContext from "../../../context/authContext";

const AdminDashboard = () => {
  const { user} = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);

  

  const getDashboardData = async () => {
    if (localStorage.getItem("access")) {
      setLoadingDashboardData(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${api_url}accounts/admin-dashboard/`, config)
          .then((response) => {
            setDashboardData(response.data);
            setLoadingDashboardData(false)
          });
      } catch (error) {
        toast.error("An error occurred");
        setLoadingDashboardData(false)
      }
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {loadingDashboardData ? (
        <Loading />
      ) : (
        <div id="admin-dashboard" className="portal-page">
          <div className="admin-dashboard-title">
            <div className="admin-dashboard-navigation">
              <GoHomeFill /> <IoIosArrowForward />{" "}
              <span className="section">Dashboard</span>
            </div>
            <h3 className="page-title">Dashboard</h3>
          </div>
          <div className="admin-dashboard-cards">
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">Total Students</span>
              </div>
              <div className="admin-dashboard-card-body">
                {dashboardData?.total_students}
              </div>
            </div>
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">Total Staff</span>
              </div>
              <div className="admin-dashboard-card-body">
                {dashboardData?.total_staff}
              </div>
            </div>
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">New Enquiries</span>
              </div>
              <div className="admin-dashboard-card-body">
                {dashboardData?.new_enquiries}
              </div>
            </div>
          </div>
          {
            user?.is_administrator && (
              <div className="recent-application section-list">
                <div className="table-section-header">Recent Applications</div>
                <div className="section-list-table">
                  {dashboardData?.recent_applications.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Application Token</th>
                          <th>Applicant Name</th>
                          <th>D.O.B</th>
                          <th>Class</th>
                          <th>Phone Number</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {dashboardData?.recent_applications?.map((application) => (
                          <tr>
                            <td>{application.application_token}</td>
                            <td>
                              {application.first_name} {application.surname}{" "}
                              {application.other_name !== "null" &&
                                application.other_name}
                            </td>
                            <td>{application.date_of_birth}</td>
                            <td>{application.applying_class}</td>
                            <td>
                              {application.parent_one_telephone ? (
                                <>{application.parent_one_telephone}</>
                              ) : (
                                <>{application.parent_two_telephone}</>
                              )}
                            </td>
                            <td>
                              <Link
                                to={`/dashboard/admissions/applications/${application.id}`}
                                className="view-button"
                              >
                                View <AiOutlineEye />
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p style={{textAlign:"center"}}>No Recent Applications</p>
                  )}
                </div>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
