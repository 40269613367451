import React from 'react'
import './Banner.scss'
import {BiSolidRightArrow} from "react-icons/bi"

const Banner = ({title, subtitle, image, underline}) => {
  return (
    <div id='banner-container'>
        <div className="banner" style={{backgroundImage: `url(${image}),linear-gradient(to right,#0000007b 65%,#0000007d 100%)`}}>
            <div className="banner-text">
                <h1>{title}</h1>
                <div className="bottom"style={{display: `${underline === "true" ? "none" : "block" }`}}>
                    <BiSolidRightArrow />
                    <span>{subtitle}</span>
                </div>
                <div className="underline-bottom" style={{display: `${underline === "true" ? "block" : "none" }`}}>
                  
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Banner