import React, { useState } from "react";
import "./NewsEvents.scss";
import { IoMdTime } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";

import AdmissionModal from "../AdmissionComponents/AdmissionModal/AdmissionModal";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import parse from 'html-react-parser'
import NoData from "../PortalComponents/NoData/NoData";

const NewsEvents = ({eventsList,latestNews}) => {
  const [admissionModal, setAdmissionModal] = useState(false);
  
  useEffect(() => {
    if (admissionModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  });
  return (
    <div className="news-events">
      <div className="news-events-header">
        <h1>News & Events</h1>
        <div className="bottom">
          <Link to={"/news-events/news"} className="read-all-news">Read All News</Link>
          <div className="underline"></div>
        </div>
      </div>
      <div className="content">
        <div className="news">
          {
            latestNews?.title !== "" ? (
              <div className="news-content">
                <img src={`http://localhost:8000${latestNews?.thumbnail}`} alt={latestNews?.title} />
                <p>{latestNews?.date} / {latestNews?.title}</p>
                <Link to={`/news-events/news/${latestNews?.slug}/`} className="news-caption">
                  {parse(latestNews?.details?.slice(0,150))}...
                </Link>
              </div>
            ) : (
              <div className="news-content">
                No News Available
              </div>
            )
          }
        </div>
        <div className="events">
          <div className="events-content">
            <div className="title">
              <h3>Upcoming Events</h3>
              <div className="bar"></div>
            </div>
            <div className="event-list">
              {eventsList.map((event, index) => (
                <div key={index} className="event-item">
                  <div className="date">
                    <h3 className="day">{event.day}</h3>
                    <h3 className="month">{event.month}</h3>
                    <div className="date-underline"></div>
                  </div>
                  <div className="event-details">
                    <div className="event-title">
                      <h3>{event.title}</h3>
                    </div>
                    {/* <div className="time-venue">
                      <div className="time">
                        <IoMdTime />
                        {event.time}
                      </div>
                      <div className="venue">
                        <FaLocationDot />
                        School Premise
                      </div>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
            {
              eventsList.length <= 0 && (
                <div>
                  No upcoming event
                </div>
              )
            }
          </div>
        </div>
        <div className="admission-apply">
          <div className="admission-content">
            <h2 className="apply-title">Apply For Admission</h2>
            {admissionModal && (
              <AdmissionModal
                admissionModal={admissionModal}
                setAdmissionModal={setAdmissionModal}
              />
            )}
            <div
              className="classic-button"
              onClick={() => setAdmissionModal(!admissionModal)}
            >
              Apply Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsEvents;
