import React, { useState, useEffect } from 'react'
import './AdmissionSection.scss'
import AdmissionImage1 from "../../../assets/admissionimages/admission-image.png"
import AdmissionImage2 from "../../../assets/admissionimages/admissionImage2.png"
import AdmissionImage3 from "../../../assets/admissionimages/admissionImage3.png"
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import AdmissionModal from '../AdmissionModal/AdmissionModal'

const AdmissionSection = () => {
    const [admissionModal, setAdmissionModal] = useState(false)
    const [openTab, setOpenTab] = useState(false)
    const [slideIndex, setSlideIndex] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true)
    let timeOut = null;
    useEffect(() => {
        if(admissionModal){
            document.body.style.overflow = 'hidden'
        }else{
            document.body.style.overflow = 'scroll'
        }
        timeOut =
          autoPlay &&
          setTimeout(() => {
            setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
          }, 3000);
      });

  return (
    <div className='main-container' id='admission-section'>
        {
            admissionModal && <AdmissionModal admissionModal={admissionModal} setAdmissionModal={setAdmissionModal}/>
        }
        <div className="admission-section-header">
            <div className="admission-section-header-left">
                <h2>
                    Anell Admission Policy
                </h2>
                <div className="apply-here-button" onClick={()=>setAdmissionModal(!admissionModal)}> 
                    Apply Here
                </div>
            </div>
            <div className="admission-section-header-right">
                <div className="image-container" onMouseEnter={()=>{
                    setAutoPlay(false);
                    clearTimeout(timeOut);  
                }}
                onMouseLeave={()=>{
                    setAutoPlay(true);
                }}
                >
                    <div className='image-slide'>
                        {
                            slideIndex === 0 && (
                            <img src={AdmissionImage1} alt="admission-1" />
                            )  
                        }
                        {
                            slideIndex === 1 && (
                                <img src={AdmissionImage2} alt="admission-1" />
                            )
                        }    
                        {
                            slideIndex === 2 && (
                                <img src={AdmissionImage3} alt="admission-1" />
                                )
                        }    
                            
                            
                        
                        
                    </div>
                </div>
            </div>
        </div>
        <div className="admission-section-body">
            <div className="application-requirement-div">
                <h3>
                    APPLICATION PERIOD
                </h3>
                <p>
                    The application period for Anell School of Excellence is typically announced on the school's official website and through the school’s social media handles (Instagram & Facebook). Prospective parents or guardians are expected to submit their applications during this designated period.
                </p>
            </div>
            <div className="application-requirement-div">
                <h3>AGE REQUIREMENT</h3>
                <p>
                Applicants must meet the age requirements for the specific grade they are applying for. The below are the available grades level of the school;
                </p>
                <ul>
                    <li>
                        Babycare & Creche
                    </li>
                    <li>
                        Pre- Kindergarten / Nursery
                    </li>
                    <li>
                        Reception / Kindergarten
                    </li>
                    <li>
                        Grade School
                    </li>
                </ul>

            </div>
            <div className="application-requirement-div">
                <h3>APPLICATION FORMS:</h3>
                <p>Application forms can be obtained online through our school's official website or in hard copy at the school's administrative office. A non-refundable application fee is required to process the application. Payment details will be provided along with the application form.</p>
            </div>
            <div className="admission-process" style={{backgroundColor:`${openTab ? "#FBFCFF":"#021D53"}`}}>
                <div className="admission-process-title" onClick={()=>setOpenTab(!openTab)}>
                    {
                        openTab ? <h3 style={{color:"#021D53"}}>SUPPORTING DOCUMENTS</h3> : <h4 style={{color:"white"}}>THE ADMISSION PROCESS</h4>
                    }
                    {
                        openTab ?<IoRemoveCircleOutline style={{color:"#000"}} /> : <IoAddCircleOutline style={{color:"#fff"}} />
                    }
                    
                </div>
                <div className="admission-process-body" style={{display:`${openTab ? "block":"none"}`}}>
                    <p className='admission-process-intro'>
                        In addition to the completed application form, applicants are required to submit the following supporting documents:
                    </p>
                    <ul>
                        <li>Child's original birth certificate (Photocopy).</li>
                        <li>Photocopy of the NHIS card.</li>
                        <li>Academic records if the applicant is transferring from another school (Last academic report).</li>
                        <li>Passport-sized photos of the prospective learner.</li>
                    </ul>
                    <div className="admission-process-item">
                        <h3>MANDATORY MEDICAL SCREENING:</h3>
                        <p>Prospective learners are required to undergo a mandatory medical screening at Garden City Hospital which is the school’s approved medical center for all prospective applicants. This medical screening is the responsibility of the parent or guardian. A certificate of medical fitness from the hospital must be submitted as part of the application process.</p>
                    </div>
                    <div className="admission-process-item">
                        <h3>INTERVIEW OR ENTRANCE EXAMINATION:</h3>
                        <p>Anell School of Excellence may require applicants to participate in an interview or take an entrance examination. This requirement may vary depending on the grade level applied for. The school will communicate the details of such assessments if necessary.</p>
                    </div>
                    <div className="admission-process-item">
                        <h3>ADMISSION CRITERIA:</h3>
                        <p>Admission to Anell School of Excellence is determined by evaluating various factors, including:</p>
                        <ul>
                            <li>The child's age and compliance with age requirements.</li>
                            <li>Academic performance if applicable.</li>
                            <li>Interview or examination results if required.</li>
                            <li>Availability of space within the school for the desired grade level.</li>
                        </ul>
                    </div>
                    <div className="admission-process-item">
                        <h3>NOTIFICATION OF ACCEPTANCE:</h3>
                        <p>Once the school has thoroughly reviewed all applications and conducted assessments, parents or guardians will be notified of the admission decision. This notification will be provided in the form of an official acceptance letter or email.</p>
                    </div>
                    <div className="admission-process-item">
                        <h3>REGISTRATION</h3>
                        <p>Parents or guardians of accepted students will be required to complete the registration process. This process includes submitting any additional necessary documents and payment of the registration fees.</p>
                    </div>
                    <div className="admission-process-item">
                        <h3>BILL ENQUIRIES</h3>
                        <p>It is essential for parents or guardians to inquire about the school bill and its associated expenses during the application process. Anell School of Excellence will provide detailed information regarding tuition and fees to ensure transparency.</p>
                    </div>
                    <div className="admission-process-item">
                        <h3>ORIENTATION</h3>
                        <p>Anell School of Excellence organizes orientation programs for new students and their parents or guardians. These orientation sessions are designed to acquaint families with the school's policies, procedures, and facilities, ensuring a smooth transition into the school community.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdmissionSection