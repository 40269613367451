import React, {useEffect} from 'react'
import {BsWhatsapp} from "react-icons/bs"
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import DepartmentsImage from "../../assets/aboutpage/departmentsImage.jpeg"
import DepartmentsContent from '../../components/AboutComponents/DepartmentsContent/DepartmentsContent'
import Footer from '../../components/Footer/Footer'
import BannerBar from '../../components/BannerBar/BannerBar'
import { Link, useLocation } from 'react-router-dom'

const Departments = () => {
  const location = useLocation()

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
        <TopBar />
        <Navbar />
        <Banner title="About Us" subtitle="Departments" image={DepartmentsImage} />
        <BannerBar />
        <DepartmentsContent />
        <Footer />
        <Link to="https://wa.me/2330504828824?text=" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
    </div>
  )
}

export default Departments