import React, { useState } from "react";
import "./Medicals.scss";
import Navbar from "../../components/Navbar/Navbar";
import TopBar from "../../components/TopBar/TopBar";
import { useParams } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect } from "react";
import axios from "axios";
import { api_url } from "../../url";
import Loading from "../../components/Loading/Loading";

const Medicals = () => {
  const [purchasing, setPurchasing] = useState(false);
  const { token } = useParams();
  const [email, setEmail] = useState("");
  const publicKey = "pk_test_b280bd842436239d7059d65481bd50c960db4302";
  // const [email, setEmail] = useState("")
  const config = {
    reference: `${token}-medic`,
    email: email,
    amount: 20000,
    currency: "GHS",
    publicKey,
  };

  // GET TOKEN DETAILS
  const getTokenDetails = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    try {
      await axios
        .get(`${api_url}admissions/application-token/${token}/`, config)
        .then((response) => {
          if(response.data['medical_forms_payment_made']){
            Swal.fire({
              title:"Payment Made",
              text:"You have already made payment for the medical forms. Check your email for the forms.",
              icon:"success"
            }).then(()=>{
              window.location.href = "/admission/apply/";      
            })
          }
        });
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error.response.data);
        window.location.href = "/admission/apply/";
      }
    }
  };

  const initializePayment = usePaystackPayment(config);

  const makePayment = () => {
    if (email === "") {
      toast.error("Please enter your email address");
    } else {
      initializePayment(onSuccess, onClose);
    }
  };

  // PURCHASE MEDICAL FORMS
  const purchaseMedicalForms = async () => {
    setPurchasing(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    const body = JSON.stringify({
      medical_forms_payment_made: true,
      email: email,
    });
    try {
      await axios
        .put(`${api_url}admissions/application-token/${token}/`, body, config)
        .then((response) => {
          setPurchasing(false)
          Swal.fire({
            title: "Payment Successful",
            text: "Payment for medical forms was successful. Please check your mail for the medical form and a letter. Thank you!",
            icon: "success",
          }).then(() => (window.location.href = "/admission/apply"));
        });
    } catch (error) {
      setPurchasing(false)
      toast.error("An error occurred please try again.")
    }
  };

  // AFTER SUCCESSFUL PAYMENT
  const onSuccess = (response) => {
    purchaseMedicalForms();
  };

  // you can call this function anything
  const onClose = () => {
    toast.error(
      "You will need the medical forms to validate your application."
    );
  };

  useEffect(() => {
    getTokenDetails();
  });
  return (
    <div>
      <TopBar />
      <Navbar />
      {purchasing ? (
        <Loading />
      ) : (
        <div id="medicals">
          <p className="medicals-intro">
            Congratulations. You have successfully submitted your application
            forms for review. Kindly check your mail for directions for the next
            steps to complete your application. Thank you.
          </p>
          <p className="medicals-info">
            To complete your application you are required to undergo a mandatory
            medical screening
          </p>
          <p className="medicals-desc">
            Prospective learners are required to undergo a mandatory medical
            screening at Garden City Hospital which is the school’s approved
            medical center for all prospective applicants. This medical
            screening is the responsibility of the parent or guardian. A
            certificate of medical fitness from the hospital must be submitted
            as part of the application process.
          </p>
          <div className="medicals-bottom">
            <h4>
              PLEASE ENTER YOUR EMAIL AND CLICK ON THE BUTTON TO MAKE THE
              PURCHASE
            </h4>
            <input
              type="email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              id=""
              placeholder="Email Address"
            />
            <div className="purchase-button" onClick={() => makePayment()}>
              MAKE PURCHASE
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Medicals;
