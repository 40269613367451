import React, { useContext } from "react";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { AiOutlineEye } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { api_url } from "../../../url";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import AuthContext from "../../../context/authContext";

const StudentDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loadingDashboardData, setLoadingDashboardData] = useState(false);

  

  const getDashboardData = async () => {
    if (localStorage.getItem("access")) {
      setLoadingDashboardData(true)
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${api_url}student/dashboard/`, config)
          .then((response) => {
            if(response?.data?.success){
                setDashboardData(response.data?.payload);

            }else{
                if(response?.data?.message){
                    toast.error(response?.data?.message)
                }
            }
            setLoadingDashboardData(false)
          });
      } catch (error) {
        toast.error("An error occurred");
        setLoadingDashboardData(false)
      }
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);
  return (
    <div>
      
      {loadingDashboardData ? (
        <Loading />
      ) : (
        <div id="admin-dashboard" className="portal-page">
          <div className="admin-dashboard-title">
            <div className="admin-dashboard-navigation">
              <GoHomeFill /> <IoIosArrowForward />{" "}
              <span className="section">Dashboard</span>
            </div>
            <h3 className="page-title">Student Dashboard</h3>
          </div>
          <div className="admin-dashboard-cards">
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">Current Class</span>
              </div>
              <div className="admin-dashboard-card-body" style={{fontSize:"35px"}}>
                {dashboardData?.current_class}
              </div>
            </div>
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">Pending Assignment</span>
              </div>
              <div className="admin-dashboard-card-body">
                {dashboardData?.pending_assignments}
              </div>
            </div>
            <div className="admin-dashboard-card">
              <div className="admin-dashboard-card-title">
                <FaUser /> <span className="card-title">Upcoming Meetings</span>
              </div>
              <div className="admin-dashboard-card-body">
                {dashboardData?.upcoming_meetings}
              </div>
            </div>
          </div>
          
        </div>
      )}
    </div>
  );
};

export default StudentDashboard;
