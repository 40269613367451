import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AdminDashboard from '../pages/PortalPages/AdminDashboard/AdminDashboard'
import Applications from '../pages/PortalPages/Applications/Applications'
import ApplicationDetails from '../pages/PortalPages/Applications/ApplicationDetails'
import AdmissionEnquiries from '../pages/PortalPages/AdmissionEnquiries/AdmissionEnquiries'
import EnquiryDetails from '../pages/PortalPages/AdmissionEnquiries/EnquiryDetails'
import SchoolClasses from '../pages/PortalPages/SchoolClasses/SchoolClasses'
import WebsiteNews from '../pages/PortalPages/WebsiteNews/WebsiteNews'
import WebsiteNewsDetails from '../pages/PortalPages/WebsiteNews/WebsiteNewsDetails'
import AddNews from '../pages/PortalPages/WebsiteNews/AddNews'
import PortalCalendar from '../pages/PortalPages/PortalCalendar/PortalCalendar'
import CalendarActivities from '../pages/PortalPages/PortalCalendar/CalendarActivities'
import StaffList from '../pages/PortalPages/Staffs/StaffList'
import AddStaff from '../pages/PortalPages/Staffs/AddStaff'
import StudentsList from '../pages/PortalPages/Students/StudentsList'
import AddStudent from '../pages/PortalPages/Students/AddStudent'
import StudentDetail from '../pages/PortalPages/Students/StudentDetail'
import StaffDetail from '../pages/PortalPages/Staffs/StaffDetail'
import ClassDetail from '../pages/PortalPages/SchoolClasses/ClassDetail'
import AnellLibrary from '../pages/PortalPages/AnellLibrary/AnellLibrary'
import AddMultipleStudent from '../pages/PortalPages/Students/AddMultipleStudent'
import AcademicsRoutes from './AcademicsRoutes'
import TeacherAnellMeet from '../pages/PortalPages/TeacherPages/TeacherAnellMeet'
import AdminAnellMeet from '../pages/PortalPages/AdminAnellMeet/AdminAnellMeet'

const AdminRoutes = () => {
  return (
    <Routes>
        <Route path='/dashboard/' element={<AdminDashboard />} exact />
        <Route path='/dashboard/admissions/applications' element={<Applications />} />
        <Route path='/dashboard/admissions/applications/:application_id' element={<ApplicationDetails />} />
        <Route path="/dashboard/admissions/enquiries" element={<AdmissionEnquiries />} />
        <Route path="/dashboard/admissions/enquiries/:enquiryId" element={<EnquiryDetails />} />
        
        {/* CLASSES */}
        <Route path='/dashboard/classes' element={<SchoolClasses />} />
        <Route path="/dashboard/classes/:class_id/details" element={<ClassDetail />} />

        {/* ACADEMICS */}
        <Route path='/dashboard/academics/*' element={<AcademicsRoutes />} />

        <Route path="/website-components/news" element={<WebsiteNews />} />
        <Route path="/website-components/news/:newsId" element={<WebsiteNewsDetails />} />
        <Route path="/website-components/add-news/" element={<AddNews />} />

        <Route path="/website-components/calendar" element={<PortalCalendar />} />
        <Route path="/website-components/calendar/:academic_year/activities/" element={<CalendarActivities />} />    

        <Route path="/user-management/staffs" element={<StaffList />} /> 
        <Route path="/user-management/staffs/new" element={<AddStaff />} /> 
        <Route path="/user-management/staffs/:staff_id/details" element={<StaffDetail />} />

        <Route path="/user-management/students" element={<StudentsList />} /> 
        <Route path="/user-management/students/new" element={<AddStudent />} /> 
        <Route path="/user-management/students/multiple" element={<AddMultipleStudent />} />
        <Route path="/user-management/students/:student_id/details" element={<StudentDetail />} /> 

        <Route path="/dashboard/anell-library" element={<AnellLibrary />} />

        <Route path="/anell-meet/" element={<AdminAnellMeet />} />
    </Routes>
  )
}

export default AdminRoutes