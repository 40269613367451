import React from 'react'
import "./TopBar.scss"
import {HiMail} from "react-icons/hi"
import {FaLocationDot,FaPhone, FaFacebookF, FaInstagram} from "react-icons/fa6"
import { Link } from 'react-router-dom'


const TopBar = () => {
  return (
    <div className="top-section">
        <div className="school-information">
            <div className="school-address">
                <div className="school-mail">
                    <HiMail /> info@anell.edu.gh &nbsp; | &nbsp; admission@anell.edu.gh
                </div>
                <div className="address">
                    <FaLocationDot /> Kumasi, Airport Road, Kenyasi 
                </div>
            </div>
            <div className="school-phone">
                <FaPhone /> 0322 499 539 / +233 (0)243 738 101
            </div>
        </div>
        <div className="social-media">
            <Link to="https://web.facebook.com/anellschoolofexcellence?mibextid=ZbWKwL&_rdc=1&_rdr" className="social-media-icon">
                <FaFacebookF />
            </Link>
            <Link to="https://instagram.com/anellschoolofexcellence?igshid=NTc4MTIwNjQ2YQ" className="social-media-icon">
                <FaInstagram />
            </Link>
        </div>
        
    </div>
  )
}

export default TopBar