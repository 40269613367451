import React, {useEffect} from 'react'
import {BsWhatsapp} from "react-icons/bs"
import OverviewImage from "../../assets/aboutpage/aboutimage.jpeg"
import AboutAnell from '../../components/AboutComponents/AboutAnell/AboutAnell'
import ChooseAnell from '../../components/AboutComponents/ChooseAnell/ChooseAnell'
import History from '../../components/AboutComponents/History/History'
import Banner from '../../components/Banner/Banner'
import BannerBar from '../../components/BannerBar/BannerBar'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import { Link, useLocation } from 'react-router-dom'

const Overview = () => {
  const location = useLocation()

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <div>
      <TopBar />
      <Navbar />
      <Banner title="About Us" subtitle="Overview" image={OverviewImage} />
      <BannerBar />
      <AboutAnell />
      <ChooseAnell />
      <History />
      <Footer />
      <Link to="https://wa.me/2330504828824?text=" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
    </div>
  )
}

export default Overview