import axios from 'axios';
import React, { useState } from 'react'
import { api_url } from '../../../../url';
import { AiOutlineClose } from 'react-icons/ai';
import { RotatingLines } from 'react-loader-spinner';
import Swal from 'sweetalert2';

const UploadDocument = ({getLibraryUploads,subjects, schoolClasses, uploadDocumentModal, setUploadDocumentModal}) => {
    const [data, setData] = useState({});
  const [uploadingDocument, setUploadingDocument] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const uploadLibraryDocument = async (e) => {
    e.preventDefault();
    setUploadingDocument(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const formData = new FormData();

    Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
    })

    await axios
      .post(`${api_url}library/teacher/uploads/`, formData, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Document uploaded Successfully",
        }).then(() => {
            getLibraryUploads();
          setUploadingDocument(false);
          setUploadDocumentModal(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setUploadingDocument(false);
      });
  };

  

  return (
    <div
      onClick={() => setUploadDocumentModal(!uploadDocumentModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setUploadDocumentModal(!uploadDocumentModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Upload Library Document</p>
          <form
            onSubmit={(e) => uploadLibraryDocument(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class*</label>
                <select
                  name="school_class"
                  id=""
                  required
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Class</option>
                  {
                    schoolClasses?.map((school_class,index)=>(
                      <option value={school_class?.id} key={index}>{school_class?.class_name}</option>
                    ))
                  }
                  
                </select>
              </div>
              
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Subject*</label>
                <select
                  name="subject"
                  id=""
                  required
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Subject</option>
                  {
                    subjects?.map((subject,index)=>(
                      <option value={subject?.id} key={index}>{subject?.subject_name}</option>
                    ))
                  }
                  
                </select>
              </div>
              
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Document Name*</label>
                <input required name="document_name" type="text"  onChange={(e) => handleFormChange(e)} />
                
              </div>
              
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Document*</label>
                <input required name="document" type="file"  onChange={(e) => setData({
                    ...data,[e.target.name]:e.target.files[0]
                })} />
                
              </div>
              
            </div>

            

            {uploadingDocument ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Upload Document" />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default UploadDocument