import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {api_url} from "../url.js"
import Loading from "../components/Loading/Loading.jsx";

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
    const [authenticating, setAuthenticating] = useState(true)
    const [ user, setUser] = useState(null)
    const [loggingIn, setLoggingIn] = useState(false)
    const [authToken, setAuthToken] = useState(null)
    const [schoolClasses, setSchoolClasses] = useState([])
    const [portalMobileView, setPortalMobileView] = useState(false)
    const [academicTerms, setAcademicTerms] = useState([])

    const navigate = useNavigate()

    

    const getUser = async(request_type) => {
        
        if(localStorage.getItem("access")){
            try {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization":`JWT ${localStorage.getItem("access")}`
                    }
                }
                await axios.get(`${api_url}accounts/me/`,config).then((response)=>{
                    setUser(response.data)
                    setAuthenticating(false)
                })
            } catch (error) {
                // toast.error("You are not authorized to access this page")
                setUser(null)
                setAuthenticating(false)
                localStorage.removeItem("access")
                localStorage.removeItem("refresh")
            }
        }else{
            setUser(null)
            setAuthenticating(false)
        }
    }

    const logoutUser = () => {
        setAuthToken(null)
        setUser(null)
        localStorage.removeItem("access")
        navigate('/login')
    }

    const getClasses = async () => {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .get(`${api_url}academics/classes/`, config)
          .then((response) => {
            setSchoolClasses(response.data);
          })
          .catch((error) => {});
      };

      const getAcademicTerms = async ()=> {
        const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
    
        await axios.get(`${api_url}academics/academic-term/`, config).then((response)=>{
            setAcademicTerms(response.data);
        }).catch((error)=>{
            console.log(error);
        })
      }

    let contextData = {
        user:user,
        logoutUser:logoutUser,
        loggingIn:loggingIn,
        setPortalMobileView,
        portalMobileView,
        schoolClasses,
        academicTerms
    }

    useEffect(()=>{
        getUser();
        getClasses();
        getAcademicTerms();
    },[])

    if(authenticating){
        return <Loading />
    }else{
        return (
            <AuthContext.Provider value={contextData}>
                {children}
            </AuthContext.Provider>
        )
    }
}