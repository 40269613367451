import React, {useEffect, useState} from 'react'
import AboutSection from '../../components/AboutSection/AboutSection'
import Admissions from '../../components/Admissions/Admissions'
import Carousel from '../../components/carousel/Carousel'
import Footer from '../../components/Footer/Footer'
import MapDirection from '../../components/MapDirection/MapDirection'
import MissionVision from '../../components/MissionVision/MissionVision'
import Navbar from '../../components/Navbar/Navbar'
import NewsEvents from '../../components/NewsEvents/NewsEvents'
import TopBar from '../../components/TopBar/TopBar'
import WhyAnell from '../../components/WhyAnell/WhyAnell'
import { Link, useLocation } from 'react-router-dom'
import {BsWhatsapp} from "react-icons/bs"
import axios from 'axios'
import { api_url } from '../../url'
import Loading from '../../components/Loading/Loading'

const Home = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([])
  const [latestNews, setLatestNews] = useState(null)
  const [gettingData, setGettingData] = useState(true)
  const location = useLocation()

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const getHomePageData = async () => {
    const config = {
      headers:{
        "Content-Type":"application/json"
      }
    }

    await axios.get(`${api_url}website/home-page/`, config).then((response)=>{
      setUpcomingEvents(response.data?.upcoming_events)
      setLatestNews(response.data?.latest_news)
      setGettingData(false)
    }).catch((error)=>{
      
      setGettingData(false)
    })
  }

  useEffect(()=>{
    getHomePageData();
  },[])
  
  return (
    <div>
        <TopBar />
        <Navbar />
        <Carousel />
        <Admissions />
        <AboutSection />
        <MissionVision />
        <WhyAnell />
        
        {
          gettingData ? <Loading /> : <NewsEvents eventsList={upcomingEvents} latestNews={latestNews} />
        }
        
        
        <MapDirection />
        <Footer />
        <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
    </div>
  )
}

export default Home