import React, { useEffect, useState } from "react";
import "./SchoolClasses.scss";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from "react-icons/ai";
import AddClassModal from "./AddClassModal";
import axios from "axios";
import { api_url } from "../../../url";
import EditClassModal from "./EditClassModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import NoData from '../../../components/PortalComponents/NoData/NoData'

const SchoolClasses = () => {
  const [classes, setClasses] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(true);
  const [addClassModal, setAddClassModal] = useState(false);
  const [editClassModal, setEditClassModal] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [deletingClass, setDeletingClass] = useState(false);

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setClasses(response.data);
        setLoadingClasses(false);
      })
      .catch((error) => {
        
        setLoadingClasses(false);
      });
  };

  const deleteClass = (class_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingClass(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}academics/classes/${class_id}/`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Class Deleted Successfully",
            }).then(() => {
              setDeletingClass(false);
              getClasses();
            });
          })
          .catch((error) => {
            setDeletingClass(false);
            
            toast.error(
              "There was an error deleting the class, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {addClassModal && (
        <AddClassModal
          getClasses={getClasses}
          addClassModal={addClassModal}
          setAddClassModal={setAddClassModal}
        />
      )}
      {editClassModal && (
        <EditClassModal
          selectedClass={selectedClass}
          getClasses={getClasses}
          editClassModal={editClassModal}
          setEditClassModal={setEditClassModal}
        />
      )}
      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <div className="admin-dashboard-navigation">
            <GoHomeFill /> <IoIosArrowForward />{" "}
            <span className="section">Dashboard</span> <IoIosArrowForward />{" "}
            <span className="section">Classes</span>
          </div>
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Classes</div>

          <Link
            onClick={() => setAddClassModal(true)}
            className="primary-button-outline w-max-content"
            style={{ marginBlock: "5px" }}
          >
            Add Class
          </Link>

          {loadingClasses ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Class Name</th>
                      <th>Class Category</th>
                      <th>Students In Class</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {classes &&
                      classes.map((school_class, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{school_class.class_name}</td>
                          <td>{school_class.class_level}</td>
                          <td>{school_class?.class_students}</td>
                          <td>
                            {deletingClass ? (
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            ) : (
                              <div className="action-buttons">
                                <Link
                                  to={`/admin/dashboard/classes/${school_class?.id}/details`}
                                  className="view-button"
                                  
                                >
                                  View <AiOutlineEye />
                                </Link>
                                <Link
                                  to={``}
                                  className="view-button"
                                  onClick={() => {
                                    setSelectedClass(school_class);
                                    setEditClassModal(true);
                                  }}
                                >
                                  Edit <AiOutlineEdit />
                                </Link>
                                <Link
                                  className="delete-button"
                                  onClick={() => deleteClass(school_class?.id)}
                                >
                                  Delete <AiOutlineDelete />
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                  classes.length <= 0 && (
                    <NoData />
                  )
                }
              </>
            </div>
          )}

          {/* <div className="classes-list">
            <div className="class-level">
              <div className="class-level-title">
                <div className="class-level-title-name">Pre-School</div>
              </div>
              <div className="class-level-body">
                {preSchoolClassList.map((preSchoolClass, index) => (
                  <div className="school-class">{preSchoolClass}</div>
                ))}
              </div>
            </div>
            <div className="class-level">
              <div className="class-level-title">
                <div className="class-level-title-name">Grade School</div>
              </div>
              <div className="class-level-body">
                {gradeSchoolClassList.map((gradeSchoolClass, index) => (
                  <div className="school-class" key={index}>
                    {gradeSchoolClass}
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SchoolClasses;
