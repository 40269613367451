import React, { useEffect, useState } from 'react'
import DashboardNavigation from '../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation'
import Loading from '../../../../components/Loading/Loading'
import NoData from '../../../../components/PortalComponents/NoData/NoData'
import axios from 'axios'
import { api_url } from '../../../../url'

const TeacherSubjects = () => {
  const [loadingTeacherSubjects, setLoadingTeacherSubjects] = useState(true)
  const [teacherSubjects, setTeacherSubjects] = useState([])
  
  const navigations = [
    "Dashboard", "Academics", "Subjects"
  ]

  const getTeacherSubjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}teacher/subjects/`, config)
      .then((response) => {
        
        setTeacherSubjects(response.data);
        setLoadingTeacherSubjects(false);
      })
      .catch((error) => {
        
        setLoadingTeacherSubjects(false);
      });
  };
  
  useEffect(()=>{
    getTeacherSubjects()
  },[])

  return (
    <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations = {navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Your Subjects</div>

          {loadingTeacherSubjects ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Subject Name</th>
                      <th>Class Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {teacherSubjects &&
                      teacherSubjects.map((teacher_subject, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{teacher_subject?.subject}</td>
                          <td>{teacher_subject?.school_class}</td>
                          
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                  teacherSubjects.length <= 0 && (
                    <NoData />
                  )
                }
              </>
            </div>
          )}

          
        </div>
      </div>
  )
}

export default TeacherSubjects