import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Subjects from '../pages/PortalPages/Subjects/Subjects'
import AdminTeacherSubjects from '../pages/PortalPages/AdminTeacherSubjects/AdminTeacherSubjects'

const AcademicsRoutes = () => {
  return (
    <Routes>
        <Route path='/subjects' element={<Subjects />} />
        <Route path="/teacher-subjects" element={<AdminTeacherSubjects />} />
    </Routes>
  )
}

export default AcademicsRoutes