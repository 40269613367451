import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const AddClassModal = ({ getClasses, addClassModal, setAddClassModal }) => {
  const [data, setData] = useState({});
  const [creatingClass, setCreatingClass] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createClass = async (e) => {
    e.preventDefault();
    setCreatingClass(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .post(`${api_url}academics/classes/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Class Created Successfully",
        }).then(() => {
          getClasses();
          setCreatingClass(false);
          setAddClassModal(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setCreatingClass(false);
      });
  };

  return (
    <div
      onClick={() => setAddClassModal(!addClassModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setAddClassModal(!addClassModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Add New Class</p>
          <form
            onSubmit={(e) => createClass(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class Level</label>
                <select
                  name="class_level"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Class Category</option>
                  <option value="Pre-school">Pre-school</option>
                  <option value="Grade School">Grade School</option>
                </select>
              </div>
            </div>

            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class Name</label>
                <input
                  required
                  type="text"
                  name="class_name"
                  id="class_name"
                  placeholder="Ex. Creche "
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>

            {creatingClass ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddClassModal;
