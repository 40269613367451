import React, { useEffect, useState } from 'react'
import {  useParams } from "react-router-dom";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import Loading from "../../../components/Loading/Loading";
import { RotatingLines } from 'react-loader-spinner';
import DashboardNavigation from '../../../components/PortalComponents/DashboardNavigation/DashboardNavigation';
import axios from 'axios';
import { api_url } from '../../../url';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

const StudentDetail = () => {
  const { student_id } = useParams();
  const navigations = ["Dashboard", "User Management", "Student Details"];
  const [gettingStudent, setGettingStudent] = useState(true);
  const [studentDetail, setStudentDetail] = useState({});
  const [studentUpdateData, setStudentUpdateData] = useState({});
  const [updatingStudent, setUpdatingStudent] = useState(false);
  const [classes, setClasses] = useState([]);

  const handleFormChange = (e) => {
    setStudentUpdateData({
      ...studentUpdateData,
      [e.target.name]: e.target.value,
    });
  };

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStudent = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/student/${student_id}/`, config)
      .then((response) => {
        console.log(response.data);
        setStudentDetail(response.data);
        setGettingStudent(false);
      })
      .catch((error) => {
        console.log(error);
        setGettingStudent(false);
      });
  };

  const updateStudent = async (e) => {
    e.preventDefault();
    setUpdatingStudent(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    console.log(studentUpdateData);
    const body = JSON.stringify(studentUpdateData);
    await axios
      .put(`${api_url}accounts/student/${student_id}/`, body, config)
      .then((response) => {
        console.log(response.data);
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Student Updated Successfully",
        }).then(() => {
            setUpdatingStudent(false);
          // window.location.href = "/user-management/students";
        });
      })
      .catch((error) => {
        console.error(error);
        setUpdatingStudent(false);
        if(error?.response?.data){
            if(error?.response?.status === 400){
                for (const property in error?.response?.data){
                    if(property === "0"){
                        toast.error(`${error?.response?.data[property]}`)
                    }else{
                        toast.error(`${property} error: ${error?.response?.data[property]}`)
                    }
                }
            }
        }else{
            toast.error("Something unexpected happended. Please try again.")
        }
      });
  };

  useEffect(() => {
    getClasses();
    getStudent();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {gettingStudent ? (
        <Loading />
      ) : (
        <div id="news-details" className="portal-page">
          <div className="admin-dashboard-title">
            <DashboardNavigation navigations={navigations} />
            <h3 className="page-title">Add New Student</h3>
          </div>
          <form onSubmit={(e) => updateStudent(e)} className="custom-form">
            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">Student ID</label>
                <input
                  type="text"
                  name="student_id"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.student_id ? studentUpdateData.student_id : studentDetail?.student_id}
                />
              </div>
              <div className="custom-form-col">
                <label htmlFor="">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.first_name ? studentUpdateData.first_name : studentDetail?.first_name}
                />
              </div>
              <div className="custom-form-col">
                <label htmlFor="">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.last_name ? studentUpdateData.last_name : studentDetail?.last_name}
                />
              </div>
            </div>

            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">Student Class</label>
                <select
                  onChange={(e) => handleFormChange(e)}
                  name="student_class"
                  id=""
                  className="custom-form-control"
                >
                  <option value="">Select Class</option>
                  {classes?.map((student_class, index) => (
                    <option selected={student_class?.id === studentDetail?.student_class} value={student_class?.id} key={index}>
                      {student_class?.class_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="custom-form-col">
                <label htmlFor="">Date of birth</label>
                <input
                  type="date"
                  name="date_of_birth"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.date_of_birth ? studentUpdateData.date_of_birth : studentDetail?.date_of_birth}
                />
              </div>

              <div className="custom-form-col">
                <label htmlFor="">Nationality</label>
                <input
                  type="text"
                  name="nationality"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.nationality ? studentUpdateData.nationality : studentDetail?.nationality}
                />
              </div>
            </div>

            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">Hometown</label>
                <input
                  type="text"
                  name="hometown"
                  className="custom-form-control"
                  onChange={(e) => handleFormChange(e)}
                  value={studentUpdateData?.hometown ? studentUpdateData.hometown : studentDetail?.hometown}
                />
              </div>
              
            </div>
            <div className="form-action-buttons">
              {updatingStudent ? (
                <div style={{ textAlign: "center" }}>
                  <RotatingLines
                    strokeColor="#021D53"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="26"
                    visible={true}
                  />
                </div>
              ) : (
                <button type="submit" className="primary-button w-max-content">
                  Save
                </button>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default StudentDetail;
