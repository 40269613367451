import React, { useState } from "react";
import './AdmissionEnquiry.scss'
import { useEffect } from "react";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../../url";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from 'react-loader-spinner'
import Loading from "../../../components/Loading/Loading";

const EnquiryDetails = () => {
  const { enquiryId } = useParams();
  const [enquiry, setEnquiry] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  // GET ENQUIRY DETAILS 
  const getEnquiryDetails = async () => {
    setDataFetched(false)
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${api_url}admissions/enquiry/${enquiryId}/`, config)
          .then((response) => {
            setEnquiry(response.data);
            setDataFetched(true)
          });
      } catch (error) {
        toast.error("error")
        console.log(error);
      }
    }
  };

  // APPROVE ENQUIRY API CALL
  const updateEnquiryStatus = async (status) => {
    if(localStorage.getItem("access")){
      setUpdating(true)
      const config = {
        headers:{
          "Content-Type": "application/json",
          "Authorization": `JWT ${localStorage.getItem("access")}`
        }
      }
      const body = JSON.stringify({
        "status":status
      })
      try {
        await axios.put(`${api_url}admissions/enquiry/${enquiryId}/`, body, config).then((response)=>{
          if(response.status === 200){
            setUpdating(false)
            if(status === "Approved"){
              toast.success("Admission Enquiry has been approved")
            }else if(status === "Declined"){
              toast.success("Admission Enquiry has been declined successfully")
            }
            getEnquiryDetails()
          }
        })
      } catch (error) {
        setUpdating(false)
        toast.error("An unexpected error occurred. Please try again.")
      }
    }
  }

  const approveEnquiry = async () => {
    Swal.fire({
        title: `Are you sure?`,
      text: "Once approved, the applicant will receive a mail with a token to proceed with the application",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve!",
    }).then((result)=>{
        if(result.isConfirmed){
          updateEnquiryStatus("Approved")
            
        }
    })
  }

  const rejectEnquiry = () => {
    Swal.fire({
        title: `Are you sure?`,
      text: "Once decline, the applicant will receive a mail informing them that there is no open admission",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject!",
    }).then((result)=>{
        if(result.isConfirmed){
          updateEnquiryStatus("Declined")
        }
    })
  }

  useEffect(() => {
    getEnquiryDetails();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="admission-enquiry-details" className="portal-page">
        <div className="admin-dashboard-title">
          <div className="admin-dashboard-navigation">
            <GoHomeFill /> <IoIosArrowForward />{" "}
            <span className="section">Dashboard</span> <IoIosArrowForward />{" "}
            <span className="section">Admission Enquiries</span>{" "}
            <IoIosArrowForward />{" "}
            <span className="section">Enquiry Details</span>
          </div>
        </div>
        {
          dataFetched ? (
          <div className="enquiry section-list">
              <div className="table-section-header">Enquiries</div>
              <div className="enquiry-details">
                <div className="field-data">
                  <label htmlFor="">Name</label>
                  <p>
                    {enquiry?.full_name}
                  </p>
                </div>
                <div className="field-data">
                  <label htmlFor="">Date Enquired</label>
                  <p>
                  {enquiry?.date_enquired}
                  </p>
                </div>
              
                <div className="field-data">
                  <label htmlFor="">Class Enquired:</label>
                  <p>
                  {enquiry?.enquired_class}
                  </p>
                </div>
              
                <div className="field-data">
                  <label htmlFor="">Status:</label>
                  <p>
                  {enquiry?.status}
                  </p>
                </div>
              
                <div className="field-data">
                  <label htmlFor="">Email:</label>
                  <p>
                  {enquiry?.email}
                  </p>
                </div>
              </div>
              {
                enquiry?.status === "Pending" && (
                  <div className="enquiry-buttons">
                    {
                      updating ? (
                        <RotatingLines
                                      strokeColor="#021D53"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="26"
                                      visible={true}
                                      />
                      ):(
                        <>
                          <button onClick={()=>approveEnquiry()} className="enquiry-button button-approve">
                              Approve
                          </button>
                          <button onClick={()=>rejectEnquiry()} className="enquiry-button button-reject">
                              Decline
                          </button>
                        </>
                      )
                    }
                  </div>
                )
              }
          </div>
          ):(
            <Loading />
          )
        }
      </div>
    </div>
  );
};

export default EnquiryDetails;
