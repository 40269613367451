import axios from "axios";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { api_url } from "../../url";
import { RotatingLines } from "react-loader-spinner";

const AdditionalInformation = ({
  activeStep,
  setActiveStep,
  handleFormChange,
  data,
  setData,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const saveAndExit = () => {
    window.location.href = "/admission";
  };

  // SAVE AND PROCEED
  const saveAndProceed = async () => {
    const validated_data = validateFields();
    if (validated_data) {
      setSubmitting(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
        },
      };
      console.log(data)
      const formData = new FormData();
      Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
      });
      
      try {
        await axios
          .post(`${api_url}admissions/apply/`, formData, config)
          .then((response) => {
            setData({ ...data, ["created_application"]: response.data });
            setSubmitting(false);
            setActiveStep(activeStep + 1);
          });
      } catch (error) {
        setSubmitting(false);
        console.log(error);
        toast.error("Error");
      }
    }
  };
  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };

  // VALIDATE FIELDS
  const validateFields = () => {
    if (data.medium === "") {
      toast.error("Please specify where you heard about us");
      return false;
    } else if (data.why_anell === "") {
      toast.error("Please specify why you chose Anell");
      return false;
    }
    return true;
  };

  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">Additional Information</h3>
        <div className="form-info-description">
          <p>Proceed to provide us with additional information</p>
        </div>
      </div>
      <form action="" className="main-admission-form">
        {/* HEARD FROM US */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Where did you hear from us? <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="radio"
                name="medium"
                value="Social Media"
                id=""
                defaultChecked={data.medium === "Social Media"}
                onChange={handleFormChange}
              />{" "}
              Social Media
              <input
                type="radio"
                name="medium"
                value="Print"
                id=""
                defaultChecked={data.medium === "Print"}
                onChange={handleFormChange}
              />{" "}
              Print
              <input
                type="radio"
                name="medium"
                value="Television"
                id=""
                defaultChecked={data.medium === "Television"}
                onChange={handleFormChange}
              />{" "}
              Television
              <input
                type="radio"
                name="medium"
                value="Radio"
                id=""
                defaultChecked={data.medium === "Radio"}
                onChange={handleFormChange}
              />{" "}
              Radio
              <input
                type="radio"
                name="medium"
                value="Family/Friend"
                id=""
                defaultChecked={data.medium === "Family/Friend"}
                onChange={handleFormChange}
              />{" "}
              Family/Friend
              <input
                type="radio"
                name="medium"
                value="Other"
                id=""
                defaultChecked={data.medium === "Other"}
                onChange={handleFormChange}
              />{" "}
              Other
            </div>
            {data.medium === "Family/Friend" && (
              <div className="main-admission-form-input">
                <input
                  type="text"
                  value={data.family_friend_medium}
                  onChange={handleFormChange}
                  name="family_friend_medium"
                  placeholder="Please indicate if Anell's parent"
                  id=""
                />
              </div>
            )}
            {data.medium === "Other" && (
              <div className="main-admission-form-input">
                <input
                  type="text"
                  name="other_medium"
                  value={data.other_medium}
                  id=""
                  placeholder="Please specify"
                  onChange={handleFormChange}
                />
              </div>
            )}
          </div>
        </div>

        {/* REASONS FOR ANELL */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            What are the reason(s) you have chosen Anell School of Excellence{" "}
            <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <textarea
                name="why_anell"
                value={data.why_anell}
                id=""
                cols="30"
                rows="5"
                onChange={handleFormChange}
              ></textarea>
            </div>
          </div>
        </div>

        <p className="form-info">
          Sometimes we take photographs or videos of children when they are
          involved in various activities at our school. These pictures and video
          recordings could be used by the school for displays, to promote the
          work of Anell in the media, on website and any authorized third party
          platforms. Please, if you have any reservations with your child’s
          image, write to the school
        </p>
        {/* PARENT INSTRUCTIONS */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Parent’s instruction for medical care in case of an emergency
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <textarea
                name="parent_instruction"
                value={data.parent_instruction}
                id=""
                cols="30"
                rows="5"
                onChange={handleFormChange}
              ></textarea>
            </div>
          </div>
        </div>

        {/* MEDICAL INFORMATION */}
        <div className="main-admission-form-row">
          <label htmlFor="">
            Please inform us, if he/she has any known medical condition, health
            issues or allergies which you would like us to be aware
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <textarea
                name="medical_information"
                value={data.medical_information}
                id=""
                cols="30"
                rows="5"
                onChange={handleFormChange}
              ></textarea>
            </div>
          </div>
        </div>

        {/* ADMISSION CLAUSES */}
        <div className="main-admission-form-row">
          <label className="admission-clauses-title" htmlFor="">
            ADMISSION CLAUSES
          </label>
          <div className="main-admission-form-right">
            <ul className="admission-clauses">
              <li className="admission-clause">
                The school reserves the right to determine the appropriate class
                for each applicant
              </li>
              <li className="admission-clause">
                An applicant will have to write an entry exams after submission
                of the admission form.
              </li>
              <li className="admission-clause">
                The admission fee is one-time payment whilst the school fees is
                payable per every school term (Crèche, Nursery, Kindergarten &
                Basic School) and is non-refundable or transferable.
              </li>
              <li className="admission-clause">
                All term fees should be settled by the first week of reopening
                and receipt will be issued detailing payment.
              </li>
              <li className="admission-clause">
                If fees are not paid by the end of the first week, a reminder
                will be sent to the parent/guardian and late fee of GH₵ 100.00
                be charged.
              </li>
              <li className="admission-clause">
                If the fees are not paid by the end of the second week of
                reopening, the admission would be terminated.
              </li>
              <li className="admission-clause">
                All fees are non- refundable & non- transferable.
              </li>
              <li className="admission-clause">
                I understand that either one full term’s notice in writing or
                payment of one full term’s fees in lieu is required in event of
                withdrawing the pupil for any reason.
              </li>
            </ul>
          </div>
        </div>

        {/* NOTES TO CONSIDER */}
        <div className="note-to-consider">
          <h3 className="note-title">NOTE</h3>
          <ul className="notes">
            <li className="note">
              Please attach a photocopy of the applicant’s birth certificate,
              the last academic report and 2 passport photographs.
            </li>
            <li className="note">
              Pupils will only be considered as candidates for admission and
              entry into ANELL SCHOOL OF EXCELLENCE when the admission form has
              been completed and returned with all relevant documents
            </li>
            <li className="note">
              The maximum number of students per class will not exceed 25.
            </li>
            <li className="note">
              Admission is subject to availability of space and the pupil
              satisfying the admission requirement.
            </li>
          </ul>
        </div>

        <p className="form-info">
          Before proceeding, please read the ADMISSION CLAUSES that are highlighted above carefully.
        </p>
        {submitting ? (
          <div style={{ textAlign: "center" }}>
            <RotatingLines
              strokeColor="#021D53"
              strokeWidth="5"
              animationDuration="0.75"
              width="26"
              visible={true}
            />
          </div>
        ) : (
          <div className="form-bottom">
            <div className="empty-section"></div>
            <div className="form-save-buttons">
              {activeStep > 1 && (
                <div className="save-button" onClick={() => previousStep()}>
                  Previous
                </div>
              )}
              <div className="save-button" onClick={() => saveAndExit()}>
                Save &amp; Exit
              </div>
              <div className="save-button" onClick={() => saveAndProceed()}>
                Save &amp; Proceed
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AdditionalInformation;
