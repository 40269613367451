import React,{useEffect} from 'react'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const GuardianDetails = ({activeStep, setActiveStep, data, handleFormChange}) => {
    const location = useLocation()

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

    const previousStep = () => {
        setActiveStep(activeStep - 1)
      }
    const saveAndExit = () => {
        window.location.href = "/admission"
      }
      const saveAndProceed = () => {
        const validated_fields = validateFields()
        if(validated_fields){
          setActiveStep(4)
        }
      }

      const validateFields = () => {
        if(data.guardian_name !== ""){
          if(data.guardian_telephone === ""){
            toast.error("Guardian Phone is required")
            return false
          }else if(data.guardian_nationality === ""){
            toast.error("Guardian Nationality is required")
            return false
          }else if(data.guardian_relationship_to_student === ""){
            toast.error("Please specify the relationship of the guardian to the applicant")
            return false
          }else if(data.guardian_home_address === ""){
            toast.error("Please enter the home address of the guardian")
            return false
          }else if(data.guardian_email === ""){
            toast.error("Please specify the email address of the guardian")
            return false
          }else if(data.guardian_occupation === ""){
            toast.error("Please specify the occupation of the guardian")
            return false
          }else if(data.guardian_company_name === ""){
            toast.error("Please specify the company name of the guardian")
            return false
          }else if(data.guardian_company_address === ""){
            toast.error("Please specify the company address of the guardian")
            return false
          }
        } 
        return true
      }
  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">Fill in personal of Guardian</h3>
        <div className="form-info-description">
          <p style={{color:"red"}}>Leave this section blank if the ward is been taken care of by the parents</p>
          <p>
            Fields marked as <span style={{ color: "red" }}>*</span> are
            required
          </p>
          <p>
            The names entered here must be same spelling and other as those used
            on official documents.
          </p>
        </div>
      </div>
      <form action="" className="main-admission-form">
        {/* NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_name" value={data.guardian_name} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* NATIONALITY */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Nationality <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_nationality" value={data.guardian_nationality} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* RELATIONSHIP TO STUDENT */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Relationship to student <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_relationship_to_student" value={data.guardian_relationship_to_student} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* TELEPHONE */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Telephone <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_telephone" value={data.guardian_telephone} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* HOME ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Home Address <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_home_address" onChange={handleFormChange} value={data.guardian_home_address}  id="" />
            </div>
          </div>
        </div>
        {/* OCCUPATION */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Occupation <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_occupation" value={data.guardian_occupation} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* EMAIL */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Email <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="email" name="guardian_email" onChange={handleFormChange} value={data.guardian_email} id="" />
            </div>
          </div>
        </div>
        {/* COMPANY NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_company_name" value={data.guardian_company_name} onChange={handleFormChange} id="" />
            </div>
          </div>
        </div>
        {/* COMPANY ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Address <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input type="text" name="guardian_company_address" onChange={handleFormChange} value={data.guardian_company_address} id="" />
            </div>
          </div>
        </div>

        <div className="form-bottom">
          <div className="empty-section"></div>
          <div className="form-save-buttons">
            {
              activeStep > 1 && (
              <div className="save-button" onClick={() => previousStep()}>
                  Previous
              </div>
              )
            }
            <div className="save-button" onClick={() => saveAndExit()}>
              Save &amp; Exit
            </div>
            <div className="save-button" onClick={() => saveAndProceed()}>
              Save &amp; Proceed
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default GuardianDetails