import React, {useEffect} from 'react'
import AdmissionSection from '../../components/AdmissionComponents/AdmissionSection/AdmissionSection'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import './Admissions.scss'
import { Link, useLocation } from 'react-router-dom'
import { BsWhatsapp } from 'react-icons/bs'

const AdmissionPage = () => {
  const location = useLocation()

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
        <TopBar />
        <Navbar />
        <AdmissionSection />
        <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
        <Footer />
    </div>
  )
}

export default AdmissionPage