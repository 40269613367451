export const NavItems = [
  {
      path:"/admin/dashboard",
      name:"Dashboard"
  },
  {
      path:"/admin/dashboard/admissions",
      name:"Admissions",
      submenus:[
          {
              path:"/admin/dashboard/admissions/applications",
              name:"Applications"
          },
          {
              path:"/admin/dashboard/admissions/enquiries",
              name:"Enquiries"
          }
      ]
  },
  {
    path:"/dashboard/file-manager",
    name:"File Manager",  
  },
  {
      path:"/admin/dashboard/academics",
      name:"Academics",
      submenus:[
          {
              path:"/admin/dashboard/classes",
              name:"Classes"
          },
          {
              path:"/admin/dashboard/academics/subjects",
              name:"Subjects"
          },
          {
              path:"/admin/dashboard/academics/teacher-subjects",
              name:"Teacher Subjects"
          },
          {
            path:"/admin/dashboard/anell-library",
            name:"Anell Library",
        },
        {
            path:"/admin/anell-meet/",
            name:"Anell Meet",
        },
      ]
  },
  
  
  {
      path:"/admin/dashboard/website-components",
      name:"Website Management",
      submenus:[
          {
              path:"/admin/website-components/news",
              name:"News"
          },
          {
              path:"/admin/website-components/calendar",
              name:"Calendar"
          }
      ]
  },
  {
      path:"/admin/dashboard/user-management",
      name:"User Management",
      submenus:[
          {
              path:"/admin/user-management/staffs",
              name:"Staffs"
          },
          {
              path:"/admin/user-management/students",
              name:"Students"
          }
      ]
  }
  // {
  //     path:"/dashboard/settings",
  //     name:"Settings"
  // },
]

export const TeacherNavItems = [
  {
      path:"/teacher/dashboard",
      name:"Dashboard"
  },
  
  {
      path:"/teacher/academics",
      name:"Academics",
      submenus:[
          {
              path:"/teacher/academics/subjects",
              name:"Your Subjects"
          },
          {
              path:"/teacher/academics/educational-materials",
              name:"Student Assignments"
          },
      ]
  },
  {
      path:"/library",
      name:"Anell Library",
      submenus:[
          {
              path:"/admin/dashboard/anell-library",
              name:"Anell Library"
          },
          {
              path:"/teacher/library/uploads",
              name:"Your Uploads"
          },
      ]
  },
  {
    path:"/dashboard/file-manager",
    name:"File Manager",  
  },
  {
    path:"/teacher/academics/anell-meet",
    name:"Anell Meet",  
  },
  
  
 
  
  // {
  //     path:"/dashboard/settings",
  //     name:"Settings"
  // },
]

export const StudentNavItems = [
  {
      path:"/student/dashboard",
      name:"Dashboard"
  },
  
  {
      path:"/student/academics",
      name:"Academics",
      submenus:[
          {
              path:"/student/academics/subjects",
              name:"Subjects"
          },
          {
            path:"/student/academics/anell-meet",
            name:"Anell Meet"
          },
      ]
  },
  {
      path:"/student/anell-library",
      name:"Anell Library",
  },
  
  
 
  
  // {
  //     path:"/dashboard/settings",
  //     name:"Settings"
  // },
]