import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const EditAcademicTermModal = ({
  academicTerm,
  editAcademicTermModal,
  setEditAcademicTermModal,
  academicYears
}) => {
  const [data, setData] = useState(null);
  const [updatingAcademicTerm, setUpdatingAcademicTerm] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateAcademicTerm = async (e) => {
    e.preventDefault();

    setUpdatingAcademicTerm(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .put(`${api_url}academics/academic-term/${academicTerm?.id}/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Academic Term Updated Successfully",
        }).then(() => {
            setUpdatingAcademicTerm(false);
          setEditAcademicTermModal(false);
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log(error);
        setUpdatingAcademicTerm(false);
      });
  };

  return (
    <div
      onClick={() => setEditAcademicTermModal(!editAcademicTermModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setEditAcademicTermModal(!editAcademicTermModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Update Academic Term</p>
          <form
            onSubmit={(e) => updateAcademicTerm(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Academic Year</label>
                <select
                  name="academic_year"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Academic Year</option>
                  {academicYears?.map((academic_year) => (
                    <option key={academic_year.id} selected={academicTerm?.academic_year == academic_year?.id} value={academic_year.id}>
                      {academic_year.year_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Academic Term Name</label>
                <input
                  required
                  type="text"
                  name="term_name"
                  id="term_name"
                  value={data?.term_name ? data?.term_name : academicTerm.term_name}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Term Start Date</label>
                <input
                  required
                  type="date"
                  name="start_date"
                  id="start_date"
                  value={data?.start_date ? data.start_date : academicTerm?.start_date}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Term End Date</label>
                <input
                  required
                  type="date"
                  name="end_date"
                  id="end_date"
                  value={data?.end_date ? data.end_date : academicTerm?.end_date}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="admission-enquiry-form-row">
              <div>
                <label htmlFor="">
                  Is this the active academic term? 
                </label> <br />
                <input
                  type="radio"
                  name="is_active"
                  value={true}
                  defaultChecked={data?.is_active ? data?.is_active === true : academicTerm?.is_active === true}
                  onChange={(e) => handleFormChange(e)}
                />{" "}
                Yes &nbsp; &nbsp;
                <input
                  type="radio"
                  name="is_active"
                  value={false}
                  defaultChecked={data?.is_active ? data?.is_active === false : academicTerm?.is_active === false}
                  onChange={(e) => handleFormChange(e)}
                />{" "}
                No
              </div>
            </div>
            {updatingAcademicTerm ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAcademicTermModal;
