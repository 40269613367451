import React from 'react'
import DashboardNavigation from '../../../../components/PortalComponents/DashboardNavigation/DashboardNavigation'

const EducationalMaterials = () => {
    const navigations = [
        "Dashboard", "Academics", "Educational Materials"
      ]
  return (
    <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations = {navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Educational Materials</div>


          
        </div>
      </div>
  )
}

export default EducationalMaterials