import React, { useEffect, useState } from 'react'
import DashboardTopBar from '../../../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../../../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import DashboardNavigation from '../../../components/PortalComponents/DashboardNavigation/DashboardNavigation'
import Loading from '../../../components/Loading/Loading'
import { RotatingLines } from 'react-loader-spinner'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../../url'
import NoData from '../../../components/PortalComponents/NoData/NoData'
import AddSubjectModal from './AddSubjectModal'
import EditSubjectModal from './EditSubjectModal'
import { toast } from 'react-toastify'
import Swal from "sweetalert2";

const Subjects = () => {
    const navigations = ["Dashboard","Academics","Subjects"]
    const [gettingSubjects, setGettingSubjects] = useState(true)
    const [subjects, setSubjects] = useState([])
    const [addSubjectModal, setAddSubjectModal] = useState(false)
    const [editSubjectModal, setEditSubjectModal] = useState(false)
    const [deletingSubject, setDeletingSubject] = useState(false)
    const [selectedSubject, setSelectedSubject] = useState({})

    const getSubjects = async () =>{
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `JWT ${localStorage.getItem('access')}`
            }
        }
        await axios.get(`${api_url}academics/subjects/`, config).then((response)=>{
            setSubjects(response.data)
            setGettingSubjects(false);
        }).catch((error)=>{
            console.error(error)
            setGettingSubjects(false);
        })
    }

    const deleteSubject = (subject_id) => {
      Swal.fire({
        title: `Are you sure?`,
        text: "Once deleted, there is no going back",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setDeletingSubject(true);
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${localStorage.getItem("access")}`,
            },
          };
          await axios
            .delete(`${api_url}academics/subjects/${subject_id}/`, config)
            .then((response) => {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Subject Deleted Successfully",
              }).then(() => {
                setDeletingSubject(false);
                getSubjects();
              });
            })
            .catch((error) => {
              setDeletingSubject(false);
              
              toast.error(
                "There was an error deleting the class, please try again."
              );
            });
        }
      });
    };

    useEffect(()=>{
        getSubjects()
    },[])

    return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        {
            addSubjectModal && (
                <AddSubjectModal
                    getSubjects={getSubjects}
                    addSubjectModal={addSubjectModal}
                    setAddSubjectModal={setAddSubjectModal}
                />
            )
        }
        {
          editSubjectModal && (
            <EditSubjectModal 
              selectedSubject={selectedSubject}
              getSubjects={getSubjects}
              editSubjectModal={editSubjectModal}
              setEditSubjectModal={setEditSubjectModal}
            />
          )
        }
        <div className="portal-page">
            <div className="admin-dashboard-title">
                <DashboardNavigation navigations={navigations} />
            </div>
            <div className="section-list classes-list-section">
          <div className="table-section-header">Classes</div>

          <Link
            onClick={() => setAddSubjectModal(true)}
            className="primary-button-outline w-max-content"
            style={{ marginBlock: "5px" }}
          >
            Add Subject
          </Link>

          {gettingSubjects ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Subject Name</th>                      
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjects &&
                      subjects.map((subject, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{subject?.subject_name}</td>
                          
                          <td>
                            {deletingSubject ? (
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            ) : (
                              <div className="action-buttons">
                                
                                <Link
                                  to={``}
                                  className="view-button"
                                  onClick={() => {
                                    setSelectedSubject(subject);
                                    setEditSubjectModal(true);
                                  }}
                                >
                                  Edit <AiOutlineEdit />
                                </Link>
                                <Link
                                  className="delete-button"
                                  onClick={() => deleteSubject(subject?.id)}
                                >
                                  Delete <AiOutlineDelete />
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                    subjects.length <=0 && <NoData />
                }
              </>
            </div>
          )}

        </div>
        </div>
    </div>
  )
}

export default Subjects