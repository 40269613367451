import React from 'react'
import './FaqSection.scss'
import Accordion from '../../Accordion/Accordion'
import SectionHeader from '../../SectionHeader/SectionHeader'

const FaqSection = () => {
    const leftSections = [
        {
            name:"What is the teacher-to-student ratio at your preschool?",
            answer:"At our preschool, we maintain a low teacher-to-student ratio of 2:25. This ensures that each child receives personalized attention and care, fostering a positive and nurturing learning environment."
        },
        {
            name:"What is the curriculum and teaching approach followed at your preschool?",
            answer:"At our preschool, we maintain a low teacher-to-student ratio of 2:25. This ensures that each child receives personalized attention and care, fostering a positive and nurturing learning environment."
        },
        {
            name:"How do you ensure the safety and security of the children at your preschool?",
            answer:"At our preschool, we maintain a low teacher-to-student ratio of 2:25. This ensures that each child receives personalized attention and care, fostering a positive and nurturing learning environment."
        },
        {
            name:"What extracurricular activities or enrichment programs do you offer?",
            answer:"At our preschool, we maintain a low teacher-to-student ratio of 2:25. This ensures that each child receives personalized attention and care, fostering a positive and nurturing learning environment."
        },
        {
            name:"How do you communicate with parents about their child's progress?",
            answer:"At our preschool, we maintain a low teacher-to-student ratio of 2:25. This ensures that each child receives personalized attention and care, fostering a positive and nurturing learning environment."
        },
        {
            name:"How do you handle children's social and emotional development?",
            answer:"We understand the significance of social and emotional growth during the early years. Our teachers are trained to create a supportive and inclusive environment where children learn to express their feelings, cooperate with others, and develop healthy relationships. We incorporate activities that encourage empathy, conflict resolution, and self-regulation skills."
        }

    ]

    const rightSections = [
        {
            name:"What is your approach to potty training?",
            answer:"We understand the significance of social and emotional growth during the early years. Our teachers are trained to create a supportive and inclusive environment where children learn to express their feelings, cooperate with others, and develop healthy relationships. We incorporate activities that encourage empathy, conflict resolution, and self-regulation skills."
        },
        {
            name:"How do you accommodate children with special needs or learning differences?",
            answer:"We understand the significance of social and emotional growth during the early years. Our teachers are trained to create a supportive and inclusive environment where children learn to express their feelings, cooperate with others, and develop healthy relationships. We incorporate activities that encourage empathy, conflict resolution, and self-regulation skills."
        },
        {
            name:"What measures do you take to ensure a healthy and nutritious diet for the children?",
            answer:"We understand the significance of social and emotional growth during the early years. Our teachers are trained to create a supportive and inclusive environment where children learn to express their feelings, cooperate with others, and develop healthy relationships. We incorporate activities that encourage empathy, conflict resolution, and self-regulation skills."
        },
        {
            name:"How do you handle discipline and behavior management?",
            answer:"We understand the significance of social and emotional growth during the early years. Our teachers are trained to create a supportive and inclusive environment where children learn to express their feelings, cooperate with others, and develop healthy relationships. We incorporate activities that encourage empathy, conflict resolution, and self-regulation skills."
        },
        {
            name:"What is your approach to fostering a love for learning in young children?",
            answer:"Our approach is centered on making learning fun and engaging. We cultivate a curiosity-driven environment where children are encouraged to explore, question, and discover. Our teachers use creative teaching methods to spark children's interest, and we adapt activities to align with their interests and developmental levels, ultimately fostering a lifelong love for learning"
        },
        

    ]
  return (
    <div id="faq-section" className='main-container'>
        <SectionHeader title="Frequently Asked Questions" />
        <div className="frequently-asked-questions">
            <Accordion sections={leftSections} />
            <Accordion sections={rightSections} />
        </div>
    </div>
  )
}

export default FaqSection