import React, { useState } from "react";
import "./CalendarAccordion.scss";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";
import NoData from "../PortalComponents/NoData/NoData";
import EditActivityModal from "../../pages/PortalPages/PortalCalendar/EditActivityModal";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const CalendarAccordion = ({
  calendarEvent,
  isActiveSection,
  setActiveIndex,
  sectionIndex,
  editable,
  setActivityModal,
}) => {
  const toggleSection = () => {
    const nextIndex = isActiveSection ? null : sectionIndex;
    setActiveIndex(nextIndex);
  };
  console.log(calendarEvent)
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [editActivityModal, setEditActivityModal] = useState(false)
  const [deletingActivity, setDeletingActivity] = useState(false)

  const deleteActivity = async (activity_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingActivity(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
        .delete(`${api_url}academics/term-activity/${activity_id}/`, config)
        .then((response) => {
          if(response?.data?.success){
            Swal.fire({
              icon: "success",
              title: "Deleted",
              text: "Activity deleted successfully",
            }).then(() => {
              window.location.reload();
            })
          }else{
            if(response?.data?.message){
              toast.error(response?.data?.message)
            }else{
              toast.error("Something went wrong. Please try again later")
            }
          }
          setDeletingActivity(false)
          
        }).catch(error => {
          setDeletingActivity(false);
          toast.error("Something went wrong. Please try again later.")
        }); 
      }
    });
    

    
  };

  return (
    <>
      {
        editActivityModal && <EditActivityModal selectedActivity={selectedActivity} editActivityModal={editActivityModal} setEditActivityModal={setEditActivityModal} />
      }
      <div
        id="calendar-accordion"
        className={`${
          isActiveSection
            ? "calendar-accordion-item-open"
            : "calendar-accordion-item-close"
        }`}
      >
        <div className="calendar-accordion-item-title" onClick={toggleSection}>
          <p>{calendarEvent.title}</p>
          {isActiveSection ? <IoRemoveCircleOutline /> : <IoAddCircleOutline />}
        </div>
        <div className="calendar-accordion-details">
          <div
            className="activities-top"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <p className="activities-title">Activities</p>
            {editable && (
              <div
                onClick={() => setActivityModal(true)}
                className="primary-button-outline w-max-content"
                style={{ marginBottom: "20px" }}
              >
                Add New Activity
              </div>
            )}
          </div>
          <table className="activities-list">
            <tbody>
              {calendarEvent.activities.map((activity, index) => (
                <tr
                  className={`activity ${
                    activity?.is_event && "important-event"
                  }`}
                  key={index}
                >
                  <td className="activity-name">
                    <p style={{ marginBottom: "5px" }}>
                      {activity.activity_name}
                    </p>
                  </td>
                  <td className="activity-date">
                    {activity.start_date} &nbsp;
                    {activity?.end_date && <>- {activity?.end_date}</>}

                    {
                      editable && (
                        <>
                          {
                            deletingActivity ? (
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            ):(
                              <span style={{fontWeight:"500"}}>
                                <span
                                  onClick={()=>{
                                    setSelectedActivity(activity);
                                    setEditActivityModal(true)
                                  }}
                                  className="text-underline cursor-pointer"
                                  style={{ color: "#0571E1", marginRight: "5px" }}
                                >
                                  Edit
                                </span>
                                <span
                                  onClick={()=>deleteActivity(activity.id)}
                                  className="text-underline cursor-pointer"
                                  style={{ color: "#ED3137", marginRight: "5px" }}
                                >
                                  Delete
                                </span>
                              </span>
                            )
                          }
                        </>
                      )
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {calendarEvent.activities.length <= 0 && <NoData />}
          {/* {calendarEvent?.additional_info && (
            <div className="calendar-bottom">{calendarEvent.additional_info}</div>
          )} */}
          {/* {editable && <div className="primary-button">Add Additional Info</div>} */}
        </div>
      </div>
    </>
  );
};

export default CalendarAccordion;
