import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";


const EditAcademicYearModal = ({editAcademicYearModal, setEditAcademicYearModal, academicYear}) => {
    const [data, setData] = useState({});
    const [updatingAcademicYear, setUpdatingAcademicYear] = useState(false);
  
    const handleFormChange =(e)=>{
        setData({
          ...data, [e.target.name]:e.target.value
        })
      }

    const updateAcademicYear = async (e) => {
        e.preventDefault();
    
        setUpdatingAcademicYear(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
    
        const body = JSON.stringify(data)
        await axios
          .put(`${api_url}academics/academic-year/${academicYear?.id}/`, body, config)
          .then((response) => {
            Swal.fire({
              title: "Success",
              icon: "success",
              text: "Academic Year Updated Successfully",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            setUpdatingAcademicYear(false);
          });
      };

    return (
        <div
        onClick={() => setEditAcademicYearModal(!editAcademicYearModal)}
        className="modal-overlay"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="modal-container min-w-50"
        >
          <div
            className="close-button"
            onClick={() => setEditAcademicYearModal(!editAcademicYearModal)}
          >
            <AiOutlineClose />
          </div>
          <div className="admission-modal-container ">
            <div className="school-logo">
              <div className="school-name">
                <div className="school-main-name">Anell</div>
                <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
              </div>
            </div>
  
            <p className="admission-modal-text">Update Academic Year</p>
            <form onSubmit={(e)=>updateAcademicYear(e)} className="admission-enquiry-form">
              <div className="admission-enquiry-form-row">
                <div className="admission-enquiry-form-input">
                  <label htmlFor="">Academic Year Name</label>
                  <input
                    required
                    type="text"
                    name="year_name"
                    id="year_name"
                    value={data?.year_name ? data?.year_name : academicYear?.year_name}
                    onChange={(e)=>handleFormChange(e)}
                  />
                </div>
              </div>
  
              <div className="admission-enquiry-form-row">
                <div>
                  <label htmlFor="">
                    Is this the active academic year? 
                  </label> <br />
                  <input
                    type="radio"
                    name="is_active"
                    value={true}
                    defaultChecked={data?.is_active ? data?.is_active === true : academicYear?.is_active === true}
                    onChange={(e) => handleFormChange(e)}
                  />{" "}
                  Yes &nbsp; &nbsp;
                  <input
                    type="radio"
                    name="is_active"
                    value={false}
                    defaultChecked={data?.is_active ? data?.is_active === false : academicYear?.is_active === false}
                    onChange={(e) => handleFormChange(e)}
                  />{" "}
                  No
                </div>
              </div>
  
              {updatingAcademicYear ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RotatingLines
                    strokeColor="#021D53"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="26"
                    visible={true}
                  />
                </div>
              ) : (
                <input type="submit" value="Submit" />
              )}
            </form>
          </div>
        </div>
      </div>
  )
}

export default EditAcademicYearModal