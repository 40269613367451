import React from 'react'
import './SectionHeader.scss'

const SectionHeader = ({title}) => {
  return (
    <div className='section-header'>
        <h2>{title}</h2>
        <div className="underline"></div>
    </div>
  )
}

export default SectionHeader