import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { Link } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import AcademicYearModal from "./AcademicYearModal";
import axios from "axios";
import { api_url } from "../../../url";
import AcademicTermModal from "./AcademicTermModal";
import EditAcademicYearModal from "./EditAcademicYearModal";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import EditAcademicTermModal from "./EditAcademicTermModal";

const PortalCalendar = () => {
  const navigations = ["Dashboard", "Calendar"];
  const [loadingData, setLoadingData] = useState(false);
  const [academicYearModal, setAcademicYearModal] = useState(false);
  const [editAcademicYearModal, setEditAcademicYearModal] = useState(false);
  const [editAcademicTermModal, setEditAcademicTermModal] = useState(false);
  const [academicTermModal, setAcademicTermModal] = useState(false);
  const [academicYears, setAcademicYears] = useState([]);
  const [selectedAcademicYear, setSelectedAcademicYear] = useState(null);
  const [selectedAcademicTerm, setSelectedAcademicTerm] = useState(null);
  const [deletingAcademicYear, setDeletingAcademicYear] = useState(false);
  const [deletingAcademicTerm, setDeletingAcademicTerm] = useState(false);

  const getAcademicYears = async () => {
    setLoadingData(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}academics/academic-year/`, config)
      .then((response) => {
        setAcademicYears(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false);
      });
  };

  const deleteAcademicYear = (academic_year_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete all the academic terms and activities related to this academic year.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#221B2D",
      cancelButtonColor: "#ED3137",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingAcademicYear(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(
            `${api_url}academics/academic-year/${academic_year_id}/`,
            config
          )
          .then((response) => {
            setDeletingAcademicYear(false);
            Swal.fire({
              title: "Deleted!",
              text: "Academic Year has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            setDeletingAcademicYear(false);
          });
      }
    });
  };

  const deleteAcademicTerm = (academic_term_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will delete all the activities related to this academic term.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#221B2D",
      cancelButtonColor: "#ED3137",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingAcademicTerm(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(
            `${api_url}academics/academic-term/${academic_term_id}/`,
            config
          )
          .then((response) => {
            setDeletingAcademicTerm(false);
            Swal.fire({
              title: "Deleted!",
              text: "Academic Term has been deleted.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {
            console.log(error);
            setDeletingAcademicTerm(false);
          });
      }
    });
  };

  useEffect(() => {
    getAcademicYears();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {academicYearModal && (
        <AcademicYearModal
          academicYearModal={academicYearModal}
          setAcademicYearModal={setAcademicYearModal}
        />
      )}
      {academicTermModal && (
        <AcademicTermModal
          academicTermModal={academicTermModal}
          setAcademicTermModal={setAcademicTermModal}
          academicYears={academicYears}
        />
      )}
      {editAcademicYearModal && (
        <EditAcademicYearModal
          editAcademicYearModal={editAcademicYearModal}
          setEditAcademicYearModal={setEditAcademicYearModal}
          academicYear={selectedAcademicYear}
        />
      )}

      {editAcademicTermModal && (
        <EditAcademicTermModal
          editAcademicTermModal={editAcademicTermModal}
          setEditAcademicTermModal={setEditAcademicTermModal}
          academicTerm={selectedAcademicTerm}
          academicYears={academicYears}
        />
      )}
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list">
          <div className="table-section-header">Calendar</div>
          <span
            onClick={() => setAcademicYearModal(true)}
            className="primary-button-outline w-max-content"
            style={{ margin: "5px", display: "inline-flex" }}
          >
            Add Academic Year
          </span>
          <span
            onClick={() => setAcademicTermModal(true)}
            className="primary-button w-max-content"
            style={{ margin: "5px", display: "inline-flex" }}
          >
            Add Academic Term
          </span>
          {loadingData ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Academic Year</th>
                      <th>Academic Terms</th>
                      <th>No. Of Activities</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {academicYears.map((academic_year, index) => (
                      <tr key={index}>
                        <td>
                          <span style={{ marginRight: "15px" }}>
                            {academic_year.year_name}
                          </span>
                          {deletingAcademicYear ? (
                            <RotatingLines
                              strokeColor="#021D53"
                              strokeWidth="5"
                              animationDuration="0.75"
                              width="26"
                              visible={true}
                            />
                          ) : (
                            <div>
                              <span
                                onClick={() => {
                                  console.log(academic_year);
                                  setSelectedAcademicYear(academic_year);
                                  setEditAcademicYearModal(true);
                                }}
                                className="text-underline cursor-pointer"
                                style={{ color: "#0571E1", marginRight: "5px" }}
                              >
                                Edit
                              </span>
                              <span
                                onClick={() => {
                                  deleteAcademicYear(academic_year.id);
                                }}
                                className="text-underline cursor-pointer"
                                style={{ color: "#ED3137", marginRight: "5px" }}
                              >
                                Delete
                              </span>
                            </div>
                          )}
                        </td>
                        <td>
                          <ul>
                            {academic_year.terms.map((term, index) => (
                              <li key={index}>
                                <span style={{ marginRight: "15px" }}>
                                  {term.term_name}
                                </span>
                                {deletingAcademicTerm ? (
                                  <RotatingLines
                                    strokeColor="#021D53"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="26"
                                    visible={true}
                                  />
                                ) : (
                                  <div>
                                    <span
                                      onClick={() => {
                                        setSelectedAcademicTerm(term);
                                        setEditAcademicTermModal(true);
                                      }}
                                      className="text-underline cursor-pointer"
                                      style={{
                                        color: "#0571E1",
                                        marginRight: "5px",
                                      }}
                                    >
                                      Edit
                                    </span>
                                    <span
                                      onClick={() => {
                                        deleteAcademicTerm(term.id);
                                      }}
                                      className="text-underline cursor-pointer"
                                      style={{
                                        color: "#ED3137",
                                        marginRight: "5px",
                                      }}
                                    >
                                      Delete
                                    </span>
                                  </div>
                                )}
                              </li>
                            ))}
                          </ul>
                        </td>
                        <td>{academic_year.activities_count}</td>
                        <td>
                          <div className="action-buttons">
                            <Link
                              to={`/admin/website-components/calendar/${academic_year.id}/activities/`}
                              className="view-button"
                            >
                              View Activities <AiOutlineEye />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {academicYears.length <= 0 && <NoData />}
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortalCalendar;
