import React, { useEffect, useState } from 'react'
import DashboardTopBar from '../../../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../../../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import DashboardNavigation from '../../../components/PortalComponents/DashboardNavigation/DashboardNavigation'
import Loading from '../../../components/Loading/Loading'
import { LuDownload } from 'react-icons/lu'
import NoData from '../../../components/PortalComponents/NoData/NoData'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { api_url } from '../../../url'
import { RotatingLines } from 'react-loader-spinner'

const AnellLibrary = () => {
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [documents, setDocuments] = useState([])
    const [schoolClass, setSchoolClass] = useState("")
    const [subject, setSubject] = useState("")
    const [schoolClasses, setSchoolClasses] = useState([]);
    const [subjects, setSubjects] = useState([]);

    const navigations = [
        "Dashboard", "Anell Library"
    ]

    const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = documents.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(documents.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % documents.length;
    setItemOffset(newOffset);
  };

  const getLibraryDocuments = async (e) => {
    e.preventDefault()
    if(schoolClass === ""){
        return toast.error("Please select a class");
    }else if(subject === ""){
        return toast.error("Please select a subject");
    }
    setLoadingDocuments(true)
    const config = {
        headers:{
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
        }
    }

    await axios.get(`${api_url}library/anell-library/${schoolClass}/${subject}/`,config).then((response)=>{
        setLoadingDocuments(false);
        setDocuments(response.data)
    }).catch((error)=>{
        toast.error("Couldn't get data");
        setLoadingDocuments(false)
    })

  }

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setSchoolClasses(response.data);
      })
      .catch((error) => {});
  };

  const getSubjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}academics/subjects/`, config)
      .then((response) => {
        setSubjects(response.data);
      })
      .catch((error) => {});
  };

  useEffect(()=>{
    getClasses();
    getSubjects();
  },[])

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="admission-applications" className="portal-page">
        <div className="admin-dashboard-title">
        <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list">
          <div className="table-section-header">Anell Library</div>
          <form onSubmit={(e)=>getLibraryDocuments(e)} className='filter-group'>
            <select name="school_class" onChange={(e)=>setSchoolClass(e.target.value)} id="" className='form-item'>
                <option value="">Select Class</option>
                <option value="All">All Classes</option>
                {
                    schoolClasses?.map((school_class, index)=>(
                        <option key={index} value={school_class.id}>{school_class.class_name}</option>
                    ))
                }
            </select>
            <select name="subject" onChange={(e)=>setSubject(e.target.value)} id="" className='form-item'>
                <option value="">Select Subject</option>
                <option value="All">All Subjects</option>
                {
                    subjects?.map((subject, index)=>(
                        <option key={index} value={subject.id}>{subject.subject_name}</option>
                    ))
                }
            </select>
            {
                loadingDocuments ? (
                    <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                ):<button className='primary-button' type="submit">Filter</button>
            }
            
          </form>
          {loadingDocuments ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Document Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents &&
                      currentItems?.map((upload, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{upload?.school_class}</td>
                          <td>{upload?.subject}</td>
                          <td>{upload?.document_name}</td>
                          
                            <td style={{ cursor: "pointer" }}>
                              <Link
                                target="_blank"
                                download
                                to={`${api_url}${upload?.document}`}
                                title="Download Document"
                              >
                                <LuDownload />
                              </Link>
                              
                            </td>
                          
                        </tr>
                      ))}
                  </tbody>
                </table>
                {documents.length <= 0 && <NoData />}
              </>
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AnellLibrary