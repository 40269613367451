import React, {useState} from 'react'
import AccordionItem from '../AccordionItem/AccordionItem'

const Accordion = ({sections}) => {
    const [activeIndex, setActiveIndex] = useState(0)
  return (
    <div style={{flex:1}}>
        {sections.map((section,index)=>{
            return <AccordionItem section={section} key={index} isActiveSection={index === activeIndex}  setActiveIndex={setActiveIndex} sectionIndex={index}/>
        })}
    </div>
  )
}

export default Accordion