import React, {useState} from 'react'
import './DashboardNavItem.scss'
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import { NavLink } from 'react-router-dom'

const DashboardNavItem = ({item}) => {
    const [openSubmenu, setOpenSubmenu] = useState(false)
    const toggleSubmenu = () => {
        setOpenSubmenu(!openSubmenu)
    }
    return (
    <li className='dashboard-menu-item'>
        {
            item.submenus ? (
                <div className='dashboard-nav-link-item' onClick={toggleSubmenu}>
                    {item.name}
                    <MdOutlineKeyboardArrowDown onClick={toggleSubmenu} />
                </div>
            ):(
                <NavLink to={item.path} className="dashboard-nav-link-item">
                    {item.name}
                    {
                        item.submenus && (
                            <MdOutlineKeyboardArrowDown onClick={toggleSubmenu}/>
                        )
                    }
                </NavLink>
            )
        }
        {
            item.submenus && item.submenus.length > 0 && (
                <ul className={`${openSubmenu ? " dashboard-submenu open-dashboard-submenu" : "dashboard-submenu close-dashboard-submenu"}`}>
                    {
                        item.submenus.map((submenu, index) => (
                            <li className='dashboard-submenu-item' key={index}>
                                <NavLink to={submenu.path} className="dash-sub-nav-link-item">
                                    {submenu.name}
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
            )
        }
    </li>
  )
}

export default DashboardNavItem