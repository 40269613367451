import React from "react";
import { useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import TopBar from "../../components/TopBar/TopBar";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import "./AdmissionToken.scss";
import axios from "axios";
import { api_url } from "../../url";

const AdmissionToken = () => {
  const [token, setToken] = useState("");
  const [applicantName, setApplicantName] = useState("")
  const [applicantTitle, setApplicantTitle] = useState("")
  const [validating, setValidating] = useState(false);

  const submitToken = async (e) => {
    e.preventDefault();
    setValidating(true)
    const body = JSON.stringify({
      token: token
    });
    localStorage.setItem("applicantName", applicantName);
    localStorage.setItem("applicantTitle", applicantTitle);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    try {
      await axios
        .get(`${api_url}admissions/application-token/${token}/`, config)
        .then((response) => {
          // if(response.data['is_used']){
          //   toast.error("Token has already been used to make an application")
          // }else{
          //     toast.success("valid token")
          //     window.location.href = `/admission/${token}/application`
          // }
          toast.success("valid token")
          window.location.href = `/admission/${token}/application`
          setValidating(false)
        });
    } catch (error) {
        setValidating(false)
      if(error.response.status === 400){
        toast.error(error.response.data)
      }
    }
  };
  return (
    <div>
      <TopBar />
      <Navbar />
      <div id="admission-token" className="main-container">
        <form onSubmit={(e) => submitToken(e)}>
          <div className="admission-token-form-row">
            <div className="admission-token-form-input">
              <label htmlFor="">Title</label>
              <select
                name="title"
                id="title"
                required
                defaultValue={applicantTitle}
                onChange={(e) =>
                  setApplicantTitle(e.target.value)
                }
              >
                <option value="">Select Title</option>
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Miss">Miss</option>
              </select>
            </div>
            <div className="admission-token-form-input">
              <label htmlFor="">Your name</label>
              <input
                type="text"
                required
                name="name_of_applicant"
                id="name_of_applicant"
                value={applicantName}
                onChange={(e) =>
                  setApplicantName(e.target.value)
                }
              />
            </div>
          </div>
          <div className="admission-token-form-row">
            <div className="admission-token-form-input">
              <label htmlFor="">
                Enter the code you received via your mail here:
              </label>
              <input
                required
                value={token}
                onChange={(e) => setToken(e.target.value)}
                type="text"
                name=""
                id=""
              />
            </div>
          </div>
          {validating ? (
            <>
              <RotatingLines
                strokeColor="#021D53"
                strokeWidth="5"
                animationDuration="0.75"
                width="26"
                visible={true}
              />
            </>
          ) : (
            <input type="submit" value="Continue" />
          )}
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default AdmissionToken;
