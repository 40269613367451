import React from 'react'
import './NoData.scss'
import NoDataImage from '../../../assets/nodata.jpg'


const NoData = () => {
  return (
    <div className='no-data'>
      <img src={NoDataImage} alt="" />
      <h3>
        NO DATA AVAILABLE
      </h3>
    </div>
  )
}

export default NoData