import React, { useEffect, useState } from "react";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import Loading from "../../../components/Loading/Loading";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../url";

const StudentSubjects = () => {
  const [loadingStudentSubjects, setLoadingStudentSubjects] = useState(true);
  const [studentSubjects, setStudentSubjects] = useState([]);

  const navigations = ["Dashboard", "Academics", "Subjects"];

  const getStudentSubjects = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}student/subjects/`, config)
      .then((response) => {
        if(response?.data?.success){
            setStudentSubjects(response.data?.payload);
        }else{
            if(response?.data?.message){
                toast.error(response?.data?.message)
            }else{
                toast.error("An error occured")
            }
        }
        setLoadingStudentSubjects(false);
      })
      .catch((error) => {
        toast.error("An Unexpected error occured")
        setLoadingStudentSubjects(false);
      });
  };

  useEffect(()=>{
    getStudentSubjects();
  },[])

  return (
    <div id="school-classes" className="portal-page">
      <div className="admin-dashboard-title">
        <DashboardNavigation navigations={navigations} />
      </div>
      <div className="section-list classes-list-section">
        <div className="table-section-header">Your Subjects</div>

        {loadingStudentSubjects ? (
          <Loading />
        ) : (
          <div className="section-list-table">
            <>
              <table>
                <thead>
                  <tr>
                    <th>SRL</th>
                    <th>Subject Name</th>
                    <th>Subject Teacher</th>
                  </tr>
                </thead>
                <tbody>
                  {studentSubjects &&
                    studentSubjects.map((student_subject, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{student_subject?.subject}</td>
                        <td>{student_subject?.teacher}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {studentSubjects.length <= 0 && <NoData />}
            </>
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentSubjects;
