import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/Anell.png";
import "./AboutSection.scss";

const AboutSection = () => {
  return (
    <div className="about-section">
      <div className="about-left">
        <h1>Welcome To</h1>
        <div className="school-logo">
          <img src={Logo} alt="anell school of excellence" />
          <span className="text">
            <div className="main-text">
              <h2>Anell</h2>
              <span>
                <p>SCHOOL OF</p>
                <p>EXCELLENCE</p>
              </span>
            </div>
            <p className="motto">...excel and lead!!!</p>
          </span>
        </div>
      </div>
      <div className="about-right">
        <div className="about-school">
          <p>
            Anell School of Excellence is a multipurpose school aimed at
            providing individualized education that addresses learners unique
            learning styles, which cultivates independent thoughts to make them
            creative builders of their present and future. The holistic
            (cognitive, physical, social and emotional) development of our
            learners is our main priority.
          </p>
          <p>
            Anell provides quality education through modern technological
            strategies of learners from three (3) months to fourteen (14) years
            thus from Creche to J.H.S 3. The intention of the school’s
            management is to feed the upper grade gradually with the lower
            grade.
          </p>
          <p>
            Our preschool combines learning experiences and structured play in a
            fun, safe and nurturing environment. <br /> Differently , our creche
            offers far more than just toddler care, rather enhances beginners’
            development through series of Early Years Foundation activities such
            as dexterity activities, oral, auditory and sensorial discovery
            activities. ensure safety and security.
          </p>
        </div>
        <Link to="/about/overview" className="classic-button">Read More</Link>
      </div>
    </div>
  );
};

export default AboutSection;
