import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './pages/Home/Home';
import Overview from './pages/Overview/Overview';
import LeadersMessage from './pages/LeadersMessage/LeadersMessage';
import Departments from './pages/Departments/Departments';
import FAQ from './pages/FAQ/FAQ';
import Careers from './pages/Careers/Careers';
import AdmissionPage from './pages/AdmissionPage/AdmissionPage';
import NewsPage from './pages/NewsPage/NewsPage';
import ContactPage from './pages/ContactPage/ContactPage';
import Calendar from './pages/Calendar/Calendar';
import AdmissionToken from './pages/AdmissionToken/AdmissionToken';
import AdmissionForm from './pages/AdmissionForm/AdmissionForm';
import LoginPage from './pages/PortalPages/LoginPage/LoginPage';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PrivateRoutes from './utils/PrivateRoutes';
import { AuthProvider } from './context/authContext';
import Medicals from './pages/Medicals/Medicals';
import NewsItem from './components/NewsItem/NewsItem';
import Layout from './utils/Layout';



function App() {
  return (
    <div className="main-app">
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about/overview" element={<Overview />} />
            <Route path="/about/message-from-head" element={<LeadersMessage />} />
            <Route path="/about/departments" element={<Departments />} />
            <Route path="/about/faq" element={<FAQ />} />
            <Route path="/about/careers" element={<Careers />} />
            <Route path="/admission" element={<AdmissionPage />} />
            <Route path="/admission/apply" element={<AdmissionToken />} />
            <Route path="/admission/:token/application" element={<AdmissionForm />} />
            <Route path="/admission/:token/medicals" element={<Medicals />} />
            <Route path="/news-events/news" element={<NewsPage />} />
            <Route path="/news-events/news/:slug" element={<NewsItem />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/news-events/calendar" element={<Calendar />} />
            <Route path="/login" element={<LoginPage />} />
            {/* PRIVATE ROUTES */}
            <Route path="/*" element={<PrivateRoutes  component={Layout} />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={6608}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
      
    </div>
  );
}

export default App;
