import React, { useState, useEffect } from "react";
import "./Carousel.scss";
import Image1 from "../../assets/carousel/image1.jpg";
import Image2 from "../../assets/carousel/image2.jpg";
import Image3 from "../../assets/carousel/image6.jpg";
import Image4 from "../../assets/carousel/image5.jpg";
import GradBan from "../../assets/carousel/gradbanner.jpeg"
import SummerCamp from "../../assets/carousel/summercamp.jpeg"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Carousel = () => {
  const [slideIndex, setSlideIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 3);
    } else {
      setSlideIndex(slideIndex < 3 ? slideIndex + 1 : 0);
    }
  };
  const slides = [
    {
      url: GradBan,
      subtitle: "",
      title: "",
    },
    {
      url: SummerCamp,
      subtitle: "2024 Anell",
      title: "Summer Camp",
    },
    {
      url: Image1,
      subtitle: "Quality Education For",
      title: "Children’s Success",
    },
    {
      url: Image2,
      subtitle: "Better Learning Environment",
      title: "Unique Play Area",
    },
    {
      url: Image3,
      subtitle: "Better Learning Environment",
      title: "Team Work",
    },
    {
      url: Image4,
      subtitle: "We Offer You A Variety Of ",
      title: "Pre-School Programs",
    },
  ];

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        setSlideIndex(slideIndex < 3 ? slideIndex + 1 : 0);
      }, 3000);
  });
  return (
    <div
      className="carousel-container"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <IoIosArrowBack
        style={{ left: "10px" }}
        onClick={() => handleClick("left")}
      />
      <div
        className="wrapper"
        style={{ transform: `translateX(${slideIndex * -100}vw)` }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className="slide"
            style={{
              backgroundImage: `url(${slide.url}), linear-gradient(to right,rgba(32, 26, 1,.4) 65%,rgba(0, 0, 0, .1) 100%)`,
            }}
          >
            <div className="carousel-text">
              <h3>{slide.subtitle}</h3>
              <h1>{slide.title}</h1>
              <Link to="/about/overview" className="classic-button">Read More</Link>
            </div>
          </div>
        ))}
      </div>
      <IoIosArrowForward
        style={{ right: "10px" }}
        onClick={() => handleClick("right")}
      />
    </div>
  );
};

export default Carousel;
