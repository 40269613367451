import React, { useState } from "react";
import "./DepartmentContent.scss";
import SectionHeader from "../../SectionHeader/SectionHeader";

const DepartmentsContent = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div id="department-content" className="main-container">
      <SectionHeader title="Departments" />
      <p>
        Anell School of Excellence is a well-organized institution with several
        key departments, each playing a crucial role in its operation:
      </p>
      <div className="departments-details">
        <div className="departments-tabs">
          <div
            onClick={() => setActiveTab(1)}
            className={`${
              activeTab === 1
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Administration
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className={`${
              activeTab === 2
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Academic
          </div>
          <div
            onClick={() => setActiveTab(3)}
            className={`${
              activeTab === 3
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Security
          </div>
          <div
            onClick={() => setActiveTab(4)}
            className={`${
              activeTab === 4
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Kitchen
          </div>
          <div
            onClick={() => setActiveTab(5)}
            className={`${
              activeTab === 5
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Sanitation
          </div>
          <div
            onClick={() => setActiveTab(6)}
            className={`${
              activeTab === 6
                ? "department-tab active-department-tab"
                : "department-tab"
            }`}
          >
            Information Technology (IT)
          </div>
        </div>
        <div className="department-tab-information">
          <div className="department-tab-details">
            {activeTab === 1 && (
              <ul>
                <li>
                  The Administration Department is the backbone, managing daily
                  tasks like admissions, student records, scheduling, and
                  budgeting. It's the main contact for inquiries and supports
                  parents, students, and staff.
                </li>
                <li>
                  It manages administrative tasks such as admissions, student
                  records, scheduling, and budgeting.
                </li>
                <li>
                  It often serves as the primary point of contact for parents,
                  students, and staff for general inquiries and support.
                </li>
              </ul>
            )}
            {activeTab === 2 && (
              <ul>
                <li>
                  The Academic Department is responsible for the core
                  educational functions of the school.
                </li>
                <li>
                  It designs and implements the curriculum, sets academic
                  standards, and oversees teaching and learning activities.
                </li>
                <li>
                  This department works closely with teachers and staff to
                  ensure that students receive a highquality education.
                </li>
              </ul>
            )}
            {activeTab === 3 && (
              <ul>
                <li>
                  The Security Department focuses on the safety and security of
                  students, staff, and the school premises.
                </li>
                <li>
                  It may include security personnel who monitor access to the
                  school, handle emergencies, and implement safety protocols.
                </li>
                <li>
                  This department plays a critical role in creating a secure
                  learning environment.
                </li>
              </ul>
            )}
            {activeTab === 4 && (
              <ul>
                <li>
                  The Kitchen Department manages the school's food services.
                </li>
                <li>
                  It plans and prepares nutritious meals for students and staff.
                </li>
                <li>
                  Ensuring food safety, hygiene, and dietary considerations are
                  important aspects of this department's responsibilities.
                </li>
              </ul>
            )}
            {activeTab === 5 && (
              <ul>
                <li>
                  The Sanitation Department ensures school cleanliness.
                  Custodial staff clean classrooms, restrooms, and common areas,
                  handling waste disposal for a healthy environment. Cleanliness
                  is vital for everyone's well-being.
                </li>
                <li>
                  It includes custodial staff who clean classrooms, restrooms,
                  common areas, and ensure proper waste disposal.
                </li>
                <li>
                  A clean and sanitary environment is essential for the health
                  and well-being of everyone at the school.
                </li>
              </ul>
            )}
            {activeTab === 6 && (
              <ul>
                <li>
                  The IT Department manages the school's technology
                  infrastructure and support services.
                </li>
                <li>
                  It maintains computer systems, networks, and software used for
                  administrative and educational purposes.
                </li>
                <li>
                  This department also provides technical assistance to staff
                  and students, ensuring that technology enhances the learning
                  experience.
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      <p>
        Each of these departments plays a unique role in the overall functioning
        of Anell School of Excellence, contributing to the school's success in
        providing a safe, nurturing, and effective educational environment.
      </p>
    </div>
  );
};

export default DepartmentsContent;
