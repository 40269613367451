import React from "react";
import './BannerBar.scss'

const BannerBar = () => {
  return (
    <div className="banner-bar">
      <div className="banner-bar-text">
        <div className="motto-text">Our Motto</div>
        <div className="motto">“ EXCEL AND LEAD ”</div>
      </div>
    </div>
  );
};

export default BannerBar;
