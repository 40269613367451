import React, { useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import "./WebsiteNews.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loading from "../../../components/Loading/Loading";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const AddNews = () => {
  const navigations = ["Dashboard", "News", "Details"];
  const [newsData, setNewsData] = useState(null);
  const [newsImages, setNewsImages] = useState([]);
  const [addingNews, setAddingNews] = useState(false);

  const handleFormChange = (e) => {
    setNewsData({
      ...newsData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!newsData?.details) {
      return toast.error("News Details is required");
    }
    try {
      setAddingNews(true);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

      const formData = new FormData();

      Object.keys(newsData).forEach((key) => {
        formData.append(key, newsData[key]);
      });

      for (let i = 0; i <= newsImages.length; i++) {
        formData.append(`images`, newsImages[i]);
      }

      await axios.post(`${api_url}news/`, formData, config).then((response) => {
        console.log(response);
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "News Added Successfully",
        }).then(() => {
          setAddingNews(false);
          window.location.href = "/admin/website-components/news"
        });
      });
    } catch (error) {
      console.log(error);
      setAddingNews(false);
    }
  };

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add News</h3>
        </div>
        <form onSubmit={(e) => handleFormSubmit(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">News Title</label>
              <input
                type="text"
                name="title"
                onChange={(e) => handleFormChange(e)}
                className="custom-form-control"
                required
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">News Thumbnail</label>
              <input
                type="file"
                id="upload-button"
                name="thumbnail"
                accept=".jpeg, .jpg, .png"
                required
                onChange={(e) =>
                  setNewsData({
                    ...newsData,
                    [e.target.name]: e.target.files[0],
                  })
                }
                className="custom-form-control display-none"
              />
              <label
                className="secondary-button w-max-content"
                htmlFor="upload-button"
              >
                Upload File
              </label>
              <small>
                {newsData?.thumbnail
                  ? newsData?.thumbnail.name
                  : "No file uploaded"}
              </small>
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">News Details</label>
              <CKEditor
                required
                editor={ClassicEditor}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                  ],
                }}
                data={newsData?.details}
                onChange={(event, editor) => {
                  const text = editor.getData();
                  setNewsData({
                    ...newsData,
                    details: text,
                  });
                }}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">News Date</label>
              <input
                type="date"
                name="date"
                onChange={(e) => handleFormChange(e)}
                className="custom-form-control"
                required
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">News Images</label>
              <input
                type="file"
                accept="image/.jpeg, .png, .jpg, .webp"
                name="images"
                multiple
                id="news-images-button"
                className="display-none"
                onChange={(e) => setNewsImages(e.target.files)}
              />
              <label
                className="w-max-content secondary-button"
                htmlFor="news-images-button"
              >
                Upload Images
              </label>
              <small>
                {newsImages.length > 0
                  ? `${newsImages.length} files selected`
                  : "No file selected"}
              </small>
            </div>
          </div>
          <div className="form-action-buttons">
            {/* <div
            className="primary-button-outline w-max-content"
            >
            Preview
            </div> */}
            {addingNews ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNews;
