import axios from "axios";
import React, { useEffect, useState } from "react";
import { api_url } from "../../../url";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { RotatingLines } from "react-loader-spinner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const AddMultipleStudent = () => {
  const navigations = ["Dashboard", "User Management", "Add Multiple Students"];
  const [studentList, setStudentList] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [classes, setClasses] = useState([]);
  const [creatingStudents, setCreatingStudents] = useState(false);

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const createStudents = async (e) => {
    e.preventDefault();
    if (studentList === null) {
      return toast.error("Please upload the file");
    }
    if (selectedClass === null) {
      return toast.error("Please select a class");
    }
    setCreatingStudents(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const formData = new FormData();

    formData.append("student_list", studentList);
    formData.append("school_class", selectedClass);

    await axios
      .post(`${api_url}accounts/multiple-students/`, formData, config)
      .then((response) => {
        
        if (!response?.data?.success) {
          toast.error("Error with row " + response?.data?.row);
          if (response.data?.response_data) {
            for (const property in response.data.response_data) {
              if (property === "0") {
                toast.error(
                  `For row ${response.data.row} ${response.data.response_data[property]}`
                );
              } else {
                toast.error(
                  `For row ${response.data.row}, ${property} error: ${response.data.response_data[property]}`
                );
              }
            }
          }
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Students added successfully",
          }).then(() => {
            window.location.reload();
          });
        }
        setCreatingStudents(false);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response?.data);
        setCreatingStudents(false);
      });
  };

  useEffect(() => {
    getClasses();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add Multiple Students</h3>
        </div>
        <br />
        <p>
          You are about to add multiple students for a class. Follow the
          following steps to successfully upload these students.
        </p>
        <ol>
          <li>
           <Link to="https://anellapi.rxultimate.com/media/StudentFormat.xlsx">Click here</Link> to download the excel format for uploading the students
          </li>
          <li>
            Fill in the empty fields in the excel format. Making sure not to
            delete the first row or change the order
          </li>
          <li>
            For all dates (date_of_birth) should be in the mm/dd/yyyy format
          </li>
          <li>Select the class you want to upload the students to</li>
          <li>Upload the populated excel file and submit</li>
        </ol>
        <form onSubmit={(e) => createStudents(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Student Class</label>
              <select
                onChange={(e) => setSelectedClass(e.target.value)}
                name="student_class"
                id=""
                className="custom-form-control"
              >
                <option value="">Select Class</option>
                {classes?.map((student_class, index) => (
                  <option value={student_class?.id} key={index}>
                    {student_class?.class_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Student List</label>
              <input
                onChange={(e) => setStudentList(e.target.files[0])}
                className="custom-form-control"
                type="file"
                name=""
                id=""
              />
            </div>
          </div>
          <div className="form-action-buttons">
            {creatingStudents ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Upload
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddMultipleStudent;
