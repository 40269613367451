import React from 'react'
import './Loading.scss'
import AnellLogo from "../../assets/Anell.png"

const Loading = () => {
  return (
    <div id='loading'>
        <div className="box">
            <div className="content">
                <img src={AnellLogo} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Loading