import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import "./WebsiteNews.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Loading from "../../../components/Loading/Loading";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const WebsiteNewsDetails = () => {
  const { newsId } = useParams();
  const navigations = ["Dashboard", "News", "Details"];
  const [newsDetails, setNewsDetails] = useState(null);
  const [updateNewsData, setUpdateNewsData] = useState({});
  const [newsImages, setNewsImages] = useState([]);
  const [updatingNewsData, setUpdatingNewsData] = useState(false);

  const handleFormChange = (e) => {
    setUpdateNewsData({
      ...updateNewsData,
      [e.target.name]: e.target.value,
    });
  };

  const updateNews = async (e) => {
    e.preventDefault();
    setUpdatingNewsData(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const formData = new FormData();

    Object.keys(updateNewsData).forEach((key) => {
      formData.append(key, updateNewsData[key]);
    });


    if (newsImages.length > 0) {
      for (let i = 0; i <= newsImages.length; i++) {
        formData.append(`images`, newsImages[i]);
      }
    }

    await axios
      .put(`${api_url}news/${newsId}/`, formData, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "News updated successfully",
        }).then(() => {
            getNewsDetails(newsId);
            setUpdatingNewsData(false);
          })
          .catch((error) => {
            console.error(error);
            setUpdatingNewsData(true);
          });
      });
  };

  const getNewsDetails = async (newsId) => {
    setNewsDetails(null);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .get(`${api_url}news/${newsId}/`, config)
      .then((response) => {
        console.log(response.data);

        setNewsDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteImage = async (image_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .delete(`${api_url}news/news-images/${image_id}/`, config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Deleted",
          text: "Image deleted successfully",
        })
          .then(() => {
            getNewsDetails(newsId);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };

  useEffect(() => {
    getNewsDetails(newsId);
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">News Details</h3>
        </div>

        {!newsDetails ? (
          <Loading />
        ) : (
          <form onSubmit={(e)=>updateNews(e)} className="custom-form">
            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">News Title</label>
                <input
                  type="text"
                  value={
                    updateNewsData?.title
                      ? updateNewsData?.title
                      : newsDetails?.title
                  }
                  className="custom-form-control"
                  name="title"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="custom-form-col">
                <label htmlFor="">News Thumbnail</label>
                <input
                  type="file"
                  id="upload-button"
                  className="custom-form-control display-none"
                  name="thumbnail"
                  onChange={(e) =>
                    setUpdateNewsData({
                      ...updateNewsData,
                      [e.target.name]: e.target.files[0],
                    })
                  }
                />
                <label
                  className="primary-button w-max-content"
                  htmlFor="upload-button"
                >
                  Upload File
                </label>
                <small>
                  {updateNewsData?.thumbnail ? (
                    updateNewsData?.thumbnail.name
                  ) : (
                    <Link to={newsDetails?.thumbnail}>
                      {newsDetails?.thumbnail}
                    </Link>
                  )}
                </small>
              </div>
            </div>

            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">News Details</label>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "blockQuote",
                    ],
                  }}
                  data={
                    updateNewsData?.details
                      ? updateNewsData?.details
                      : newsDetails?.details
                  }
                  onChange={(event, editor) => {
                    const text = editor.getData();
                    setUpdateNewsData({
                      ...updateNewsData,
                      details: text,
                    });
                  }}
                />
              </div>
            </div>

            <div className="custom-form-row">
              <div className="custom-form-col">
                <label htmlFor="">News Date</label>
                <input
                  type="date"
                  name="date"
                  value={
                    updateNewsData?.date
                      ? updateNewsData?.date
                      : newsDetails?.date
                  }
                  className="custom-form-control"
                  required
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="custom-form-col">
                <label htmlFor="">News Images</label>
                <input
                  type="file"
                  accept="image/.jpeg, .png, .jpg, .webp"
                  name="images"
                  multiple
                  id="news-images-button"
                  className="display-none"
                  onChange={(e) => setNewsImages(e.target.files)}
                />
                <label
                  className="w-max-content secondary-button"
                  htmlFor="news-images-button"
                >
                  Upload Images
                </label>
                <small>{newsImages.length} images</small>
              </div>
            </div>

            <div className="news-details-images">
              {newsDetails?.images?.map((image, index) => (
                <>
                  <div className="news-detail-image" key={index}>
                    <img src={`http://localhost:8000${image.image}`} alt="" />
                    <div className="news-detail-image-overlay">
                      <Link
                        to={`http://localhost:8000${image.image}`}
                        target="_blank"
                        className="btn white-outline-btn"
                      >
                        View Image
                      </Link>
                      <div
                        onClick={() => deleteImage(image.id)}
                        className="btn red-outline-btn"
                      >
                        Delete Image
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>

            <div className="form-action-buttons">
              {/* <div className="primary-button-outline w-max-content">
                        Preview
                    </div> */}
              {updatingNewsData ? (
                <div style={{ textAlign: "center" }}>
                  <RotatingLines
                    strokeColor="#021D53"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="26"
                    visible={true}
                  />
                </div>
              ) : (
                <button type="submit" className="primary-button w-max-content">Save</button>
              )}
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default WebsiteNewsDetails;
