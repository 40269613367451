import React, { useEffect, useState } from "react";
import "./Calendar.scss";
import Banner from "../../components/Banner/Banner";
import Navbar from "../../components/Navbar/Navbar";
import TopBar from "../../components/TopBar/TopBar";
import CalendarImage from "../../assets/calendar.png";
import Footer from "../../components/Footer/Footer";
import CalendarAccordion from "../../components/CalendarAccordion/CalendarAccordion";
import { Link } from "react-router-dom";
import { BsWhatsapp } from "react-icons/bs";
import Loading from "../../components/Loading/Loading";
import axios from "axios";
import { api_url } from "../../url";
import NoData from "../../components/PortalComponents/NoData/NoData";

const Calendar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadingData, setLoadingData]=useState(true)
  const [calendarData, setCalendarData] = useState(null);

  const getYearActivities = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    await axios
      .get(
        `${api_url}academics/active-academic-year/activities`,
        config
      )
      .then((response) => {
        console.log(response.data)
        setCalendarData(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingData(false);
      });
  };

  useEffect(()=>{
    getYearActivities()
  },[])

  const calendarEvents = [
    {
      title: "TERM 2 Tuesday, 9th January 2024 - Thursday, 28th March 2024",
      activities: [
        {
          names: [
            "Reopening for term 2 & Students workshop",
          ],
          date: "Tue - 9th Jan. 2024 ",
          important: true,
        },
        {
            names: [
              "Term’s lessons begins"
            ],
            date: "Wed- 10th Jan. 2024",
            important: false,
        },
        {
            names: [
              "Ghana’s Chocolate Day (Anell Goes Red)"
            ],
            date: "Wed- 14th Feb. 2024 ",
            important: false,
        },
        {
            names: [
              "Half Term Assessment "
            ],
            date: "Thur. – 15th Feb. 2024",
            important: false,
        },
        {
          names: [
            "Cultural Diversity Day (Celebration of Ghana’s 67th Independence Day)"
          ],
          date: "Tue. – 5th Mar. 2024",
          important: false,
      },
        {
            names: [
              "Public Holiday (For Students)",
            ],
            date: "Wed. – 6th Mar. 2024",
            important: true
        },
        
        {
            names: [
              "Half Term Holidays (No School)"
            ],
            date: "Thur. 7th Mar. – 8th Mar. 2024",
            important: true,
        },
        {
            names: [
              "Parent – Facilitator Conference (PFC) & Book Fair"
            ],
            date: "Mon. –11th Mar. 2024 ",
            important: false,
        },
        {
            names: [
              "Book Character Day"
            ],
            date: "Tue. 12th Mar. 2024",
            important: false,
        },
        {
            names: [
              "Anell Arts & Exhibition"
            ],
            date: "Wed – 13th – 15th Mar. 2024 ",
            important: false,
        },
        {
            names: [
              "End of Term Assessment Begins."
            ],
            date: "Mon – 18th – 22nd Mar. 2024",
            important: false,
        },
        {
            names: [
              "World Oral Day"
            ],
            date: "Wed. 20th Mar. 2024"
        },
        {
            names: [
              "Last day of the term(Vacation Day)"
            ],
            date: "Thur. – 28th Mar. 2024",
            important: true,
        },
      ],
      additional_info: "TERM 3 RESUMES ON MON. 15th APRIL, 2024"
    },
    {
      title: "TERM 3 Monday, 15th April 2024 - Friday, 12th July 2024",
      activities: [
        {
          names: [
            "Reopening for term 2 & Students workshop",
          ],
          date: "Mon. 15th April, 2024",
          important: true,
        },
        {
            names: [
              "Term’s lessons begins"
            ],
            date: "Tue. 16th April, 2024 ",
            important: false,
        },
        {
            names: [
              "Worker’s Day (Public Holiday)"
            ],
            date: "Wed. 1st May, 2024 ",
            important: false,
        },
        {
            names: [
              "Eid – Ul – Fitr  "
            ],
            date: "Thur. – 15th Feb. 2024",
            important: true,
        },
        {
          names: [
            "Half Term Assessment "
          ],
          date: "Mon. 20th May. – 27th May, 2024",
          important: false,
      },
        {
            names: [
              "Half Term Holidays (No School)",
            ],
            date: "Thur. 30th May. – 31st May. 2024",
            important: true
        },
        
        {
            names: [
              "End of Term Assessment Begins."
            ],
            date: "Mon –June 24th – 3rd July 2024 ",
            important: false,
        },
        {
            names: [
              "Anell Fun Fair"
            ],
            date: "Fri. 5th July, 2024",
            important: false,
        },
        {
            names: [
              "Anell Charity Drive (In commemoration of the school’s 3rd Anniversary Celebration)"
            ],
            date: "Tue. 9th July, 2024",
            important: true,
        },
        {
            names: [
              "Graduation Ceremony & Vacation Day"
            ],
            date: "Fri. 12th July, 2024",
            important: true,
        },
        
      ],
      additional_info: "TERM 1 OF 2024/2025 ACADEMIC YEAR RESUMES ON MON. 2nd SEPTEMBER 2024"
    }
  ];
  return (
    <div>
      <TopBar />
      <Navbar />
      <Banner title="Calendar" image={CalendarImage} underline="true" />
      {
        loadingData ? <Loading /> : (
        <div className="calendar-section main-container">
          {
            calendarData && (
              <div className="calendar-header">{calendarData?.academic_year} Academic Year</div>
            )
          }
          <div className="calendar-accordions">
            {calendarData?.calendar_activities?.map((calendarEvent, index) => (
              <CalendarAccordion
                calendarEvent={calendarEvent}
                key={index}
                isActiveSection={index === activeIndex}
                setActiveIndex={setActiveIndex}
                sectionIndex={index}
              />
            ))}
          </div>
        </div>
        )
      }
      {
        !calendarData && !loadingData && <NoData />
      }
      <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
      <Footer />
    </div>
  );
};

export default Calendar;
