import React, { useState, useRef } from 'react'
import {NavLink} from 'react-router-dom'
import {MdOutlineKeyboardArrowDown} from 'react-icons/md'
import { useEffect } from 'react'

const NavItem = ({item}) => {
    const [openSubmenu, setOpenSubmenu] = useState(false)
    const toggleSubmenu = () => {
        setOpenSubmenu(!openSubmenu)
    }
    
    const menuRef = useRef()

    useEffect(()=>{

        let handler = (e) => {
            if(menuRef !== null){
                if(!menuRef.current.contains(e.target)){
                    setOpenSubmenu(false)
                }
            }
        }
        document.addEventListener("mousedown", handler)

        return()=>{
            document.removeEventListener("mousedown", handler)
        }
    })
  return (
    <li className='menu-item' ref={menuRef}>
        {
            item.submenus ? (
                <div onClick={toggleSubmenu} className="nav-link-item">
                    {item.name}
                    <MdOutlineKeyboardArrowDown onClick={toggleSubmenu} />
                </div>
            ):(
                <NavLink to={item.path} className="nav-link-item">
                    {item.name}
                    {
                        item.submenus && (
                            <MdOutlineKeyboardArrowDown onClick={toggleSubmenu} />
                        )
                    }
                </NavLink>
            )
        }
        
        {
            item.submenus && item.submenus.length > 0 && (
                <ul className={`${openSubmenu ? " submenu open-submenu" : "submenu close-submenu"}`}>
                    {
                        item.submenus.map((submenu, index) => (
                            <li className='submenu-item' key={index}>
                                <NavLink to={submenu.path} className="sub-nav-link-item">

                                    {submenu.name}
                                </NavLink>
                            </li>
                        ))
                    }
                </ul>
            )
        }
        <div className="overlay"></div>
    </li>
  )
}

export default NavItem