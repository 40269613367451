import axios from "axios";
import React, { useEffect, useState } from "react";
import { api_url } from "../../../url";
import { toast } from "react-toastify";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../components/Loading/Loading";
import { RotatingLines } from "react-loader-spinner";
import { LuDownload } from "react-icons/lu";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const StudentLibrary = () => {
  const [loadingDocuments, setLoadingDocuments] = useState(true);
  const [documents, setDocuments] = useState([]);

  const navigations = ["Dashboard", "Anell Library"];

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = documents.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(documents.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % documents.length;
    setItemOffset(newOffset);
  };

  const getLibraryDocuments = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}library/student/library/`, config)
      .then((response) => {
        setDocuments(response.data);
        setLoadingDocuments(false);
      })
      .catch((error) => {
        toast.error("Failed to get uploads");
        setLoadingDocuments(false);
      });
  };

  useEffect(()=>{
    getLibraryDocuments();
  },[]);

  return (
    <div>
      

      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Student Library</div>
          
          {loadingDocuments ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Document Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {documents &&
                      currentItems?.map((upload, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{upload?.school_class}</td>
                          <td>{upload?.subject}</td>
                          <td>{upload?.document_name}</td>
                          
                            <td style={{ cursor: "pointer" }}>
                              <Link
                                target="_blank"
                                download
                                to={`${api_url}${upload?.document}`}
                                title="Download Document"
                              >
                                <LuDownload />
                              </Link>
                              
                            </td>
                          
                        </tr>
                      ))}
                  </tbody>
                </table>
                {documents.length <= 0 && <NoData />}
              </>
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentLibrary;
