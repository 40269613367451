import React, { useEffect, useState } from "react";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import Loading from "../../../components/Loading/Loading";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import { LuDownload } from "react-icons/lu";
import axios from "axios";
import { api_url } from "../../../url";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";

const FileManager = () => {
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [uploadingDocument, setUploadingDocument] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [document, setDocument] = useState(null);
  const [deletingFile, setDeletingFile] = useState(false);
  const navigations = ["Dashboard", "File Manager"];

  const itemsPerPage = 30;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = fileList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(fileList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % fileList.length;
    setItemOffset(newOffset);
  };

  const getUserFiles = async () => {
    setLoadingFiles(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}filemanager/user-files/`, config)
      .then((response) => {
        if (response?.data?.success) {
          setFiles(response.data?.payload);
          setFileList(response.data?.payload);
        } else {
          toast.error("Couldn't get files");
        }
        setLoadingFiles(false);
      })
      .catch((error) => {
        toast.error("An error occurred.");
        setLoadingFiles(false);
      });
  };


  const uploadUserFile = async (e) => {
    e.preventDefault();
    if (!document) {
      return toast.error("Please upload a file");
    }
    if (documentName === "") {
      return toast.error("Please enter a document name");
    }
    setUploadingDocument(true);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const formData = new FormData();

    formData.append("file_name", documentName);
    formData.append("file", document);

    await axios
      .post(`${api_url}filemanager/user-files/`, formData, config)
      .then((response) => {
        if (response?.data?.success) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Document uploaded Successfully",
          }).then(() => {
            getUserFiles();
            setUploadingDocument(false);
          });
        } else {
          if (response?.data?.errors) {
            for (const property in response.data?.errors) {
              if (property === "0") {
                toast.error(`${response.data.errors[property]}`);
              } else {
                toast.error(
                  `${property} error: ${response.data.errors[property]}`
                );
              }
            }
          }
        }
        setUploadingDocument(false);
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again");
        setUploadingDocument(false);
      });
  };

  const deleteFile = (file_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingFile(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}filemanager/${file_id}/details`, config)
          .then((response) => {
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "File Deleted Successfully",
            }).then(() => {
              setDeletingFile(false);
              getUserFiles();
            });
          })
          .catch((error) => {
            setDeletingFile(false);

            toast.error(
              "There was an error deleting the file, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getUserFiles();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {loadingFiles ? (
        <Loading />
      ) : (
        <div id="admin-dashboard" className="portal-page">
          <div className="admin-dashboard-title">
            <DashboardNavigation navigations={navigations} />
          </div>
          <div className="two-columns">
            <div className="section-list" style={{ flex: 3 }}>
              <div className="table-section-header">Uploaded Files</div>
              <div className="section-list-table">
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>File Name</th>
                        <th>Uploaded By</th>
                        <th>Date Uploaded</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files &&
                        currentItems?.map((file, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{file?.file_name}</td>
                            <td>{file?.user}</td>
                            <td>{file?.uploaded_at}</td>

                            <td
                              style={{ cursor: "pointer" }}
                              className="action-buttons"
                            >
                              {deletingFile ? (
                                <div>
                                  <RotatingLines
                                    strokeColor="#021D53"
                                    strokeWidth="5"
                                    animationDuration="0.75"
                                    width="26"
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                <div className="action-buttons">
                                  <Link
                                    target="_blank"
                                    download
                                    to={`${api_url}${file?.file}`}
                                    title="Download Document"
                                    className="view-button"
                                  >
                                    <LuDownload />
                                  </Link>
                                  <div
                                    onClick={() => deleteFile(file.id)}
                                    className="delete-button"
                                  >
                                    <AiOutlineDelete />
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {files.length <= 0 && <NoData />}
                </>
                <div className="pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=""
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                    containerClassName="pagination-container"
                    activeClassName="active-page"
                    pageLinkClassName="page-link"
                  />
                </div>
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <h3>Upload New file</h3>
              <form onSubmit={(e) => uploadUserFile(e)} className="custom-form">
                <div className="custom-form-row">
                  <div className="custom-form-col">
                    <label htmlFor="">Document Name*</label>
                    <input
                      onChange={(e) => setDocumentName(e.target.value)}
                      className="custom-form-control"
                      required
                      name="document_name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="custom-form-row">
                  <div className="custom-form-col">
                    <label htmlFor="">Document*</label>
                    <input
                      onChange={(e) => setDocument(e.target.files[0])}
                      className="custom-form-control"
                      required
                      name="document"
                      type="file"
                    />
                  </div>
                </div>

                {uploadingDocument ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <RotatingLines
                      strokeColor="#021D53"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="26"
                      visible={true}
                    />
                  </div>
                ) : (
                  <input
                    type="submit"
                    className="primary-button w-max-content"
                    value="Upload Document"
                    style={{ marginTop: "10px" }}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileManager;
