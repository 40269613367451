import React, { useState } from "react";
import "./WhyAnell.scss";
import Logo from "../../assets/Anell.png";
import HolidayVillage from "../../assets/icons/holiday-village.png";
import TeacherBoard from "../../assets/icons/teacher.png";
import People from "../../assets/icons/people.png";
import Grad from "../../assets/icons/grad.png";
import ReactPlayer from "react-player/lazy";
import { AiFillPlayCircle } from "react-icons/ai";

const WhyAnell = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <div className="why-anell">
      <div className="why-anell-header">
        <h1>Why Choose Anell ?</h1>
        <div className="underline"></div>
      </div>
      <div className="anell-information">
        <div className="anell-information-video">
          <ReactPlayer
            controls
            light="../../assets/image11.png"
            // playIcon={<AiFillPlayCircle />}
            url="https://www.youtube.com/watch?v=L411vqwMorM"
            width="100%"
            height="100%"
            playing
            // onReady={() => console.log("Ready")}
          />
        </div>
        <div className="anell-information-details">
          <div className="tabs">
            <div
              onClick={() => setActiveTab(1)}
              className={`${activeTab === 1 ? "tab active" : "tab"}`}
            >
              Our Program
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={`${activeTab === 2 ? "tab active" : "tab"}`}
            >
              Our Curriculum
            </div>
            <div
              onClick={() => setActiveTab(3)}
              className={`${activeTab === 3 ? "tab active" : "tab"}`}
            >
              General Care
            </div>
            <div
              onClick={() => setActiveTab(4)}
              className={`${activeTab === 4 ? "tab active" : "tab"}`}
            >
              Learner’s Health
            </div>
            <div
              onClick={() => setActiveTab(5)}
              className={`${activeTab === 5 ? "tab active" : "tab"}`}
            >
              Security
            </div>
          </div>
          <div className="tab-information">
            <div className="background-image">
              <img src={Logo} alt="" />
            </div>
            {activeTab === 1 && (
              <div className="tab-details">
                <p>
                  It is the philosophy of Anell School of Excellence that,
                  children are encouraged to learn and grow by providing a
                  foundation of developmentally appropriate experiences which
                  equips them for future challenges. Our program is an
                  integration of academic and non-academic but social and
                  personal development. Our non-academic activities are part of
                  our objectives to train children holistically by giving them
                  the opportunity to discover their talents. Anell School offers
                  a vast array of activities including:
                </p>
                <ul>
                  <li>Swimming lessons</li>
                  <li>Computer Programming</li>
                  <li>Abacus lessons</li>
                  <li>Ballet</li>
                  <li>Connect and build</li>
                  <li>Karate</li>
                </ul>
                <p>
                  Our pedagogy follows best practices as a priority with a
                  practical approach to teaching and learning, and a focus on
                  encouraging critical thinking and problem-solving.
                </p>
              </div>
            )}
            {activeTab === 2 && (
              <div className="tab-details">
                <p>
                  Anell School employs an integrated system for its preschool
                  level, which aligns with the Early Years Foundation Stage
                  Framework, for Nursery and Reception levels. This framework
                  provides a structured approach to early childhood education.
                  At Anell School of Excellence, education is not merely an
                  endeavor to impart knowledge; it's a journey of holistic
                  development.
                 </p> 
                  <p> Our grade school curriculum stands as a testament
                  to this philosophy, where innovation meets integration to
                  foster well-rounded individuals. We are proud to present an
                  educational approach that harmoniously blends the esteemed
                  Cambridge Curriculum with the locally relevant Ghana Education
                  Service (GES) curriculum, striking a balance that prepares our
                  students for a globalized world while staying rooted in their
                  cultural context.</p>
               <p> This is our commitment to nurturing not just
                  academic excellence but also the development of character,
                  skills, and talents, setting our students on a path toward a
                  future of limitless possibilities
                </p>
              </div>
            )}
            {activeTab === 3 && (
              <div className="tab-details">
                <p>
                  We work closely with parents to ensure a seamless transition from home. 
                  Our teachers are experienced in training techniques and follow a
                  consistent approach based on the child's readiness. We
                  collaborate with parents to maintain continuity between school
                  and home routines.
                </p>
                <p>
                  We provide balanced and nutritious meals for the children,
                  taking into consideration any dietary restrictions or
                  allergies. Our menus are designed to include a variety of
                  foods that promote healthy growth and development. We
                  prioritize fresh ingredients and work to create a positive
                  mealtime experience for the children.
                </p>
                <p>
                  We implement a strict cleaning schedule for all areas of the
                  preschool, including classrooms, play areas, restrooms, and
                  common spaces. Regularly clean and disinfect surfaces, toys,
                  and materials that children come in contact with. Use
                  child-safe, non-toxic cleaning products to avoid any harm. We
                  also maintain clean and well-equipped restrooms. The
                  facilitators take students through proper hygienic and
                  hand-washing practices, with designated diaper changing areas
                  with appropriate supplies and sanitization procedures. 
                </p>
              </div>
            )}
            {activeTab === 4 && (
              <div className="tab-details">
                <p>
                  We have a planned well-balanced menu that provide a variety of
                  nutrients and flavors and rotate menus on a regular basis to
                  offer diverse meal options and prevent monotony. At Anell we
                  respect cultural and dietary preferences of families and offer
                  alternatives when necessary and communicate openly with
                  parents about menu choices. Furthermore, the management of the
                  school identify and address any food allergies or
                  sensitivities among the children and keep a record of
                  allergies and ensure that allergen-containing foods are
                  clearly labeled.
                </p>
                <p>
                  The school has developed a comprehensive medical emergency
                  plan in collaboration with a special hospital. This plan
                  outlines the procedures to be followed during various medical
                  situations, including contacting the hospital, transporting
                  the child, and notifying parents. In case of any medical
                  incident, we communicate promptly and transparently with
                  parents and inform them about the situation, the actions
                  taken, and the child's well-being.
                </p>
                <p>
                  The school conducts periodic screening exercise in a
                  child-friendly and non-threatening environment for our
                  learners. We do this by collaborating with healthcare
                  professionals and specialists to administer the assessments
                  effectively and ensure that each child receives individualized
                  attention during the screening process and have respect for
                  the child's comfort level and provide support as needed.
                  Regularly monitor the progress of children who underwent
                  screenings and interventions and schedule follow-up
                  assessments to track improvements and adjust intervention
                  strategies if necessary.
                </p>
              </div>
            )}
            {activeTab === 5 && (
              <div className="tab-details">
                <p>
                  Safety is our top priority. We have security measures,
                  supervision protocols, emergency procedures put in place to
                  ensure a secure environment for the children.
                </p>
                <p>
                  The school has a Management System where parents or authorized
                  guardians follow specific drop off and pick-up procedures.
                  This often includes signing out with their child's name, and
                  time. Only authorized individuals listed in the child's
                  records are allowed to pick up the child. The security
                  personnel are trained to verify the identity of anyone picking
                  up a child. This might involve checking photo IDs and
                  cross-referencing them with the authorized list.
                </p>
                <p>
                  CCTV and Monitoring; the school has closed-circuit television
                  (CCTV) systems in all the classrooms, within and outside the
                  school premises, allowing administrators to monitor different
                  areas of the school to ensure safety and security.
                </p>
                <p>
                  Visitors, including parents, are required to sign in and wear
                  identification badges while on the premises. This helps the
                  staff easily identify authorized individuals.
                </p>
                <p>
                  Outdoor play areas are inspected regularly for hazards like
                  sharp objects, uneven surfaces, or any potential dangers.
                  Equipment such as swings and slides are securely anchored and
                  in good condition.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="extra-curriculums">
        <div className="extra-curricular">
          <img
            src={HolidayVillage}
            alt=""
            style={{ height: "45.33px", width: "68px" }}
          />
          <h3>Holiday Support</h3>
          <p>
            We have your busy schedule at heart. For this reason, our facility
            is opened to you our cherished parents and the entire public on
            vacations and other selected holidays on a very moderate fee.
            Special lessons are given on phonics, mechanical writing, crafts,
            swimming, programming etc.
          </p>
        </div>

        <div className="extra-curricular">
          <img
            src={TeacherBoard}
            alt=""
            style={{ height: "42.5px", width: "55.25px" }}
          />
          <h3>Teacher Learner Ratio</h3>
          <p>
          We have very spacious classrooms with moderate learners in each class. Our crèche has a
fifteen (15) to three (3) attendants ratio. Our other preschool levels have 25 learners to 2
facilitators ratio and our grade school also has a maximum of 25 learners in a class.
          </p>
        </div>

        <div className="extra-curricular">
          <img
            src={People}
            alt=""
            style={{ height: "41.43px", width: "49.29px" }}
          />
          <h3>Our Professional Team</h3>
          <p>
            We pride ourselves with well trained teachers (facilitators) who are
            versed in the area of preschool and grade school tuition and care.
            The school has a culture of giving its teaching staff Continuous
            Professional Development (In-house training) periodically.
          </p>
        </div>

        <div className="extra-curricular">
          <img
            src={Grad}
            alt=""
            style={{ height: "41.43px", width: "49.29px" }}
          />
          <h3>Stimulating Learning Environment</h3>
          <p>
            We believe in the child-centred learning approach, therefore, our
            classrooms are fiitted with relevant learning resource and a modern
            outdoor facility. We have modern outdoor facilities.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyAnell;
