import React from 'react'
import Message from '../Message/Message'
import './MessageSection.scss'
import PrincipalImage from "../../../assets/aboutpage/LeadersMessage/principalImage.png"
import DirectorImage from "../../../assets/aboutpage/LeadersMessage/directorImage.jpg"
import AdministrationImage from "../../../assets/aboutpage/LeadersMessage/academicsHead.jpeg"

const MessageSection = () => {
    
    const leadersMessages = [
        {
            image:DirectorImage,
            name:"Ellen Antwiwaa Sarkodie (Mrs.)",
            position:"DIRECTOR",
            
        },
        {
            image:PrincipalImage,
            name:"Mr. Philips Yeboah Pimpong",
            position:"HEAD OF ACADEMICS"
        },
        
        {
            image:AdministrationImage,
            name:"Mr. Eric Opoku Asare",
            position:"HEAD OF ADMINISTRATION",
            
        },
        // {
        //     image:MessageImage,
        //     name:"Mr. Emmanuel Ampofo Twumasi",
        //     position:"GRADE SCHOOL",
        //     message:"Greetings to all from Ghana!"
        // },
    ]
  return (
    <div id='message-section'>
        
        <div className="messages">
            {
                leadersMessages.map((leaderMessage, index) => {
                    return <Message key={index} leaderMessage={leaderMessage}/>
                })
            }
            
        </div>
    </div>
  )
}

export default MessageSection