import React from "react";
import "./History.scss";

const History = () => {
  return (
    <div id="anell-history">
      <div className="history-header">
        <h1>Our History</h1>
        <div className="underline"></div>
      </div>
      <div className="history-content">
        <p className="history-text">
          Anell School of Excellence was founded on 12th July 2021 by Mr.
          Anthony Kwabena Badu and Ms. Ellen Antwiwaa Sarkodie to provide
          international education to students of all races and creeds, at a
          time. Ms. Ellen Antwiwaa Sarkodie had a passion for being privileged
          to be in a position of contributing towards the intellectual and
          social development of the kids in her community and the nation at
          large. It is with this passion that Mr. Anthony Kwabena Badu gladly
          supported the dream which eventually became a reality around the time
          when the COVID-19 pandemic was at its peak in the year 2020. The
          school building was then put up within a year and a few months. The
          school officially opened on the 12th day of July 2021 with 12 teaching
          and non-teaching staff and 12 learners which comprised 1 student in
          Foundation, 2 in Reception 1, 1 in Creche, and 8 in Daisies class
          respectively.
        </p>
        <div className="acronym">
          <p>The name ANELL is an acronym for;</p>
          <ul>
            <li>A - Academic</li>
            <li>N - Neatness</li>
            <li>E - Excellence</li>
            <li>L - Learn</li>
            <li>L - Leadership</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default History;
