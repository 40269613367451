import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { usePaystackPayment } from "react-paystack";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loading from "../../components/Loading/Loading";
import { api_url } from "../../url";

const Payment = ({ activeStep, setActiveStep,handleFormChange, data,setData, token }) => {
  const [updatingApplicationToken,setUpdatingApplicationToken] = useState(false)
  const publicKey = "pk_test_b280bd842436239d7059d65481bd50c960db4302";
  const [email, setEmail] = useState("")
  const config = {
    reference:`${token.token}`,
    email,
    amount: 20000,
    currency: "GHS",
    publicKey
  };
  const initializePayment = usePaystackPayment(config);

  const previousPage = () =>{
    setActiveStep(activeStep-1)
  }
  
  const makePayment = () => {
    if(email === ""){
      toast.error("Please enter a valid email address")
    }else{
      initializePayment(onSuccess, onClose);
    }
  }

  const updateApplicationToken = async () => {
    setUpdatingApplicationToken(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    const body = JSON.stringify({
      "payment_made":true,
      "amount_paid":200.00,
      "is_used":true
    })
    try {
      await axios.put(`${api_url}admissions/application-token/${token.token}/`,body, config).then((response) => {
        setUpdatingApplicationToken(false)
        Swal.fire({
          title:"Payment Successful",
          text:"You have successfully made payment. Please proceed with the rest of the application.",
          icon:"success",
        }).then(()=>setActiveStep(activeStep+1))
      })
    } catch (error) {
      setUpdatingApplicationToken(false)
      console.log(error)
    }
  }

  // AFTER SUCCESSFUL PAYMENT
  const onSuccess = (response) => {
    // console.log(response['reference'])
    updateApplicationToken()
   
  };

  // you can call this function anything
  const onClose = () => {
    toast.error("You need to make payment first before we can proceed with your application")
    console.log("closed");
  };
  

  useEffect(()=>{
    if(token.payment_made){
      Swal.fire({
        title:"Payment Made",
        text:"You have already made payment for this application. Click OK to continue",
        icon:"success"
      }).then(()=>{
        setActiveStep(activeStep + 1)
      })
    }
  },[])

  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">Payment Options</h3>
        <div className="form-info-description">
          <p>Proceed with payment and view the preview of your submission</p>
        </div>
      </div>
      {
        updatingApplicationToken ? (
          <Loading />
        ):(
          <form action="" className="main-admission-form">
            <div className="main-admission-form-row align-center">
              <label htmlFor="">Your Email</label>
              <div className="main-admission-form-right">
                <div className="main-admission-form-input">
                  <input
                    type="text"
                    name="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    id=""
                  />
                </div>
              </div>
            </div>
            <div className="form-bottom">
              <div className="empty-section"></div>
              <div className="form-save-buttons">
                <div className="save-button" onClick={()=>previousPage()}>
                  Previous
                </div>
                <div className="save-button" onClick={() => {
                makePayment();
              }}>
                  Make Payment
                </div>
              </div>
            </div>
          </form>
        )
      }
    </div>
  );
};

export default Payment;
