import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import { RotatingLines } from "react-loader-spinner";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import axios from "axios";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const AddStudent = () => {
  const navigations = ["Dashboard", "User Management", "Add Staff"];
  const [studentData, setStudentData] = useState(null);
  const [creatingStudent, setCreatingStudent] = useState(false);
  const [classes, setClasses] = useState([]);

  const handleFormChange = (e) => {
    setStudentData({
      ...studentData,
      [e.target.name]: e.target.value,
    });
  };

  const getClasses = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}academics/classes/`, config)
      .then((response) => {
        setClasses(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createStudent = async (e) => {
    e.preventDefault();
    setCreatingStudent(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(studentData);

    await axios
      .post(`${api_url}accounts/student/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Student Created Successfully",
        }).then(() => {
          setCreatingStudent(false);
          window.location.href = "/admin/user-management/students";
        });
      })
      .catch((error) => {
        console.error(error);
        setCreatingStudent(false);
        if (error?.response?.data) {
          for (const property in error?.response?.data) {
            if (property === "0") {
              toast.error(`${error?.response?.data[property]}`);
            } else {
              toast.error(
                `${property} error: ${error?.response?.data[property]}`
              );
            }
          }
        } else {
          toast.error("Something unexpected happended. Please try again.");
        }
      });
  };

  useEffect(() => {
    getClasses();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="news-details" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
          <h3 className="page-title">Add New Student</h3>
        </div>
        <form onSubmit={(e) => createStudent(e)} className="custom-form">
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Student ID</label>
              <input
                type="text"
                name="student_id"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">First Name*</label>
              <input
                type="text"
                name="first_name"
                className="custom-form-control"
                required
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Last Name*</label>
              <input
                type="text"
                name="last_name"
                className="custom-form-control"
                required
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Student Class*</label>
              <select
                onChange={(e) => handleFormChange(e)}
                name="student_class"
                id=""
                required
                className="custom-form-control"
              >
                <option value="">Select Class</option>
                {classes?.map((student_class, index) => (
                  <option value={student_class?.id} key={index}>
                    {student_class?.class_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="custom-form-col">
              <label htmlFor="">Date of birth</label>
              <input
                type="date"
                name="date_of_birth"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>

            <div className="custom-form-col">
              <label htmlFor="">Nationality</label>
              <input
                type="text"
                name="nationality"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>

          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Hometown</label>
              <input
                type="text"
                name="hometown"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Email</label>
              <input
                type="text"
                name="email"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Phone number</label>
              <input
                type="text"
                name="phone_number"
                className="custom-form-control"
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
          <div className="custom-form-row">
            <div className="custom-form-col">
              <label htmlFor="">Username*</label>
              <input
                type="text"
                name="username"
                className="custom-form-control"
                required
                onChange={(e) => handleFormChange(e)}
              />
            </div>
            <div className="custom-form-col">
              <label htmlFor="">Password*</label>
              <input
                type="text"
                name="password"
                className="custom-form-control"
                required
                onChange={(e) => handleFormChange(e)}
              />
            </div>
          </div>
          <div className="form-action-buttons">
            {creatingStudent ? (
              <div style={{ textAlign: "center" }}>
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <button type="submit" className="primary-button w-max-content">
                Save
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddStudent;
