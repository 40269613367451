import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";


const NewActivityModal = ({
  academicTerms,
  activityModal,
  setActivityModal,
}) => {
  
  const [data, setData] = useState(null);
  const [creatingActivity, setCreatingActivity] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createActivity = async (e) => {
    e.preventDefault();

    setCreatingActivity(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .post(`${api_url}academics/term-activity/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Activity Created Successfully",
        }).then(() => {
          setCreatingActivity(false);
          setActivityModal(false);
          window.location.reload();
        });
      })
      .catch((error) => {
        console.log(error);
        setCreatingActivity(false);
      });
  };
  return (
    <div
      onClick={() => setActivityModal(!activityModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setActivityModal(!activityModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Add New Activity</p>
          <form
            onSubmit={(e) => createActivity(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Academic Term</label>
                <select
                  name="academic_term"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Academic Term</option>
                  {academicTerms?.map((academic_term) => (
                    <option key={academic_term.id} value={academic_term.id}>
                      {academic_term.term_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity Name</label>
                <input
                  required
                  type="text"
                  name="activity_name"
                  id="activity_name"
                  placeholder="Ex. Term’s lessons begins "
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity Start Date</label>
                <input
                  required
                  type="date"
                  name="start_date"
                  id="start_date"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity End Date</label>
                <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  onChange={(e) => handleFormChange(e)}
                />
                <small>Leave blank if the activity is for only one day</small>
              </div>
            </div>

            <div className="admission-enquiry-form-row">
              <div className="">
                <label htmlFor="">
                  Is this activity an event?{" "}
                  <span style={{ fontSize: "12px" }}>
                    (This will make it show on the homepage of the website)
                  </span>{" "}
                </label>{" "}
                <br />
                <input
                  type="radio"
                  name="is_event"
                  value={true}
                  onChange={(e) => handleFormChange(e)}
                />{" "}
                Yes &nbsp; &nbsp;
                <input
                  type="radio"
                  name="is_event"
                  value={false}
                  defaultChecked
                  onChange={(e) => handleFormChange(e)}
                />{" "}
                No
              </div>
            </div>
            {creatingActivity ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewActivityModal;
