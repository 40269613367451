import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ParentDetails = ({
  activeStep,
  setActiveStep,
  handleFormChange,
  data,
}) => {
  const location = useLocation();

  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };
  const saveAndExit = () => {
    window.location.href = "/admission";
  };
  const saveAndProceed = () => {
    const validated_fields = validatedFields();
    console.log(data)
    if(validated_fields){
      setActiveStep(3);
    }
  };

  const validatedFields = () => {
    if(data.parent_one_name === ""){
      toast.error("Please enter the name of the first parent")
      return false
    }else if(data.parent_one_nationality === ""){
      toast.error("Please enter the nationality of the first parent")
      return false
    }else if(data.parent_one_relationship === ""){
      toast.error("Please enter the relationship of the first parent to the applicant")
      return false
    }else if(data.parent_one_telephone === ""){
      toast.error("Please enter the phone number of the first parent")
      return false
    }else if(data.parent_one_occupation === ""){
      toast.error("PLease enter the occupation of the first parent")
      return false
    }else if(data.parent_one_hometown === ""){
      toast.error("Please enter the hometown of the first parent")
      return false
    }else if(data.parent_two_name === ""){
      toast.error("Please enter the name of the second parent")
      return false
    }else if(data.parent_two_nationality === ""){
      toast.error("Please enter the nationality of the second parent")
      return false
    }else if(data.parent_two_relationship === ""){
      toast.error("Please enter the relationship of the second parent to the applicant")
      return false
    }else if(data.parent_two_telephone === ""){
      toast.error("Please enter the phone number of the second parent")
      return false
    }else if(data.parent_two_occupation === ""){
      toast.error("PLease enter the occupation of the second parent")
      return false
    }else if(data.parent_two_hometown === ""){
      toast.error("Please enter the hometown of the second parent")
      return false
    }
    return true
  }
  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">Fill in your personal information</h3>
        <div className="form-info-description">
          <p>
            Fields marked as <span style={{ color: "red" }}>*</span> are
            required
          </p>
          <p>
            The names entered here must be same spelling and other as those used
            on official documents.
          </p>
        </div>
      </div>
      <form action="" className="main-admission-form">
        {/* NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.parent_one_name}
                name="parent_one_name"
                id="parent_one_name"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* NATIONALITY */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Nationality <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_nationality}
                type="text"
                name="parent_one_nationality"
                id="parent_one_nationality"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* RELATIONSHIP TO STUDENT */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Relationship to student <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_relationship}
                type="text"
                name="parent_one_relationship"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* TELEPHONE */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Telephone <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_telephone}
                type="text"
                name="parent_one_telephone"
                id="parent_one_telephone"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOME ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Home Address <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_home_address}
                type="text"
                name="parent_one_home_address"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* OCCUPATION */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Occupation <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_occupation}
                type="text"
                name="parent_one_occupation"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* EMAIL */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Email
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_email}
                type="email"
                name="parent_one_email"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOMETOWN */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Hometown <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_hometown}
                type="text"
                name="parent_one_hometown"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* COMPANY NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Name
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_company_name}
                type="text"
                name="parent_one_company_name"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* COMPANY ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Address
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_one_company_address}
                type="text"
                name="parent_one_company_address"
                id="parent_one_company_address"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>

        <h5 className="section-title">OTHER PARENT DETAILS HERE</h5>
        {/* OTHER PARENT DETAILS */}
        {/* NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Name <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                type="text"
                value={data.parent_two_name}
                name="parent_two_name"
                id="parent_two_name"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* NATIONALITY */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Nationality <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_nationality}
                type="text"
                name="parent_two_nationality"
                id="parent_two_nationality"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* RELATIONSHIP TO STUDENT */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Relationship to student <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_relationship}
                type="text"
                name="parent_two_relationship"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* TELEPHONE */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Telephone <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_telephone}
                type="text"
                name="parent_two_telephone"
                id="parent_two_telephone"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOME ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Home Address <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_home_address}
                type="text"
                name="parent_two_home_address"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* OCCUPATION */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Occupation <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_occupation}
                type="text"
                name="parent_two_occupation"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* EMAIL */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Email
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_email}
                type="email"
                name="parent_two_email"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* HOMETOWN */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Hometown <span className="required">*</span>
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_hometown}
                type="text"
                name="parent_two_hometown"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* COMPANY NAME */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Name 
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_company_name}
                type="text"
                name="parent_two_company_name"
                id=""
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        {/* COMPANY ADDRESS */}
        <div className="main-admission-form-row align-center">
          <label htmlFor="">
            Company Address 
          </label>
          <div className="main-admission-form-right">
            <div className="main-admission-form-input">
              <input
                value={data.parent_two_company_address}
                type="text"
                name="parent_two_company_address"
                id="parent_two_company_address"
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        <div className="form-bottom">
          <div className="empty-section"></div>
          <div className="form-save-buttons">
            {activeStep > 1 && (
              <div className="save-button" onClick={() => previousStep()}>
                Previous
              </div>
            )}
            <div className="save-button" onClick={() => saveAndExit()}>
              Save &amp; Exit
            </div>
            <div className="save-button" onClick={() => saveAndProceed()}>
              Save &amp; Proceed
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ParentDetails;
