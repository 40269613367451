import React from "react";
import "./Footer.scss";
import Logo from "../../assets/Anell.png";
import {
  FaFacebookF,
  FaInstagram,
} from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  const date = new Date();
  return (
    <div className="footer">
      <div className="footer-info">
        <div className="first-section">
          <div className="school-logo">
            <img src={Logo} alt="anell school of excellence" />
            <span className="text">
              <h2>Anell</h2>
              <p>SCHOOL OF EXCELLENCE</p>
              <div className="underline"></div>
            </span>
          </div>
          
          <div className="donate-button">Donate Now</div>
        </div>
        <div className="footer-sections">
          <div className="footer-section">
            <div className="header">
              <h3>Pages</h3>
              <div className="underline"></div>
            </div>
            <div className="list">
              <Link to="/about/overview">About Us</Link>
              <Link to="/news-events/news">News and Events</Link>
              <Link to="/contact-us">Contact Us</Link>
              <Link>Mail Login</Link>
            </div>
          </div>
          <div className="footer-section">
            <div className="header">
              <h3>Info</h3>
              <div className="underline"></div>
            </div>
            <div className="list">
              <p>
                <span>info@anell.edu.gh</span> <br />
                <span>admission@anell.edu.gh</span>
              </p>
              <p>Kumasi, Airport Road, Kenyasi</p>
              <p>032 249 9539 / +233 243 738 101</p>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-info">
        <div className="content">
          <p>&copy; {date.getFullYear()}, Developed by <Link style={{color:"white", textDecoration:"underline"}} to="https://epahubb.com">Epahubb Consult</Link></p>
          <div className="social-media">
            <Link to="https://web.facebook.com/anellschoolofexcellence?mibextid=ZbWKwL&_rdc=1&_rdr" className="social-media-icon">
              <FaFacebookF />
            </Link>
            <Link to="https://instagram.com/anellschoolofexcellence?igshid=NTc4MTIwNjQ2YQ" className="social-media-icon">
              <FaInstagram />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
