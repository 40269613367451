import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const EditClassModal = ({ getClasses, editClassModal, setEditClassModal, selectedClass }) => {
  const [data, setData] = useState({});
  const [updatingClass, setUpdateClass] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateClass = async (e) => {
    e.preventDefault();
    setUpdateClass(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .put(`${api_url}academics/classes/${selectedClass.id}/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Class Updated Successfully",
        }).then(() => {
          getClasses();
          setUpdateClass(false);
          setEditClassModal(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setUpdateClass(false);
      });
  };

  return (
    <div
      onClick={() => setEditClassModal(!editClassModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setEditClassModal(!editClassModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Update Class</p>
          <form
            onSubmit={(e) => updateClass(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class Level</label>
                <select
                  name="class_level"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Class Category</option>
                  <option selected={data?.class_level ? data?.class_level === "Pre-school" : selectedClass?.class_level === "Pre-school"} value="Pre-school">Pre-school</option>
                  <option selected={data?.class_level ? data?.class_level === "Grade School" : selectedClass?.class_level === "Grade School"} value="Grade School">Grade School</option>
                </select>
              </div>
            </div>

            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class Name</label>
                <input
                  required
                  type="text"
                  name="class_name"
                  id="class_name"
                  value={data?.class_name ? data.class_name : selectedClass?.class_name}
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>

            {updatingClass ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditClassModal