import React from 'react'
import DashboardTopBar from '../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import { Route, Routes } from 'react-router-dom'
import StudentLibrary from '../pages/PortalPages/StudentPages/StudentLibrary'
import StudentSubjects from '../pages/PortalPages/StudentPages/StudentSubjects'
import StudentAnellMeet from '../pages/PortalPages/StudentPages/StudentAnellMeet'
import StudentDashboard from '../pages/PortalPages/StudentPages/StudentDashboard'

const StudentRoutes = () => {
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        <Routes>
            <Route path="/dashboard" element={<StudentDashboard />} />
            <Route path='/anell-library' element={<StudentLibrary />} />
            <Route path='/academics/subjects' element={<StudentSubjects />} />
            <Route path="/academics/anell-meet" element={<StudentAnellMeet />} />
        </Routes>
    </div>
  )
}

export default StudentRoutes