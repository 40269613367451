import React from "react";
import "./MapDirection.scss";
const MapDirection = () => {
  return (
    <div className="map-direction">
      <div className="map-direction-header">
        <h1>Map Direction</h1>
        <div className="underline"></div>
      </div>
      <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.296937878604!2d-1.5661074259157117!3d6.733581993262581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb951b50df6a2d%3A0xf5dc1695ffc1b41!2sAnell%20School%20Of%20Excellence!5e0!3m2!1sen!2sgh!4v1692893114730!5m2!1sen!2sgh"
          width="100%"
          height="600"
          style={{ border: "0"}}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        
      </div>
    </div>
  );
};

export default MapDirection;
