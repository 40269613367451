import React, {useEffect} from 'react'
import LeadersImage from "../../assets/aboutpage/LeadersMessage/leadershipImage.jpeg"
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import MessageSection from '../../components/AboutComponents/MessageSection/MessageSection'
import Footer from '../../components/Footer/Footer'
import BannerBar from '../../components/BannerBar/BannerBar'
import { Link, useLocation } from 'react-router-dom'
import {BsWhatsapp} from "react-icons/bs"

const LeadersMessage = () => {
  const location = useLocation()
  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
        <TopBar />
        <Navbar />
        <Banner title="About Us" subtitle="Message From Leadership" image={LeadersImage} />
        <BannerBar />
        <MessageSection />
        <Footer />
        <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
    </div>
  )
}

export default LeadersMessage