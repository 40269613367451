import React, { useState } from "react";
import "./ChooseAnell.scss";
import AcademicExperience from "../../../assets/aboutpage/academicExperience.png";
import Creative from "../../../assets/aboutpage/creative.png";
import Globe from "../../../assets/aboutpage/globe.png";
import Holistic from "../../../assets/aboutpage/holistic.png";
import Innovation from "../../../assets/aboutpage/innovation.png";
import Individual from "../../../assets/aboutpage/individual.png";
import ArrowUp from "../../../assets/aboutpage/ArrowUp.png";
import ArrowDown from "../../../assets/aboutpage/ArrowDown.png";
import { BiSolidDownArrow } from "react-icons/bi";

const ChooseAnell = () => {
  const [tab, setTab] = useState(1);

  return (
    <div id="choose-anell">
      <img src={ArrowDown} alt="arrow-down" className="arrow-down-1" />
      <img src={ArrowUp} alt="arrow-up" className="arrow-up-1" />

      <div className="choose-anell-content">
        <div className="choose-anell-header">
          <h2>
            WHY CHOOSE ANELL SCHOOL OF EXCELLENCE FOR YOUR EDUCATIONAL JOURNEY?
          </h2>
          <div className="underline"></div>
        </div>
        <div className="features">
          <div className="feature-display">
            {tab === 1 && (
              <div className="feature-display-content">
                <img
                  src={AcademicExperience}
                  alt="academic-experience"
                  className="feature-icon"
                />
                <div className="feature-display-top">
                  <h3>Academic Experience</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  Our rigorous and comprehensive academic programs, enriched
                  with the latest teaching methodologies, ensure that students
                  develop a deep understanding of subjects and critical thinking
                  skills that will serve them throughout their lives.
                </p>
              </div>
            )}

            {tab === 2 && (
              <div className="feature-display-content global-perspective">
                <img src={Globe} alt="globe" className="feature-icon" />
                <div className="feature-display-top">
                  <h3>Global Perspective</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  In an increasingly interconnected world, we foster a global
                  outlook by exposing students to diverse cultures, languages,
                  and perspectives. Our collaborative projects prepare students
                  to thrive in a multicultural society.
                </p>
              </div>
            )}

            {tab === 3 && (
              <div className="feature-display-content creative-expression">
                <img src={Creative} alt="creative" className="feature-icon" />
                <div className="feature-display-top">
                  <h3>Creative Expression</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  We celebrate the arts and encourage creative expression.
                  Whether it's through music, visual arts, drama, or literary
                  pursuits, every student has the opportunity to explore and
                  showcase their talents.
                </p>
              </div>
            )}

            {tab === 4 && (
              <div className="feature-display-content holistic-development">
                <img src={Holistic} alt="holistic" className="feature-icon" />
                <div className="feature-display-top">
                  <h3>Holistic Development</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  Education at Anell goes beyond academics. We emphasize
                  physical fitness, character development, and leadership skills
                  through sports, clubs, and community service initiatives.
                </p>
              </div>
            )}
            {tab === 5 && (
              <div className="feature-display-content innovation-technology">
                <img
                  src={Innovation}
                  alt="innovation"
                  className="feature-icon"
                />
                <div className="feature-display-top">
                  <h3>Innovation and Technology</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  Our classrooms are equipped with the latest technology to
                  enhance the learning experience. Students are empowered to
                  engage with cutting-edge tools that prepare them for the
                  digital age.
                </p>
              </div>
            )}
            {tab === 6 && (
              <div className="feature-display-content individual-support">
                <img
                  src={Individual}
                  alt="innovation"
                  className="feature-icon"
                />
                <div className="feature-display-top">
                  <h3>Individual Support</h3>
                  <div className="underline"></div>
                </div>
                <p className="feature-display-text">
                  We recognize that every student is unique. Our dedicated
                  facilitators and counselors provide personalized attention,
                  ensuring that each student's strengths are celebrated and
                  their challenges addressed.
                </p>
              </div>
            )}
          </div>
          <div className="feature-icons">
            {tab === 1 && (
              <div className="droparrow left-first">
                <BiSolidDownArrow />
              </div>
            )}
            {tab === 2 && (
              <div className="droparrow left-second">
                <BiSolidDownArrow />
              </div>
            )}

            {tab === 3 && (
              <div className="droparrow left-third">
                <BiSolidDownArrow />
              </div>
            )}
            {tab === 4 && (
              <div className="droparrow left-fourth">
                <BiSolidDownArrow />
              </div>
            )}

            {tab === 5 && (
              <div className="droparrow left-fifth">
                <BiSolidDownArrow />
              </div>
            )}

            {tab === 6 && (
              <div className="droparrow left-sixth">
                <BiSolidDownArrow />
              </div>
            )}

            <div
              className="feature-icon academic-experience"
              style={{ scale: `${tab === 1 ? "1.2" : "1"}` }}
              onClick={() => setTab(1)}
            >
              <img src={AcademicExperience} alt="" />
            </div>
            <div
              className="feature-icon"
              onClick={() => setTab(2)}
              style={{ scale: `${tab === 2 ? "1.2" : "1"}` }}
            >
              <img src={Globe} alt="" />
            </div>
            <div
              className="feature-icon"
              onClick={() => setTab(3)}
              style={{ scale: `${tab === 3 ? "1.2" : "1"}` }}
            >
              <img src={Creative} alt="" />
            </div>
            <div
              className="feature-icon"
              onClick={() => setTab(4)}
              style={{ scale: `${tab === 4 ? "1.2" : "1"}` }}
            >
              <img src={Holistic} alt="" />
            </div>
            <div
              className="feature-icon"
              onClick={() => setTab(5)}
              style={{ scale: `${tab === 5 ? "1.2" : "1"}` }}
            >
              <img src={Innovation} alt="" />
            </div>
            <div
              className="feature-icon"
              onClick={() => setTab(6)}
              style={{ scale: `${tab === 6 ? "1.2" : "1"}` }}
            >
              <img src={Individual} alt="" />
            </div>
          </div>
        </div>
        <div className="sub-text">
          <p>
            Join us at Anell and be part of a community that values excellence,
            compassion, and lifelong learning. Explore our website to learn more
            about our programs, facilities, and the incredible achievements of
            our students. Feel free to reach out to us with any questions or to
            schedule a campus tour. We can't wait to welcome you into the Anell
            family!
          </p>
        </div>

        <img src={ArrowDown} alt="arrow-down" className="arrow-down-2" />
        <img src={ArrowUp} alt="" className="arrow-up-2" />
      </div>
    </div>
  );
};

export default ChooseAnell;
