import React from 'react'
import { Link } from 'react-router-dom'
import SectionHeader from '../../SectionHeader/SectionHeader'
import CareerItem from '../CareerItem/CareerItem'

const CareersSection = () => {
  return (
    <div id="careers-section" >
        <div className='main-container'>
            <SectionHeader title="Careers" />
        </div>
        <div className="careers">
            <div className="careers-intro">
                <p>
                At Anell School of Excellence, we are committed to fostering a culture of continuous growth and development among our educators. 
                </p>
                <p>
                This Career and Professional Development Policy outlines our approach to supporting educators in enhancing their skills, expanding their knowledge, and advancing their careers within the institution. 
                </p>
                <p>
                We believe that investing in our educators' professional growth ultimately contributes to the overall success of our students and the institution.
                </p>


            </div>
            <div className="career-objective">
                <h3 className="career-objective-header">
                The School’s objective for its employees is to:
                </h3>
                <ul className="career-objectives">
                    <li className="career-objective-item">
                        Provide educators with opportunities for continuous learning and skill enhancement.
                    </li>
                    <li className="career-objective-item">
                        Support educators in their pursuit of career advancement within Anell School of Excellence.
                    </li>
                    <li className="career-objective-item">
                    Foster a collaborative and innovative educational environment by promoting the exchange of ideas and best practices.
                    </li>
                    <li className="career-objective-item">Align educators' professional development with the institution's goals and values.</li>
                </ul>
            </div>
            <div className="career-items">
                <CareerItem title="Professional Development Studies"/>
                <CareerItem title="Career Advancement" />
            </div>
            <div className="career-description">
                <p>
                    At Anell School of Excellence, we are dedicated to empowering our educators with the tools, knowledge, and opportunities needed to excel in their roles and contribute to the growth of our institution. 
                </p>
                <p>
                    Through a commitment to continuous learning and development, we aim to provide an environment where educators thrive and students receive the best possible education.
                </p>
            </div>
            <div className="join-our-team-section main-container">
                <SectionHeader title="Join Our Team" />
                <div className="application-description">
                    <h3 className="application-description-header">
                        HOW TO APPLY:
                    </h3>
                    <p className="application-header-text">

                    Interested and qualified applicants are to submit the following documents through the school’s email: &nbsp; 
                    <Link className='application-mail-link' to="mailto:info@anell.edu.gh">info@anell.edu.gh</Link>
                    </p>
                    <ul className="application-requirements">
                        <li className="application-requirement">
                            <b>Resume / CV</b>: Tailor your resume or curriculum vitae to highlight your relevant skills, qualifications, and experience.
                        </li>
                        <li className="application-requirement">
                            <b>Cover Letter</b>: Write a customized cover letter explaining your interest in the school and the specific position you're applying for.
                        </li>
                        <li className="application-requirement">
                            <b>References</b>: Prepare a list of professional references who can vouch for your qualifications (minimum of two).
                        </li>
                    </ul>
                    <div className="additional-application-info">
                        <p>
                            You can also write to us at <Link>career@anell.edu.gh</Link>
                        </p>
                        <ul className="school-address">
                            <li>The Human Resource Manager</li>
                            <li>Anell School of Excellence</li>
                            <li>AD- 395 – 8884</li>
                            <li>Kenyase – Kumasi.</li>
                        </ul>
                        <p>
                        Phone: 0322499539 / +233243738101 
                        </p>
                        <p>
                        NB: Please note that only shortlisted candidates will be contacted for an interview. 
                        </p>
                        <p>
                        We eagerly anticipate the arrival of your application and possibility of you becoming a valued member of our school community. 
                        </p>
                        <div className="additional-requirements">
                            <h3>REQUIREMENT</h3>
                            <ul className="requirement-list">
                                <li>Should be a diploma and a degree holder.</li>
                                <li>A Bachelor’s degree in Education (B. Ed) and Post Graduate Diploma in Education (P. G. D. E) will be a plus.</li>
                                <li>Candidates must have a minimus of 3 years working experience.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CareersSection