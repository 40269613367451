import axios from "axios";
import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";

const AddTeacherSubjectModal = ({ subjects, schoolClasses, teachers, addTeacherSubjectModal, setAddTeacherSubjectModal,getTeacherSubjects }) => {
  const [data, setData] = useState({});
  const [creatingTeacherSubject, setCreatingTeacherSubject] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const createTeacherSubject = async (e) => {
    e.preventDefault();
    setCreatingTeacherSubject(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .post(`${api_url}academics/teacher-subjects/`, body, config)
      .then((response) => {
        Swal.fire({
          title: "Success",
          icon: "success",
          text: "Teacher Subject Created Successfully",
        }).then(() => {
          getTeacherSubjects();
          setCreatingTeacherSubject(false);
          setAddTeacherSubjectModal(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setCreatingTeacherSubject(false);
      });
  };
  
  return (
    <div
      onClick={() => setAddTeacherSubjectModal(!addTeacherSubjectModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setAddTeacherSubjectModal(!addTeacherSubjectModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Add Teacher Subject</p>
          <form
            onSubmit={(e) => createTeacherSubject(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class</label>
                <select
                  name="school_class"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Class</option>
                  {
                    schoolClasses?.map((school_class,index)=>(
                      <option value={school_class?.id} key={index}>{school_class?.class_name}</option>
                    ))
                  }
                  
                </select>
              </div>
              
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Suject</label>
                <select
                  name="subject"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Subject</option>
                  {
                    subjects?.map((subject,index)=>(
                      <option value={subject?.id} key={index}>{subject?.subject_name}</option>
                    ))
                  }
                  
                </select>
              </div>
              
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Teacher</label>
                <select
                  name="teacher"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">Select Teacher</option>
                  {
                    teachers?.map((teacher,index)=>(
                      <option value={teacher?.id} key={index}>{teacher?.staff_name}</option>
                    ))
                  }
                  
                </select>
              </div>
              
            </div>

            

            {creatingTeacherSubject ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddTeacherSubjectModal