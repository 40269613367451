import React, { useContext, useEffect, useState } from "react";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import Loading from "../../../components/Loading/Loading";
import NoData from "../../../components/PortalComponents/NoData/NoData";
import ReactPaginate from "react-paginate";
import { LuDownload } from "react-icons/lu";
import { toast } from "react-toastify";
import axios from "axios";
import { api_url } from "../../../url";
import { Link } from "react-router-dom";
import AuthContext from "../../../context/authContext";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import { AiOutlineDelete } from "react-icons/ai";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";

const AdminAnellMeet = () => {
  const { schoolClasses } = useContext(AuthContext);
  const [loadingMeetings, setLoadingMeetings] = useState(true);
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [pastMeetings, setPastMeetings] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [newMeetingData, setNewMeetingData] = useState({});
  const [creatingMeeting, setCreatingMeeting] = useState(false);
  const [deletingMeeting, setDeletingMeeting] = useState(false);

  const navigations = ["Dashboard", "Academics", "Anell Meet"];

  const handleFormChange = (e) => {
    setNewMeetingData({
      ...newMeetingData,
      [e.target.name]: e.target.value,
    });
  };

  const getMeetings = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}anell-meet/`, config)
      .then((response) => {
        if (response?.data?.success) {
          setUpcomingMeetings(response.data?.upcoming_meetings);
          setPastMeetings(response.data?.past_meetings);
        } else {
          if (response?.data?.message) {
            toast.error(response?.data?.message);
          } else {
            toast.error("Something unexpected happened");
          }
        }
        setLoadingMeetings(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");

        setLoadingMeetings(false);
      });
  };

  const createMeeting = async (e) => {
    e.preventDefault();
    setCreatingMeeting(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(newMeetingData);
    await axios
      .post(`${api_url}anell-meet/`, body, config)
      .then((response) => {
        setCreatingMeeting(false);
        if (response?.data?.success) {
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Meeting Created Successfully",
          }).then(() => {
            setCreatingMeeting(false);
            window.location.reload();
          });
        } else {
          if (response?.data?.errors) {
            for (const property in response?.data?.errors) {
              if (property === "0") {
                toast.error(`${response?.data?.errors[property]}`);
              } else {
                toast.error(
                  `${property} error: ${response?.data?.errors[property]}`
                );
              }
            }
          } else {
            toast.error("Something unexpected happened");
          }
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");

        setCreatingMeeting(false);
      });
  };

  const deleteMeeting = (meeting_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingMeeting(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .delete(`${api_url}anell-meet/${meeting_id}/`, config)
          .then((response) => {
            if(response?.data?.success){
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Meeting Deleted Successfully",
              }).then(() => {
                getMeetings();
              });
            }else{
              if(response?.data?.message){
                toast.error(response?.data?.message);
              }else{
                toast.error("Couldn't delete meeting")
              }
            }
            setDeletingMeeting(false);
          })
          .catch((error) => {
            setDeletingMeeting(false);

            toast.error(
              "There was an error deleting the meeting, please try again."
            );
          });
      }
    });
  };

  useEffect(() => {
    getMeetings();
  }, []);
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Anell Meet</div>
          <div
            className="custom-tabs"
            style={{ borderBottom: "1px solid black" }}
          >
            <div
              onClick={() => setActiveTab(1)}
              className={`custom-tab-section ${activeTab === 1 && "active"}`}
            >
              Upcoming Meetings
            </div>
            <div
              onClick={() => setActiveTab(2)}
              className={`custom-tab-section ${activeTab === 2 && "active"}`}
            >
              Past Meetings
            </div>
            
          </div>
          {loadingMeetings ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              {activeTab === 1 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Meeting Name</th>
                        <th>Class</th>
                        <th>Meeting Date</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upcomingMeetings &&
                        upcomingMeetings?.map((meeting, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{meeting?.meeting_name}</td>
                            <td>{meeting?.school_class}</td>
                            <td>{meeting?.date}</td>

                            <td style={{ cursor: "pointer" }}>
                              {deletingMeeting ? (
                                <RotatingLines
                                  strokeColor="#021D53"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="26"
                                  visible={true}
                                />
                              ) : (
                                <div className="action-buttons">
                                  <Link
                                    target="_blank"
                                    to={meeting?.meeting_link}
                                    className="view-button"
                                  >
                                    Join Meeting
                                  </Link>
                                  <Link
                                    onClick={() => deleteMeeting(meeting?.id)}
                                    className="delete-button"
                                  >
                                    Delete <AiOutlineDelete />
                                  </Link>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {upcomingMeetings?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              {activeTab === 2 && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>SRL</th>
                        <th>Meeting Name</th>
                        <th>Class</th>
                        <th>Meeting Date</th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {pastMeetings &&
                        pastMeetings?.map((meeting, index) => (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{meeting?.meeting_name}</td>
                            <td>{meeting?.school_class}</td>
                            <td>{meeting?.date}</td>

                            
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {pastMeetings?.length <= 0 && <NoData />}
                  {/* <div className="pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=""
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel=""
                      renderOnZeroPageCount={null}
                      containerClassName="pagination-container"
                      activeClassName="active-page"
                      pageLinkClassName="page-link"
                    />
                  </div> */}
                </div>
              )}
              {activeTab === 3 && (
                <div>
                  {/* Create New Meeting Form */}
                  <form
                    onSubmit={(e) => createMeeting(e)}
                    className="custom-form"
                  >
                    <div className="custom-form-row">
                      <div className="custom-form-col">
                        <label htmlFor="">Meeting Name*</label>
                        <input
                          type="text"
                          name="meeting_name"
                          required
                          className="custom-form-control"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="custom-form-col">
                        <label htmlFor="">Meeting Link*</label>
                        <input
                          type="text"
                          name="meeting_link"
                          className="custom-form-control"
                          required
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="custom-form-col">
                        <label htmlFor="">Date*</label>
                        <input
                          type="date"
                          name="date"
                          className="custom-form-control"
                          required
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                    </div>

                    <div className="custom-form-row">
                      <div className="custom-form-col">
                        <label htmlFor="">Time*</label>
                        <input
                          type="time"
                          name="time"
                          required
                          className="custom-form-control"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="custom-form-col">
                        <label htmlFor="">Class*</label>
                        <select
                          onChange={(e) => handleFormChange(e)}
                          name="school_class"
                          id=""
                          required
                          className="custom-form-control"
                        >
                          <option value="">Select Class</option>
                          {schoolClasses?.map((student_class, index) => (
                            <option value={student_class?.id} key={index}>
                              {student_class?.class_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="custom-form-row">
                      <div className="custom-form-col">
                        <label htmlFor="">Description*</label>
                        <textarea
                          onChange={(e) => handleFormChange(e)}
                          className="custom-form-control"
                          name="description"
                          required
                          id=""
                        ></textarea>
                      </div>
                    </div>

                    <div className="form-action-buttons">
                      {creatingMeeting ? (
                        <div style={{ textAlign: "center" }}>
                          <RotatingLines
                            strokeColor="#021D53"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="26"
                            visible={true}
                          />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="primary-button w-max-content"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminAnellMeet;
