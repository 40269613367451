import React from 'react'
import './AboutAnell.scss'

const AboutAnell = () => {
  return (
    <div id='about-anell-container'>
        <div className="about-anell">
            <div className="header">
                <h1>About Anell</h1>
                <div className="underline"></div>
            </div>
            <div className="about-content">
                <div className="first-text">
                    <p>
                        The Anell School of Excellence is dedicated to providing personalized education tailored to individual learning styles, fostering independent thinking to empower students to become creative architects of their present and future. Our primary focus is on the holistic development—cognitive, physical, social, and emotional—of each student. Utilizing modern technological strategies.
                    </p>
                    <p>
                        Our approach involves a seamless transition from lower to upper grades, with preschool emphasizing learning through play in a safe and nurturing environment. The creche goes beyond mere childcare, promoting early development through various activities. Nursery and reception students are encouraged to inquire and explore, while our grade school curriculum is designed to empower students as active participants in their learning journey. We prioritize engaging them in activities that align with their developmental stage while fostering independence and critical thinking skills. 
                    </p>
                    <p>
                        Our dedicated staff ensures excellence, supported by spacious classrooms, well-designed facilities, and a unique outdoor area for stimulating learning experiences. We prioritize safety and security through vigilant surveillance of all activities.
                    </p>
                </div>

                <div className="second-text">
                    <p>
                        The school’s management intends to feed the upper grades gradually with the lower grades. Our preschool combines learning experiences and structured play in a fun, safe, and nurturing environment. 
                    </p>
                    <p>
                        Differently, our creche offers far more than just toddler care, rather enhances beginners’ development through a series of Early Years Foundation activities such as dexterity activities, and oral, auditory, and sensorial discovery activities. 
                    </p>
                    <p>
                        Our nursery and reception learners are considered the ‘enquiry group’. Diverse opportunities are created for them to explore reading through synthetic phonics, numeracy, and the like. 
                    </p>
                    <p>
                        Our grade schoolers are considered the ‘master minders’ who explore opportunities through child-centered activities created to engage them in all learning areas. At Anell we strive for excellence, and by so doing we boast of our quality, certified, and dedicated staff. 
                    </p>
                    <p>
                        Anell School of Excellence has a unique outdoor area providing a stimulating learning experience for our learners. Our spacious classroom and well-designed washroom provide a serene environment and comfort for learners and staff. 
                    </p>
                    <p>
                        A unique play area with modern resources that facilitate the learning experience for learners and staff. We prioritize surveillance of both indoor and outdoor activities of learners, staff, and visitors to ensure safety and security.
                    </p>
                </div>
            </div>
        </div>
        <div className="about-mission-vision">
            <div className="mission">
                <h1 className='mission-header'>
                    Our Mission
                </h1>
                <p className="mission-text">
                    ANELL’s MISSION is to provide quality education and childcare in a safe, child-friendly, and conducive environment that builds a foundation for lifelong learning.
                </p>
            </div>
            <div className="vision">
                <h1 className="vision-header">
                    Our Vision
                </h1>
                <div className="vision-text">
                    <p>
                        Our VISION is to develop well-rounded confident and responsible individuals who aspire to achieve their full potential. 
                    </p>
                    <p>
                        We do this by providing quality teaching and learning experience through a supportive learning environment and engaging school culture in which everyone’s achievement is celebrated and no child is left behind in terms of academics.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutAnell