import React, { useRef } from "react";
import Logo from "../../assets/Anell.png";
import UserImage from "../../assets/admissionimages/dummyProfile.png";
import axios from "axios";
import Swal from "sweetalert2";
import { api_url } from "../../url";
import { RotatingLines } from "react-loader-spinner";
import {
  AiFillPhone,
  AiFillMail,
  AiFillFacebook,
  AiOutlineInstagram,
} from "react-icons/ai";
import { BsGlobe } from "react-icons/bs";
import ReactToPrint from "react-to-print";
import { useState } from "react";

const Preview = ({ data, token }) => {
  const applicationFormRef = useRef();
  const [submittingForm, setSubmittingForm] = useState(false);

  const submitForm = async () => {
    setSubmittingForm(true)
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    const body = JSON.stringify({
      application_completed: true,
    });
    try {
      await axios
        .put(
          `${api_url}admissions/user-application/${token.token}/`,
          body,
          config
        )
        .then((response) => {
          setSubmittingForm(false)
          Swal.fire({
            title: "Form submitted",
            text: "You’ve successfully submitted application form for admission to Anell School of Excellence.",
            icon: "success",
          }).then(() => {
            window.location.href = `/admission/${token.token}/medicals`;
          });
        });
    } catch (error) {
      setSubmittingForm(false)
      console.log(error);
    }
  };

  return (
    <div className="admission-form-details">
      <div className="admission-form-info">
        <h3 className="form-info-title">PREVIEW</h3>
        <div className="form-info-description">
          <p>
            Add photocopies of all related documents, certificates & results
            Keep a copy of this printout for any future enquiry.
          </p>
        </div>
      </div>
      <div id="admission-form-preview">
        <div ref={applicationFormRef}>
          <div className="preview-form-header" style={{borderBottom:"2px solid #021d53", padding:"20px 40px", display:"flex", justifyContent:"space-between", alignItems:"center"}}>
            <div className="preview-form-title" style={{fontWeight:600, color:"#021d53", fontSize:"40px", textTransform:"capitalize"}}>Application for Admission</div>
            <div className="school-logo" style={{padding:"28px 0px", display:"flex", gap:"10px"}}>
              <img src={Logo} alt="anell school of excellence" style={{height:"70px"}} />
              <span className="text" style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div className="main-text" style={{display:"flex", gap:"5px", alignItems:"center"}}>
                  <h2 style={{fontSize:"49.31px", fontWeight:"700", color:"#021D53"}}>Anell</h2>
                  <span style={{display:"flex", flexDirection:"column", gap:"2px", color: "#221B2D"}}>
                    <p>SCHOOL OF</p>
                    <p>EXCELLENCE</p>
                  </span>
                </div>
                <p className="motto" style={{fontStyle:"italic", color:"#021d53", fontWeight:'600'}}>...excel and lead!!!</p>
              </span>
            </div>
          </div>
          <div className="preview-form-body" style={{padding:"20px"}}>
            {/* STUDENT DETAILS */}
            <div className="preview-form-section" style={{marginBottom: "10px", borderBottom:"1px solid black", paddingBottom:"20px"}}>
              <div className="preview-form-section-title" style={{fontSize:"13.33px", padding:"8px 35px", fontWeight:"bold", border: "0.5px solid black", marginBottom: "22px", width:"max-content"}}>Student Details</div>
              <div className="preview-form-details">
                <div className="student-image">
                  <img
                    style={{ height: "150px" }}
                    src={
                      data.passport_picture
                        ? URL.createObjectURL(data.passport_picture)
                        : UserImage
                    }
                    alt="passport"
                  />
                </div>
                <div className="preview-form-information" style={{marginTop:"10px"}}>
                  {/* FIRST ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"5px"}}>
                    {/* SURNAME */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Surname:</label>
                        <p className="data">{data.surname}</p>
                      </p>
                    </div>
                    {/* FIRST NAME */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>First Name:</label>
                        <p className="data">{data.first_name}</p>
                      </p>
                    </div>
                    {/* OTHER NAME(S) */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Other Name(s):</label>
                        <p className="data">{data.other_name}</p>
                      </p>
                    </div>
                  </div>
                  {/* SECOND ROW */}
                  <div className="preview-form-information-row"  style={{display:"flex", marginTop:"10px"}}>
                    {/* DATE OF BIRTH */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Date of birth:</label>
                        <p className="data">{data.date_of_birth ? data.date_of_birth : `${data.dob_year}-${data.dob_month}-${data.dob_day}`}</p>
                      </p>
                    </div>
                    {/* PLACE OF BIRTH */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Place of birth:</label>
                        <p className="data">{data.place_of_birth}</p>
                      </p>
                    </div>
                    {/* NATIONALITY */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Nationality:</label>
                        <p className="data">{data.nationality}</p>
                      </p>
                    </div>
                  </div>
                  {/* THIRD ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    {/* GENDER */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Gender:</label>
                        <p className="data">{data.gender}</p>
                      </p>
                    </div>
                    {/* HOME TOWN */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Hometown:</label>
                        <p className="data">{data.hometown}</p>
                      </p>
                    </div>
                    {/* RELIGION */}
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Region:</label>
                        <p className="data">{data.region}</p>
                      </p>
                    </div>
                  </div>
                  {/* FOURTH ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Home Address:</label>
                        <p className="data">{data.home_address}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Home Language:</label>
                        <p className="data">{data.home_language}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Other Language:</label>
                        <p className="data">{data.other_language}</p>
                      </p>
                    </div>
                  </div>
                  {/* FIFTH ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Religion:</label>
                        <p className="data">{data.religion}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>I am applying for class:</label>
                        <p className="data">{data.applying_class}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field"></p>
                    </div>
                  </div>
                </div>
              </div>

              {/* SCHOOLS ATTENDED */}
              <div className="subsection">
                <p className="subsection-title" style={{marginTop:"20px", fontSize:"15px", fontWeight:600, color:"#00000080"}}>Schools Attended</p>
                {/* FIRST PREVIOUS SCHOOL */}
                <div className="subsection-details">
                  <div className="preview-form-details">
                    <div className="preview-form-information">
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            1. <label style={{fontWeight:600}}>Name:</label>
                            <span className="data">
                              {data.previous_school_one_name}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Year:</label>
                            <span className="data">
                              {data.previous_school_one_year}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>City/Country:</label>
                            <span className="data">
                              {data.previous_school_one_city}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Class:</label>
                            <span className="data">
                              {data.previous_school_one_class}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* SECOND PREVIOUS SCHOOL */}
                <div className="subsection-details">
                  <div className="preview-form-details">
                    <div className="preview-form-information">
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            2. <label style={{fontWeight:600}}>Name:</label>
                            <span className="data">
                              {data.previous_school_two_name}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Year:</label>
                            <span className="data">
                              {data.previous_school_two_year}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>City/Country:</label>
                            <span className="data">
                              {data.previous_school_two_city}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Class:</label>
                            <span className="data">
                              {data.previous_school_two_class}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* THIRD PREVIOUS SCHOOL */}
                <div className="subsection-details">
                  <div className="preview-form-details">
                    <div className="preview-form-information">
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            3. <label style={{fontWeight:600}}>Name:</label>
                            <span className="data">
                              {data.previous_school_three_name}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Year:</label>
                            <span className="data">
                              {data.previous_school_three_year}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>City/Country:</label>
                            <span className="data">
                              {data.previous_school_three_city}
                            </span>
                          </p>
                        </div>
                        <div className="preview-form-information-item" style={{flex:1}}>
                          <p className="field">
                            <label style={{fontWeight:600}}>Class:</label>
                            <span className="data">
                              {data.previous_school_three_class}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* PARENT DETAILS */}
            <div className="preview-form-section">
              <div className="preview-form-section-title" style={{fontSize:"13.33px", padding:"8px 35px", fontWeight:"bold", border: "0.5px solid black", marginTop:"10px", marginBottom: "12px", width:"max-content"}}>Parent Details</div>
              {/* FIRST PARENT */}
              <div className="preview-form-details">
                <div className="preview-form-information">
                  {/* FIRST ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Name:</label>
                        <p className="data">{data.parent_one_name}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Nationality:</label>
                        <p className="data">{data.parent_one_nationality}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Relationship to student:</label>
                        <p className="data">{data.parent_one_relationship}</p>
                      </p>
                    </div>
                  </div>
                  {/* SECOND ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Telephone:</label>{" "}
                        <p className="data">{data.parent_one_telephone}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Home Address:</label>
                        <p className="data">{data.parent_one_home_address}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Occupation:</label>
                        <p className="data">{data.parent_one_occupation}</p>
                      </p>
                    </div>
                  </div>
                  {/* THIRD ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Email:</label>
                        <p className="data">{data.parent_one_email}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Company Name:</label>
                        <p className="data">{data.parent_one_company_name}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Company Address:</label>
                        <p className="data">
                          {data.parent_one_company_address}
                        </p>
                      </p>
                    </div>
                  </div>
                  {/* FOURTH ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Hometown:</label>
                        <p className="data">{data.parent_one_hometown}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}></div>
                    <div className="preview-form-information-item" style={{flex:1}}></div>
                  </div>
                </div>
              </div>

              <div className="subsection">
                <div className="subsection-title" style={{marginTop:"40px", fontSize:"15px", fontWeight:600, color:"#00000080"}}>Other Parent Details</div>
                {/* SECOND PARENT */}
                <div className="preview-form-details">
                  <div className="preview-form-information">
                    {/* FIRST ROW */}
                    <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Name:</label>
                          <p className="data">{data.parent_two_name}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Nationality:</label>
                          <p className="data">{data.parent_two_nationality}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Relationship to student:</label>
                          <p className="data">{data.parent_two_relationship}</p>
                        </p>
                      </div>
                    </div>
                    {/* SECOND ROW */}
                    <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Telephone:</label>{" "}
                          <p className="data">{data.parent_two_telephone}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Home Address:</label>
                          <p className="data">{data.parent_two_home_address}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Occupation:</label>
                          <p className="data">{data.parent_two_occupation}</p>
                        </p>
                      </div>
                    </div>
                    {/* THIRD ROW */}
                    <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Email:</label>
                          <p className="data">{data.parent_two_email}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Company Name:</label>
                          <p className="data">{data.parent_two_company_name}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Company Address:</label>
                          <p className="data">
                            {data.parent_one_company_address}
                          </p>
                        </p>
                      </div>
                    </div>
                    {/* FOURTH ROW */}
                    <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                      <div className="preview-form-information-item" style={{flex:1}}>
                        <p className="field">
                          <label style={{fontWeight:600}}>Hometown:</label>
                          <p className="data">{data.parent_two_hometown}</p>
                        </p>
                      </div>
                      <div className="preview-form-information-item" style={{flex:1}}></div>
                      <div className="preview-form-information-item" style={{flex:1}}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* GUARDIAN DETAILS */}
            <div className="preview-form-section">
              <div className="preview-form-section-title" style={{fontSize:"13.33px", padding:"8px 35px", fontWeight:"bold", border: "0.5px solid black", marginTop:"20px", marginBottom: "22px", width:"max-content"}}>Guardian Details</div>
              <div className="preview-form-details">
                <div className="preview-form-information">
                  {/* FIRST ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Name:</label>
                        <p className="data">{data.guardian_name}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Nationality:</label>
                        <p className="data">{data.guardian_nationality}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Relationship to student:</label>
                        <p className="data">
                          {data.guardian_relationship_to_student}
                        </p>
                      </p>
                    </div>
                  </div>
                  {/* SECOND ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Telephone:</label>{" "}
                        <p className="data">{data.guardian_telephone}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Home Address:</label>
                        <p className="data">{data.guardian_home_address}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Occupation:</label>
                        <p className="data">{data.guardian_occupation}</p>
                      </p>
                    </div>
                  </div>
                  {/* THIRD ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Email:</label>
                        <p className="data">{data.guardian_email}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Company Name:</label>
                        <p className="data">{data.guardian_company_name}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Company Address:</label>
                        <p className="data">{data.guardian_company_address}</p>
                      </p>
                    </div>
                  </div>
                  {/* FOURTH ROW */}
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>Hometown:</label>
                        <p className="data">{data.parent_two_hometown}</p>
                      </p>
                    </div>
                    <div className="preview-form-information-item" style={{flex:1}}></div>
                    <div className="preview-form-information-item" style={{flex:1}}></div>
                  </div>
                </div>
              </div>
            </div>

            {/* ADDITIONAL DETAILS */}
            <div className="preview-form-section">
              <div className="preview-form-section-title" style={{fontSize:"13.33px", padding:"8px 35px", fontWeight:"bold", border: "0.5px solid black",marginTop:"20px", marginBottom: "22px", width:"max-content"}}>
                Additional Details
              </div>
              <div className="preview-form-details">
                <div className="preview-form-information">
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>How did you hear about us?:</label>
                        <p className="data">
                          {data.medium}{" "}
                          {data.medium === "Family/Friend" && (
                            <span>({data.family_friend_medium})</span>
                          )}
                          {data.medium === "Other" && (
                            <span>({data.other_medium})</span>
                          )}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>
                          What are the reason(s) you have chosen Anell School of
                          Excellence:
                        </label>{" "}
                        <p className="data">{data.why_anell}</p>
                      </p>
                    </div>
                  </div>
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>
                          Parent’s instruction for medical care in case of an
                          emergency:
                        </label>{" "}
                        <p className="data">{data.parent_instruction}</p>
                      </p>
                    </div>
                  </div>
                  <div className="preview-form-information-row" style={{display:"flex", marginTop:"10px"}}>
                    <div className="preview-form-information-item" style={{flex:1}}>
                      <p className="field">
                        <label style={{fontWeight:600}}>
                          Please inform us, if he/she has any known medical
                          condition, health issues or allergies which you would
                          like us to be aware:
                        </label>{" "}
                        <p className="data">{data.medical_information}</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* ADMISSION CLAUSES */}
            <div className="admission-clauses" style={{marginTop:"20px"}}>
              <h3 className="admission-clauses-header" style={{color:"#021d53"}}>ADMISSION CLAUSES</h3>
              <ul className="admission-clauses-list" style={{marginLeft:"20px", color:"#021d53"}}>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  The school reserves the right to determine the appropriate
                  class for each applicant
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  An applicant will have to write an entry exams after
                  submission of the admission form.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  The admission fee is one-time payment whilst the school fees
                  is payable per every school term (Crèche, Nursery,
                  Kindergarten & Basic School) and is non-refundable or
                  transferable.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  All term fees should be settled by the first week of reopening
                  and receipt will be issued detailing payment.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  If fees are not paid by the end of the first week, a reminder
                  will be sent to the parent/guardian and late fee of GH₵ 100.00
                  be charged.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  If the fees are not paid by the end of the second week of
                  reopening, the admission would be terminated.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  All fees are non- refundable & non- transferable.
                </li>
                <li className="admission-clause" style={{marginBottom:"10px", textAlign:"justify"}}>
                  I understand that either one full term’s notice in writing or
                  payment of one full term’s fees in lieu is required in event
                  of withdrawing the pupil for any reason.
                </li>
              </ul>
            </div>

            {/* NOTES TO CONSIDER */}
            <div className="note-to-consider" style={{marginTop:"20px", marginBottom:"20px", backgroundColor:"#deecfd", padding: "20px"}}>
              <h3 className="note-title" style={{marginBottom:"10px"}}>NOTE</h3>
              <ul className="notes" style={{marginLeft:"20px"}}>
                <li className="note" style={{marginBottom:"10px"}}>
                  Please attach a photocopy of the applicant’s birth
                  certificate, the last academic report and 2 passport
                  photographs.
                </li>
                <li className="note">
                  Pupils will only be considered as candidates for admission and
                  entry into ANELL SCHOOL OF EXCELLENCE when the admission form
                  has been completed and returned with all relevant documents
                </li>
                <li className="note">
                  The maximum number of students per class will not exceed 25.
                </li>
                <li className="note">
                  Admission is subject to availability of space and the pupil
                  satisfying the admission requirement.
                </li>
              </ul>
            </div>

            <p>
              Before signing the admission form, please read the ADMISSION
              CLAUSES that are highlighted on the form carefully.
            </p>

            {/* DECLARATION */}
            <div className="declaration" style={{marginTop:"20px"}}>
              <h3 className="declaration-header" style={{color:"#021d53"}}>DECLARATION</h3>
              <div className="declaration-body" style={{fontSize:"20px"}}>
                I, <b>{data?.applicant_title} {data?.applicant_name} </b> parent/guardian of{" "}
                <b>
                  {data.first_name} {data.surname} {data.other_name}{" "}
                </b>{" "}
                assert that all information provided are valid and true to the
                best of my knowledge and understand that my child should be
                admitted base on the stipulated policy of the school and all
                fees paid are not refundable
              </div>
              <div className="declaration-bottom" style={{marginTop: "15px", display:"flex", gap: "20px", justifyContent:"space-between"}}>
                <div className="declaration-bottom-item" style={{flex:1, display:"flex", gap:"10px"}}>
                  <label style={{fontSize:"20px"}}>Signature</label>
                  <input style={{width:"100%", border:"none", borderBottom:"1px solid black"}} type="text" name="" id="" readOnly />
                </div>
                <div className="declaration-bottom-item" style={{flex:1, display:"flex", gap:"10px"}}>
                  <label htmlFor="">Date</label>
                  <input style={{width:"100%", border:"none", borderBottom:"1px solid black"}} type="text" name="" id="" readOnly />
                </div>
                <div className="declaration-bottom-item" style={{flex:1, display:"flex", gap:"10px"}}>
                  <label htmlFor="">Telephone</label>
                  <input style={{width:"100%", border:"none", borderBottom:"1px solid black"}} type="text" name="" id="" readOnly />
                </div>
              </div>
            </div>

            {/* OFFICIAL USE */}
            <div className="official-use" style={{marginTop:"70px"}}>
              <h3 className="official-use-header" style={{color:"#021d53"}}>OFFICIAL USE ONLY</h3>
              <div className="official-use-form">
                <div className="official-use-row" style={{display:"flex", gap:"20px", justifyContent:"space-between", marginTop:"15px" }}>
                  <div className="official-use-col" style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">Entry Exams results</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                  <div className="official-use-col" style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">Comments</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                </div>
                <div className="official-use-row" style={{display:"flex", gap:"20px", justifyContent:"space-between", marginTop:"15px" }}>
                  <div className="official-use-col" style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">Class Admitted to</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                  <div className="official-use-col" style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">Student Code</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                </div>
                <div className="official-use-row" style={{display:"flex", gap:"20px", justifyContent:"space-between", marginTop:"15px" }}>
                  <div className="official-use-col" style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">Date of Admission</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                  <div className="official-use-col"style={{flex:1, display:"flex", gap:"10px",alignItems:"flex-end"}}>
                    <label style={{flex:1}} htmlFor="">PRINCIPAL’S ENDORSEMENT</label>
                    <input type="text" style={{flex:2.5, border:"none",borderBottom:"1px solid black"}} readOnly />
                  </div>
                </div>
              </div>
            </div>

            {/* SCHOOL DETAILS */}
            <div className="school-details" style={{marginTop:"60px", borderTop:"1px solid #021d53",gap:"20px", display:"flex", padding: "20px 0px", justifyContent:"space-between", fontSize:"16px"}}>
              <div className="school-detail">
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>Airport Road, Kenyasi- Kumasi Ashanti Region</p>
              </div>
              <div className="school-detail">
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>
                  <AiFillPhone />
                  0322499539/0243738101
                </p>
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>
                  <AiFillMail />
                  info@anell.edu.gh
                </p>
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>
                  <BsGlobe /> www.anell.edu.gh
                </p>
              </div>
              <div className="school-detail">
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>
                  <AiOutlineInstagram />
                  anellschoolofexcellence
                </p>
                <p style={{display:'flex', alignItems:"center", gap:"5px", marginBottom:"5px"}}>
                  <AiFillFacebook />
                  @anellschoolofexcellence
                </p>
              </div>
            </div>
          </div>
        </div>
        {
          submittingForm ? (
            <div style={{ textAlign: "center" }}>
            <RotatingLines
              strokeColor="#021D53"
              strokeWidth="5"
              animationDuration="0.75"
              width="26"
              visible={true}
            />
          </div>
          ):(
            <div className="preview-form-bottom">
          <div className="preview-form-buttons">
            <ReactToPrint
              trigger={() => <div className="preview-form-button">Print</div>}
              content={() => applicationFormRef.current}
              
              suppressErrors={true}

              bodyClass="preview-form-header preview-form-title school-logo text main-text motto"
            />
            <div className="preview-form-button" onClick={() => submitForm()}>
              Submit & Continue
            </div>
          </div>
        </div>
          )
        }
        
      </div>
    </div>
  );
};

export default Preview;
