import React, {useEffect} from 'react'
import Banner from '../../components/Banner/Banner'
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import NewsImage from "../../assets/NewsEvent.png"
import ContactSection from '../../components/ContactSection/ContactSection'
import { Link, useLocation } from 'react-router-dom'
import { BsWhatsapp } from 'react-icons/bs'

const ContactPage = () => {
  const location = useLocation()
  // Scroll to the top when the page is mounted or the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
        <TopBar />
        <Navbar />
        <Banner underline="true" title="Contact Us" image={NewsImage} />
        <ContactSection />
        <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
        <Footer />
    </div>
  )
}

export default ContactPage