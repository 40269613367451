import React, { useState } from "react";
import "./Message.scss";
import { IoIosArrowForward } from "react-icons/io";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Message = ({ leaderMessage }) => {
  const [tapOpen, setTapOpen] = useState(true);
  return (
    <div id="message-container">
      <div className="message-section-header">
        <h2 style={{ fontSize: "23.04px", textTransform: "uppercase" }}>
          Message From <span>{leaderMessage.position} </span>
        </h2>
        <div className="underline"></div>
      </div>
      <div id="message">
        <AiOutlineCloseCircle
          className="close-btn"
          style={{ display: `${tapOpen ? "block" : "none"}` }}
          onClick={() => setTapOpen(!tapOpen)}
        />
        <div className="image-section">
          <img src={leaderMessage.image} alt="" style={{ maxWidth: "100%" }} />
          <div className="image-section-footer">
            <div
              className="leader-name-position"
              style={{ textAlign: `${tapOpen ? "center" : "left"}` }}
            >
              <h4 className="name">{leaderMessage.name}</h4>
              <p className="position">{leaderMessage.position}</p>
            </div>
            <div
              style={{ display: `${tapOpen ? "none" : "flex"}` }}
              className="read-message-button"
              onClick={() => setTapOpen(!tapOpen)}
            >
              <span>Read Message</span>
              <IoIosArrowForward />
            </div>
          </div>
        </div>
        <div
          style={{ display: `${tapOpen ? "block" : "none"}` }}
          className="leader-message-section"
        >
          {leaderMessage.position === "HEAD OF ACADEMICS" && (
            <>
              <p>Greetings to all from Ghana!</p>
              <p>
                I am thrilled to extend my warm salutations and extend a
                heartfelt welcome to Anell School of Excellence. It brings us
                immense joy to have you participate in our virtual platform and
                embark on a journey to discover the myriad of opportunities our
                esteemed institution has to offer. Our committed faculty has
                invested considerable dedication into crafting an exceptional
                setting that fosters living, learning, and personal development.
                Anell School of Excellence proudly adopts an Integrated
                Curriculum, often referred to as a Hybrid System of Education.
                This distinctive approach exposes our students to diverse
                learning perspectives, spanning from their immediate
                surroundings to the global panorama. This methodology is founded
                on the belief that such an approach enables learners to attain
                pertinent knowledge applicable to their individual lives, and
                subsequently expand their comprehension to encompass the broader
                world. The wave of modernization has touched all aspects of our
                lives, prompting the management of Anell to institute the
                following measures, thereby ensuring an unwavering commitment to
                quality education, as emphasized in our vision and mission
                statements:
              </p>
              <ul>
                <li>- Establishment of a state-of-the-art I.C.T laboratory.</li>
                <li>
                  - A well-endowed library. - Integration of abacus lessons.
                </li>
                <li>- Introduction of a comprehensive database system.</li>
                <li>
                  - Incorporation of contemporary international teaching and
                  learning methodologies, including therapies, enrichment
                  programs, seminars, and more.
                </li>
                <li>
                  - Notably, our curriculum encompasses Computer Programming,
                  exposing students to various programming languages such as
                  Scratch, Q-Basic, and others.
                </li>
              </ul>
              <p>
                Whether you are a student, a parent, a faculty member, or a
                casual visitor, we firmly believe that becoming a part of the
                A.S. of E. community will be an enriching experience. As you
                navigate through our online platform, you will discern the
                meticulous care we have invested in crafting a secure and
                nurturing environment for all. We would be elated to coordinate
                an in-person interaction as you immerse yourself in our virtual
                tour. We look forward to fostering strong connections within our
                community and getting to know each of you better.
              </p>
              <h3>Warm regards</h3>
              <h3>Mr. Philips Yeboah Pimpong</h3>
            </>
          )}
          {leaderMessage.position === "DIRECTOR" && (
            <>
              <p style={{ margin: "20px 0px 50px" }}>
                Dear Anell School of Excellence Community,
              </p>
              <p>
                It is with immense pleasure and a sense of great responsibility
                that I extend my warmest greetings to you all. As the Director
                of Anell School of Excellence, I am honored to have the
                opportunity to lead an institution that stands as a beacon of
                holistic education, unwavering values, and unparalleled
                excellence. Our school is a vibrant hub where young minds
                flourish.
              </p>
              <p>
                We go beyond the traditional way of learning. We seek to nurture
                unique talents and foster critical thinking. Our core values of
                assertiveness, discipline, attention to detail, humility,
                empathy, obedience, tolerance and perseverance form the bedrock
                of all our endeavors. Collaboration is at the heart of our
                educational approach. Our extracurricular activities offer
                diverse learning experiences, enriching our students beyond the
                classroom.
              </p>
              <p>
                I extend my gratitude to our parents for entrusting us with the
                education and development of their children. Your partnership is
                invaluable, and together, we can pave the way for a future that
                is bright and promising. Let’s embark on this exciting journey,
                inspiring our students to envision grand aspirations, toil
                diligently, and strive for extraordinary achievements.
              </p>
              <p>
                Thank you for being a part of the Anell School of Excellence
                family. Together, we will continue to create an environment that
                nurtures excellence and empowers future generations. With warm
                regards,
              </p>
              <h3 style={{ marginTop: "40px" }}>
                Ellen Antwiwaa Sarkodie (Mrs.)
              </h3>
              <h3>Director.</h3>
            </>
          )}
          {leaderMessage.position === "HEAD OF ADMINISTRATION" && (
            <>
              <p>Dear parents, guardians, and prospective students,</p>
              <p>
                I am delighted to welcome you to Anell School of Excellence,
                where we strive for nothing short of the best in education and
                holistic development. As the Head of Administration, I would
                like to highlight several key aspects that make our school truly
                exceptional:
              </p>
              <ul style={{ listStyleType: "decimal", marginLeft: "20px" }}>
                <li>
                  <b>Admission Process:</b> Our transparent, fair, and
                  accessible admission process welcomes students from diverse
                  backgrounds, ensuring a smooth enrollment experience.
                </li>

                <li>
                  <b>Transition of New Learners:</b> We pride ourselves on
                  making new learners feel at home from day one with a caring
                  staff and welcoming environment.
                </li>

                <li>
                  <b>Parent-School Cooperation:</b> We emphasize a strong
                  partnership between parents and the school, encouraging open
                  communication and active involvement in your child's
                  education.
                </li>

                <li>
                  <b> Safety & Security:</b> Our contemporary security measures
                  foster an environment that cultivates students’ development
                  and sustains safety.
                </li>

                <li>
                  <b>Health and Wellness:</b> Routine health check-ups and
                  easily accessible nursing and counseling services contribute
                  to their holistic well-being.
                </li>
                <li>
                  <b>Qualified Staff:</b> Our devoted and exceptionally skilled
                  educators foster academic brilliance, character development,
                  and core values.
                </li>
                <li>
                  <b>Professionalism:</b> Anell School of Excellence upholds
                  high teaching and administrative standards to ensure the best
                  educational experience.
                </li>
                <li>
                  <b>Modern Facilities and Equipment:</b>We offer
                  state-of-the-art facilities and equipment to foster creativity
                  and innovation in a dynamic learning environment.
                </li>
              </ul>
              <p>
                We eagerly anticipate welcoming your child into our dynamic
                community, dedicated to equipping them with the resources for
                academic success, fostering their well-being, and supporting
                their personal growth. If you have any questions or need
                additional information, please don't hesitate to contact us.
              </p>
              <p>Sincerely,</p>
              <h3 style={{ marginTop: "40px" }}>Mr. Eric Opoku Asare</h3>
              <h3>Head of Administration.</h3>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;
