import React from 'react'
import Banner from '../../components/Banner/Banner'
import Navbar from '../../components/Navbar/Navbar'
import TopBar from '../../components/TopBar/TopBar'
import CareersImage from "../../assets/aboutpage/careersImage.jpeg"
import './Careers.scss'
import Footer from '../../components/Footer/Footer'
import CareersSection from '../../components/AboutComponents/CareersSection/CareersSection'
import BannerBar from '../../components/BannerBar/BannerBar'
import { Link } from 'react-router-dom'
import { BsWhatsapp } from 'react-icons/bs'

const Careers = () => {
  return (
    <div>
        <TopBar />
        <Navbar />
        <Banner title="About Us" subtitle="Careers" image={CareersImage} />
        <BannerBar />
        <CareersSection />
        <Link to="https://wa.me/2330504828824?text=" target="_blank" id="whatsapp-icon">
          <BsWhatsapp />
        </Link>
        <Footer />
    </div>
  )
}

export default Careers