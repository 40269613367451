import React from 'react'
import DashboardTopBar from '../../components/PortalComponents/DashboardTopBar/DashboardTopBar'
import DashboardNavbar from '../../components/PortalComponents/DashboardNavbar/DashboardNavbar'
import AnellLogo from "../../assets/Anell.png"

const NotFound = () => {
  return (
    <div>
        <DashboardTopBar />
        <DashboardNavbar />
        <div className="not-found" >
            <img src={AnellLogo} alt="Anell School Of Excellence" />
            <h3>
                Page Not Found
            </h3>
        </div>
    </div>
  )
}

export default NotFound