import React, { useContext, useEffect, useState } from 'react'
import AnellLogo from '../../../assets/Anell.png'
import Navbar from '../../../components/Navbar/Navbar'
import TopBar from '../../../components/TopBar/TopBar'
import {BiSolidUser} from "react-icons/bi"
import {GiPadlock} from "react-icons/gi"
import './LoginPage.scss'
import { RotatingLines } from 'react-loader-spinner'
import AuthContext from '../../../context/authContext'
import { Link, Navigate } from 'react-router-dom'
import axios from 'axios'
import { api_url } from '../../../url'
import { toast } from 'react-toastify'

const LoginPage = () => {
    const [loggingIn, setLoggingIn] = useState(false)
    const { user} = useContext(AuthContext)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    
    const loginUser = async(e) => {
        e.preventDefault()
        
        const config = {
            headers:{
                "Content-Type": "application/json",
            }
        }
        const body = JSON.stringify({
            "username": username,
            "password": password
        })
        setLoggingIn(true)
        try {
            await axios.post(`${api_url}authentication/login/`,body,config).then((response) => {
                localStorage.setItem("access",response.data['access'])
                localStorage.setItem("refresh",response.data['refresh'])
                
                getUser();

                setLoggingIn(false)
            })
        } catch (error) {
            toast.error("Invalid username or password")
            setLoggingIn(false)
        }
    }

    const getUser = async () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization":`JWT ${localStorage.getItem("access")}`
            }
        }

        axios.get(`${api_url}accounts/me/`,config).then((response)=>{
            if(response?.data?.is_administrator){
                window.location.href = "/admin/dashboard"
            }else if(response?.data?.is_student){
                window.location.href = "/student/dashboard"
            }else if(response?.data?.is_employee){
                window.location.href = "/teacher/dashboard"
            }
        })
    }

    // useEffect(() => {
        
    // }, [user])
    
    if(user?.is_administrator){
        
        window.location.href = "/admin/dashboard"
        // <Navigate to="/admin/dashboard" />
    }else if(user?.is_student){
        window.location.href = "/student/dashboard"
    }else if(user?.is_employee){
        <Navigate to="/employee/dashboard" />
    }

  return (
    <div>
        
        <div id="login-page">
            <div className="login-section">
                <div className="school-logo">
                    <img src={AnellLogo} alt="anell" />
                    <div className="school-name">
                        <Link to="/" className="school-main-name">
                            <h2 className="anell">
                                Anell
                            </h2>
                            <span className="school-of-excellence">
                                <p>SCHOOL OF</p>
                                <p>EXCELLENCE</p>
                            </span>
                        </Link>
                        <p className="school-motto">
                            ...excel and lead!!!
                        </p>
                    </div>
                    <div className="logo-underline"></div>
                </div>
                <form onSubmit={loginUser} className="login-form">
                    <div className="input-container">
                        <BiSolidUser />
                        <input  onChange={(e)=>setUsername(e.target.value)} type="text" name="username" id="username" placeholder='Username' required />
                    </div>
                    <div className="input-container">
                        <GiPadlock />
                        <input onChange={(e)=>setPassword(e.target.value)} type="password" name="password" id="password" placeholder='Password' required />
                    </div>
                    {
                        loggingIn ? (
                            <div className='rotating-lines'>
                                <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                                />
                            </div>
                        ):(
                            <input type="submit" value="LOG IN" />
                        )
                    }
                </form>
            </div>
        </div>
    </div>
  )
}

export default LoginPage