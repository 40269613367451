import React from "react";
import "./ContactSection.scss";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail, MdLocationOn } from "react-icons/md";

const ContactSection = () => {
  const schoolContacts = [
    {
      icon: <FaPhoneAlt />,
      name: "Phone",
      contactDetails: "0322 499 539 / +233 (0)234 738 101",
    },
    {
      icon: <MdEmail />,
      name: "Email",
      contactDetails: "info@anell.edu.gh  |  admission@anell.edu.gh",
    },
    {
      icon: <MdLocationOn />,
      name: "Locate Us",
      contactDetails: "Kumasi, Airport Road, Kenyasi",
    },
  ];
  return (
    <div id="contact">
      <div className="contact-details">
        <div className="contacts">
          {schoolContacts.map((contact, index) => (
            <div key={index} className="contact">
              <div className="contact-icon">{contact.icon}</div>
              <div className="contact-info">
                <div className="contact-name">{contact.name}</div>
                <div className="contact-description">
                  {contact.contactDetails}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-form-container">
        <div className="contact-form-header">
          <h3>LEAVE US YOUR CONCERN</h3>
          <p>AND WE WILL GET BACK TO YOU</p>
        </div>
        <form action="" className="contact-form">
          <input
            type="text"
            name="full_name"
            id="full_name"
            placeholder="Full Name*"
          />
          <input
            type="email"
            name="email_address"
            id="email_address"
            placeholder="Email*"
          />
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder="Subject*"
          />
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Message*"
          ></textarea>
          <input type="submit" value="SUBMIT NOW" />
        </form>
      </div>
      <div className="contact-map-direction">
        <div className="map-direction-header">
          <h1>Map Direction</h1>
          <div className="underline"></div>
        </div>
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.296937878604!2d-1.5661074259157117!3d6.733581993262581!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb951b50df6a2d%3A0xf5dc1695ffc1b41!2sAnell%20School%20Of%20Excellence!5e0!3m2!1sen!2sgh!4v1692893114730!5m2!1sen!2sgh"
            width="100%"
            height="600"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
