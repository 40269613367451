import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading/Loading";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import { api_url } from "../../../url";
import ReactPaginate from "react-paginate";

const AdmissionEnquiries = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loadingEnquiries, setLoadingEnquiries] = useState(true);

  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = enquiries.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(enquiries.length / itemsPerPage);


  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % enquiries.length;
    setItemOffset(newOffset);
  };

  const getEnquiries = async () => {
    if (localStorage.getItem("access")) {
      setLoadingEnquiries(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      try {
        await axios
          .get(`${api_url}admissions/enquiry/`, config)
          .then((response) => {
            setEnquiries(response.data);
            setLoadingEnquiries(false);
          });
      } catch (error) {
        setLoadingEnquiries(false);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    getEnquiries();
  }, []);
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      <div id="admission-enquiries" className="portal-page">
        <div className="admin-dashboard-title">
          <div className="admin-dashboard-navigation">
            <GoHomeFill /> <IoIosArrowForward />{" "}
            <span className="section">Dashboard</span> <IoIosArrowForward />{" "}
            <span className="section">Admission Enquiries</span>{" "}
          </div>
        </div>
        <div className="section-list">
          <div className="table-section-header">Enquiries</div>
          {loadingEnquiries ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              {
                enquiries.length > 0 ? (
                  <table>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Date Enquired</th>
                    <th>Class Enquired</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((enquiry) => (
                    <tr key={enquiry.id}>
                      <td>{enquiry.full_name}</td>
                      <td>{enquiry.date_enquired}</td>
                      <td>{enquiry.enquired_class}</td>
                      <td>
                        {enquiry.status === "Pending" ? (
                          <span className="yellow-badge">{enquiry.status}</span>
                        ) : (
                          <>
                            {enquiry.status === "Approved" ? (
                              <span className="green-badge">
                                {enquiry.status}
                              </span>
                            ) : (
                              <>
                                {enquiry.status === "Declined" ? (
                                  <span className="red-badge">
                                    {enquiry.status}
                                  </span>
                                ) : (
                                  <span>{enquiry.status}</span>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </td>
                      <td>
                        <Link
                          to={`/dashboard/admissions/enquiries/${enquiry.id}`}
                          className="view-button"
                        >
                          View <AiOutlineEye />
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
                ):(
                  <h3 style={{textAlign:"center"}}>No Enquiries Made</h3>
                )
              }
              
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=""
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel=""
                  renderOnZeroPageCount={null}
                  containerClassName="pagination-container"
                  activeClassName="active-page"
                  pageLinkClassName="page-link"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionEnquiries;
