import { useContext } from "react"
import { Outlet, Navigate} from "react-router-dom"
import AuthContext from "../context/authContext"

const PrivateRoutes = ({component: Component, ...rest}) => {
    const {user} = useContext(AuthContext)
    
    if(!user || user === "undefined" || user === undefined){
        return <Navigate to="/login" />
    }else{
        return <Component {...rest} />
    }

    // return (
    //     user ?  <Outlet /> : <Navigate to="/login" />
    // )
}

export default PrivateRoutes