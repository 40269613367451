import React from "react";
import { GoHomeFill } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";

const DashboardNavigation = ({ navigations }) => {
  return (
    <div className="admin-dashboard-navigation">
      <GoHomeFill />
        {
            navigations.map((navigation, index) => (
                <React.Fragment key={index}>
                    <IoIosArrowForward />{" "}
                    <span className="section">{navigation}</span>
                </React.Fragment>
            ))
        }
    </div>
  );
};

export default DashboardNavigation;
