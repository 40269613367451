import React, { useEffect, useState } from 'react'
import DashboardTopBar from '../../../components/PortalComponents/DashboardTopBar/DashboardTopBar';
import DashboardNavbar from '../../../components/PortalComponents/DashboardNavbar/DashboardNavbar';
import AddTeacherSubjectModal from './AddTeacherSubjectModal';
import { GoHomeFill } from 'react-icons/go';
import { IoIosArrowForward } from 'react-icons/io';
import Loading from '../../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { api_url } from "../../../url";
import NoData from '../../../components/PortalComponents/NoData/NoData';


const AdminTeacherSubjects = () => {
    const [addTeacherSubjectModal, setAddTeacherSubjectModal] = useState(false)
    const [editTeacherSubjectModal, setEditTeacherSubjectModal] = useState(false)
    const [selectedTeacherSubject, setSelectedTeacherSubject] = useState({})
    const [teacherSubjects,setTeacherSubjects] = useState([])
    const [loadingTeacherSubjects, setLoadingTeacherSubjects] = useState(true)
    const [deletingTeacherSubject, setDeletingTeacherSubject] = useState(false)
    const [schoolClasses, setSchoolClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [teachers, setTeachers] = useState([])

    const getClasses = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${api_url}academics/classes/`, config)
        .then((response) => {
          setSchoolClasses(response.data);
          
        })
        .catch((error) => {
          
          
        });
    };

    const getSubjects = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };
      await axios
        .get(`${api_url}academics/subjects/`, config)
        .then((response) => {
          setSubjects(response.data);
        })
        .catch((error) => {});
    };

  const getTeachers = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(`${api_url}accounts/teachers`, config)
      .then((response) => {
        setTeachers(response.data);
        
      })
      .catch((error) => {
        
      });
  };

    const getTeacherSubjects = async () => {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
          .get(`${api_url}academics/teacher-subjects/`, config)
          .then((response) => {
            console.log(response?.data)
            setTeacherSubjects(response.data);
            setLoadingTeacherSubjects(false);
          })
          .catch((error) => {
            
            setLoadingTeacherSubjects(false);
          });
      };
    
      const deleteTeacherSubject = (teacher_subject_id) => {
        Swal.fire({
          title: `Are you sure?`,
          text: "Once deleted, there is no going back",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setDeletingTeacherSubject(true);
            const config = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${localStorage.getItem("access")}`,
              },
            };
            await axios
              .delete(`${api_url}academics/teacher-subjects/${teacher_subject_id}/details/`, config)
              .then((response) => {
                Swal.fire({
                  icon: "success",
                  title: "Deleted!",
                  text: "Teacher Subject Deleted Successfully",
                }).then(() => {
                  setDeletingTeacherSubject(false);
                  getTeacherSubjects();
                });
              })
              .catch((error) => {
                setDeletingTeacherSubject(false);
                
                toast.error(
                  "There was an error deleting the class, please try again."
                );
              });
          }
        });
      };

  useEffect(()=>{
    getSubjects();
    getClasses();
    getTeachers()
    getTeacherSubjects()
  },[])
  
  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {addTeacherSubjectModal && (
        <AddTeacherSubjectModal
          teachers = {teachers}
          subjects = {subjects}
          schoolClasses = {schoolClasses}
          getTeacherSubjects={getTeacherSubjects}
          addTeacherSubjectModal={addTeacherSubjectModal}
          setAddTeacherSubjectModal={setAddTeacherSubjectModal}
        />
      )}
     
      <div id="school-classes" className="portal-page">
        <div className="admin-dashboard-title">
          <div className="admin-dashboard-navigation">
            <GoHomeFill /> <IoIosArrowForward />{" "}
            <span className="section">Dashboard</span> <IoIosArrowForward />{" "}
            <span className="section">Teacher subjects</span>
          </div>
        </div>
        <div className="section-list classes-list-section">
          <div className="table-section-header">Teacher Subjects</div>

          <Link
            onClick={() => setAddTeacherSubjectModal(true)}
            className="primary-button-outline w-max-content"
            style={{ marginBlock: "5px" }}
          >
            Add Teacher Subject
          </Link>

          {loadingTeacherSubjects ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>SRL</th>
                      <th>Subject Name</th>
                      <th>Class Name</th>
                      <th>Teacher</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {teacherSubjects &&
                      teacherSubjects.map((teacher_subject, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{teacher_subject?.subject}</td>
                          <td>{teacher_subject?.school_class}</td>
                          <td>{teacher_subject?.teacher}</td>
                          <td>
                            {deletingTeacherSubject ? (
                              <RotatingLines
                                strokeColor="#021D53"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="26"
                                visible={true}
                              />
                            ) : (
                              <div className="action-buttons">
                               
                                <Link
                                  className="delete-button"
                                  onClick={() => deleteTeacherSubject(teacher_subject?.id)}
                                >
                                  Delete <AiOutlineDelete />
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                  teacherSubjects.length <= 0 && (
                    <NoData />
                  )
                }
              </>
            </div>
          )}

          
        </div>
      </div>
    </div>
  )
}

export default AdminTeacherSubjects