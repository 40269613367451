import React from "react";
import "./Admissions.scss";

import { HiBuildingLibrary } from "react-icons/hi2";
import { FaGraduationCap } from "react-icons/fa";
import { IoFootballOutline } from "react-icons/io5";
import { TbSocial } from "react-icons/tb";
import { Link } from "react-router-dom";

const Admissions = () => {
  return (
    <div className="admissions-container">
      <div className="admissions">
        <div className="admissions-card">
          <div className="activities">
            <div className="activity">
              <HiBuildingLibrary />
              <div className="description">
                <h3 className="description-header">Childhood Life</h3>
                <p className="description-text">Best experienced here</p>
              </div>
            </div>

            <div className="activity">
              <FaGraduationCap />
              <div className="description">
                <h3 className="description-header">Graduation</h3>
                <p className="description-text">Overall in here</p>
              </div>
            </div>

            <div className="activity">
              <IoFootballOutline />
              <div className="description">
                <h3 className="description-header">Athletics</h3>
                <p className="description-text">Sports Exercises</p>
              </div>
            </div>

            <div className="activity">
              <TbSocial />
              <div className="description">
                <h3 className="description-header">Social Life</h3>
                <p className="description-text">Connect and build</p>
              </div>
            </div>
          </div>
          <div className="admission-statement">
            <div className="left">
              <div className="image">
                {/* <img src={AdmissionImage} alt="" /> */}
              </div>
            </div>
            <div className="right">
              <div className="admission-information">
                <h2>Apply For Admission</h2>
                <h4>
                  Admissions take place at the beginning of every academic year.
                </h4>
                <p>
                  At Anell School of Excellence, we believe in nurturing young
                  minds to reach their fullest potential. Our dedicated team of
                  educators is committed to providing a safe, inspiring, and
                  inclusive learning environment. We invite you to be a part of
                  our educational journey. Join our school family and watch your
                  child thrive!
                </p>
                <Link to="/admission" className="classic-button">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admissions;
