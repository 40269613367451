import React from "react";
import "./DashboardTopBar.scss";
import Logo from "../../../assets/Anell.png";
import {AiOutlineBell} from "react-icons/ai"
import {SlLogout} from "react-icons/sl"
import { useContext } from "react";
import AuthContext from "../../../context/authContext";
import { FaBars, FaTimes } from "react-icons/fa";
import UserImg from "../../../assets/user.png"
import { Link } from "react-router-dom";

const DashboardTopBar = () => {
  const {logoutUser, user, setPortalMobileView, portalMobileView} = useContext(AuthContext)
  
  return (
    <div id="dashboard-topbar" className="">
      <div className="school-logo-topbar">
        <img src={Logo} alt="anell school of excellence" />
        <span className="text">
          <div className="main-text">
            <h2>Anell</h2>
            <span>
              <p>SCHOOL OF</p>
              <p>EXCELLENCE</p>
            </span>
          </div>
          <p className="motto">...excel and lead!!!</p>
        </span>
      </div>
      <div className="notification-user-logout">
        {/* <div className="notification">
          <AiOutlineBell />
          <div className="notification-dot"></div>
        </div> */}
        <Link to="/dashboard/profile" className="user">
          <div className="username-position">
            <p className="username">
              {user.first_name} {user.last_name}
            </p>
            <p className="position">
              {
                user?.is_student && "Student"
              }
              {
                user?.is_employee && "Staff"
              }
              {
                user?.is_administrator && "Admin"
              }
            </p>
          </div>
          <div className="user-image">
              <img src={UserImg} alt="" />
          </div>
        </Link>
        <div className="logout-btn" onClick={logoutUser}>
          <SlLogout /> Logout
        </div>
      </div>
      <div id="portal-mobile" onClick={()=>setPortalMobileView(!portalMobileView)}>
            {
                portalMobileView ? <FaTimes /> : <FaBars />
            }
      </div>
    </div>
  );
};

export default DashboardTopBar;
