import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { Link, useParams } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { calendarEvents } from "../../../utils/data";
import CalendarAccordion from "../../../components/CalendarAccordion/CalendarAccordion";
import axios from "axios";
import { api_url } from "../../../url";
import NewActivityModal from "./NewActivityModal";

const CalendarActivities = () => {
  const { academic_year } = useParams();
  const navigations = ["Dashboard", "Calendar", "Activities"];
  const [loadingData, setLoadingData] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [calendarData, setCalendarData] = useState(null);
  const [activityModal, setActivityModal] = useState(false);
  const [academicTerms, setAcademicTerms] = useState([]);

  const getYearActivities = async (academic_year) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    await axios
      .get(
        `${api_url}academics/academic-year/${academic_year}/activities`,
        config
      )
      .then((response) => {
        
        setCalendarData(response.data);
        setLoadingData(false);
      })
      .catch((error) => {
        
        setLoadingData(false);
      });
  };

  const getAcademicTerms = async ()=> {
    const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${localStorage.getItem("access")}`,
        },
      };

    await axios.get(`${api_url}academics/academic-term/`, config).then((response)=>{
        setAcademicTerms(response.data);
    }).catch((error)=>{
        console.log(error);
    })
  }

  useEffect(() => {
    getYearActivities(academic_year);
    getAcademicTerms();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />
      {
        activityModal && (
            <NewActivityModal
              activityModal={activityModal}
              setActivityModal={setActivityModal}
              academicTerms={academicTerms}
            />
        )
      }
      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        {loadingData ? (
          <Loading />
        ) : (
          <div className="calendar-section">
            <div className="calendar-header">
              {calendarData?.academic_year} Academic Year
            </div>
            <div className="calendar-accordions">
              {calendarData?.calendar_activities?.map(
                (calendarEvent, index) => (
                  <CalendarAccordion
                    calendarEvent={calendarEvent}
                    key={index}
                    isActiveSection={index === activeIndex}
                    setActiveIndex={setActiveIndex}
                    sectionIndex={index}
                    editable={true}
                    setActivityModal={setActivityModal}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CalendarActivities;
