import React from 'react'
import './AccordionItem.scss'
import {IoAddCircleOutline, IoRemoveCircleOutline} from 'react-icons/io5'

const AccordionItem = ({section, isActiveSection, setActiveIndex, sectionIndex}) => {
    const toggleSection = () => {
        const nextIndex = isActiveSection ? null : sectionIndex;
        setActiveIndex(nextIndex)
    }
  return (
    <div id="accordion-item" className={`${isActiveSection ? "accordion-item-open" : "accordion-item-close"}`}>
        <div className='accordion-item-title' onClick={toggleSection}>
            <p>
                {section.name}
            </p>
            {
                isActiveSection ? <IoRemoveCircleOutline /> : <IoAddCircleOutline />
            }
            
        </div>
        
        <div className="accordion-item-answer">
            {section.answer}
        </div>
        
    </div>
  )
}

export default AccordionItem