import React from "react";
import "./AdmissionModal.scss";
import { AiOutlineClose } from "react-icons/ai";
import Logo from "../../../assets/Anell.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";

const AdmissionModal = ({ admissionModal, setAdmissionModal }) => {
  const [enquiryData, setEnquiryData] = useState(null);
  const [makingEnquiry, setMakingEnquiry] = useState(false);

  const handleFormChange = (e) => {
    setEnquiryData({
      ...enquiryData,
      [e.target.name]: e.target.value,
    });
  };

  const makeAdmissionEnquiry = async (e) => {
    e.preventDefault(e);
    setMakingEnquiry(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token hdb=izS=cubqJq!TZNcP!1ryM2MuH-U6xaS?V-F9!tCr-dl1Mruh0/O8ZIjtOvC-TyHLoG99faARzzFNp/T3pe2fTp?AoL/GCcVy=unv9FD=dvBx9HkA=BUvG/dOVa74`,
      },
    };
    const body = JSON.stringify(enquiryData);
    console.log(body);

    try {
      await axios
        .post(`${api_url}admissions/enquiry/`, body, config)
        .then((response) => {
          setMakingEnquiry(false);
          if (response.status === 201) {
            Swal.fire({
              title: "Request Sent",
              text: "Admission request has been sent successfully. Please be checking your email address for the response. Thank you",
              icon: "success",
            }).then(() => {
              setAdmissionModal(!admissionModal);
            });
          }
        });
    } catch (error) {
      setMakingEnquiry(false);
      console.log(error);
    }
  };
  return (
    <div
      className="modal-overlay"
      onClick={() => setAdmissionModal(!admissionModal)}
    >
      <div className="modal-container" onClick={(e) => e.stopPropagation()}>
        <div
          className="close-button"
          onClick={() => setAdmissionModal(!admissionModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container">
          <div className="school-logo">
            <img src={Logo} alt="Anell" />
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>
          <div className="admission-modal-title">ADMISSION PORTAL</div>
          <p className="admission-modal-text">
            To ensure that there is space for you for the class you’d like to be
            admitted to first inquire from us to know available spaces
          </p>
          <form
            onSubmit={(e) => makeAdmissionEnquiry(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Full name</label>
                <input
                  required
                  type="text"
                  name="full_name"
                  id="full_name"
                  placeholder="Enter your name"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Contact</label>
                <input
                  required
                  type="text"
                  name="contact"
                  id="contact"
                  placeholder="Enter your contact"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Email</label>
                <input
                  required
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter your email"
                  onChange={(e) => handleFormChange(e)}
                />
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Class</label>
                <select
                  required
                  name="enquired_class"
                  onChange={(e) => handleFormChange(e)}
                >
                  <option value="">-Select Class-</option>
                  <option value="Creche">Creche</option>
                  <option value="Upper creche">Upper creche</option>
                  <option value="Nursery 1">Nursery 1</option>
                  <option value="Nursery 2">Nursery 2</option>
                  <option value="Kindergarten 1">Kindergarten 1</option>
                  <option value="Kindergarten 2">Kindergarten 2</option>
                  <option value="Grade 1">Grade 1</option>
                  <option value="Grade 2">Grade 2</option>
                  <option value="Grade 3">Grade 3</option>
                  <option value="Grade 4">Grade 4</option>
                  <option value="Grade 5">Grade 5</option>
                  <option value="Grade 6">Grade 6</option>
                </select>
              </div>
            </div>
            {makingEnquiry ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
            <p className="form-bottom-text">
              Already having verification code?{" "}
              <Link to="/admission/apply">Click here</Link> to start admission
              process.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdmissionModal;
