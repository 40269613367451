import axios from "axios";
import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { toast } from "react-toastify";
import { api_url } from "../../../url";
import Swal from "sweetalert2";
import { RotatingLines } from "react-loader-spinner";
import AuthContext from "../../../context/authContext";

const EditActivityModal = ({selectedActivity, editActivityModal, setEditActivityModal}) => {
  const {academicTerms} = useContext(AuthContext)
  const [data, setData] = useState(null);
  const [updatingActivity, setUpdatingActivity] = useState(false);

  const handleFormChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const updateActivity = async (e) => {
    e.preventDefault();
    if(!data){
      return toast.error("You have not updated any field")
    }
    setUpdatingActivity(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };

    const body = JSON.stringify(data);
    await axios
      .put(`${api_url}academics/term-activity/${selectedActivity.id}/`, body, config)
      .then((response) => {
        console.log(response?.data)
        if(response?.data?.success){
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Activity Updated Successfully",
          }).then(() => {
            setUpdatingActivity(false);
            setEditActivityModal(false);
            window.location.reload();
          });
        }else{
          toast.error("Couldn't update activity")
        }
        setUpdatingActivity(false)
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again later.")
        setUpdatingActivity(false);
      });
  };
  return (
    <div
      onClick={() => setEditActivityModal(!editActivityModal)}
      className="modal-overlay"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="modal-container min-w-50"
      >
        <div
          className="close-button"
          onClick={() => setEditActivityModal(!editActivityModal)}
        >
          <AiOutlineClose />
        </div>
        <div className="admission-modal-container ">
          <div className="school-logo">
            <div className="school-name">
              <div className="school-main-name">Anell</div>
              <div className="school-sub-name">SCHOOL OF EXCELLENCE</div>
            </div>
          </div>

          <p className="admission-modal-text">Update Activity</p>
          <form
            onSubmit={(e) => updateActivity(e)}
            className="admission-enquiry-form"
          >
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Academic Term</label>
                <select
                  name="academic_term"
                  id=""
                  onChange={(e) => handleFormChange(e)}
                  
                >
                  <option value="">Select Academic Term</option>
                  {academicTerms?.map((academic_term) => (
                    <option selected={selectedActivity?.academic_term === academic_term.id} key={academic_term.id} value={academic_term.id}>
                      {academic_term.term_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity Name</label>
                <input
                  required
                  type="text"
                  name="activity_name"
                  id="activity_name"
                  placeholder="Ex. Term’s lessons begins "
                  onChange={(e) => handleFormChange(e)}
                  value={data?.activity_name ? data?.activity_name : selectedActivity?.activity_name}
                />
              </div>
            </div>
            <div className="admission-enquiry-form-row">
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity Start Date</label>
                <input
                  required
                  type="date"
                  name="start_date"
                  id="start_date"
                  onChange={(e) => handleFormChange(e)}
                  value={data?.start_date ? data?.start_date : selectedActivity?.raw_start_date}
                />
              </div>
              <div className="admission-enquiry-form-input">
                <label htmlFor="">Activity End Date</label>
                <input
                  type="date"
                  name="end_date"
                  id="end_date"
                  onChange={(e) => handleFormChange(e)}
                  value={data?.end_date ? data?.end_date : selectedActivity?.raw_end_date}
                />
                <small>Leave blank if the activity is for only one day</small>
              </div>
            </div>

            <div className="admission-enquiry-form-row">
              <div className="">
                <label htmlFor="">
                  Is this activity an event?{" "}
                  <span style={{ fontSize: "12px" }}>
                    (This will make it show on the homepage of the website)
                  </span>{" "}
                </label>{" "}
                <br />
                <input
                  type="radio"
                  name="is_event"
                  value={true}
                  onChange={(e) => handleFormChange(e)}
                  defaultChecked={data?.is_event ? data.is_event === true : selectedActivity?.is_event === true}
                />{" "}
                Yes &nbsp; &nbsp;
                <input
                  type="radio"
                  name="is_event"
                  value={false}
                  defaultChecked={data?.is_event ? data.is_event === false : selectedActivity?.is_event === false}
                  onChange={(e) => handleFormChange(e)}
                />{" "}
                No
              </div>
            </div>
            {updatingActivity ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <RotatingLines
                  strokeColor="#021D53"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="26"
                  visible={true}
                />
              </div>
            ) : (
              <input type="submit" value="Submit" />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditActivityModal;
