import React from 'react'
import './MissionVision.scss'

const MissionVision = () => {
  return (
    <div className='mission-vision'>
        <div className="mission-vision-header">
            <h1>
                Our Mission, Vision & Core Values
            </h1>
            <div className="underline"></div>
        </div>
        <div className="statements">
            <div className="statement">
                <h4>Mission</h4>
                <p>
                ANELL’s MISSION is to provide quality education and childcare in a safe, child friendly and conducive environment that build a foundation for life – long learning.
                </p>
            </div>
            <div className="statement">
                <h4>Vision</h4>
                <p>
                Our VISION is to develop well rounded confident and responsible individuals who aspire to achieve their full potentials.
                </p> <br />
                <p>We do this by providing quality teaching and learning experience through supportive learning environment and engaging school culture in which everyone’s achievement are celebrated and no child is left behind in terms of academics.
                </p>
            </div>
            <div className="statement">
                <h4>Core Values</h4>
                <p>
                    We believe that ethics and good moral values are essential to the education of young children. 
                </p>
                <br />
                <p>
                    Our programme is designed to facilitate the teaching of values which include
                </p>
                <div className="lists">
                    <ul className='list'>
                        <li>Assertiveness</li>
                        <li>Descipline</li>
                        <li>Attention to detail</li>
                        <li>Humility</li>
                    </ul>
                    <ul className='list'>
                        <li>Empathy</li>
                        <li>Obedience</li>
                        <li>Tolerance</li>
                        <li>Perseverance</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MissionVision