import React, { useState } from "react";
import "./Navbar.scss";
import Logo from "../../assets/Anell.png";
import { LiaSearchSolid } from "react-icons/lia";
import { FaBars } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import NavItem from "../NavItem/NavItem";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [mobileView, setMobileView] = useState(false)
    useEffect(()=>{
      if(mobileView){
        document.body.style.overflow = 'hidden'
    }else{
        document.body.style.overflow = 'scroll'
    }
    })
    const NavItems = [
      {
        path:"/",
        name:"Home"
      },
      {
        path:"/about",
        name:"About",
        submenus:[
          {
            path:"/about/overview",
            name:"Overview"
          },
          {
            path:"/about/message-from-head",
            name:"Message from leadership"
          },
          {
            path:"/about/departments",
            name:"Departments"
          },
          {
            path:"/about/careers",
            name:"Careers"
          },
          {
            path:"/about/faq",
            name:"FAQ"
          },
        ]
      },
      {
        path:"/admission",
        name:"Admission"
      },
      {
        path:"/news-events",
        name:"News & Events",
        submenus:[
          {
            path:"/news-events/news",
            name:"News",
          },
          {
            path:"/news-events/calendar",
            name:"Calendar",
          }
        ]
      },
      {
        path:"/contact-us",
        name:"Contact Us"
      },
      {
        path:"/login",
        name:"Portal"
      }
    ]
  return (
    <div className="navigation-bar">
      <Link to="/" className="school-logo">
        <img src={Logo} alt="anell school of excellence" />
        <span className="text">
          <div className="main-text">
            <h2>Anell</h2>
            <span>
              <p>SCHOOL OF</p>
              <p>EXCELLENCE</p>
            </span>
          </div>
          <p className="motto">
            ...excel and lead!!!
          </p>
        </span>
      </Link>
      <div className="navigations">
        <ul className={`${mobileView && "active-navbar"} menu-list` }>
          {
            NavItems.map((item, index) => {
              return (
                <NavItem item={item} key={index} />
              )
            })
          }
          {/* <li className="active">Home</li>
          <li>About</li>
          <li>Admission</li>
          <li>Contact Us</li> */}
        </ul>
        {/* <Link to="/login" className="login-button">
            Portal          
        </Link> */}
        <div id="mobile" onClick={()=>setMobileView(!mobileView)}>
            {
                mobileView ? <FaTimes /> : <FaBars />
            }
        </div>
      </div>
    </div>
  );
};

export default Navbar;
