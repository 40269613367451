import React, { useEffect, useState } from "react";
import DashboardTopBar from "../../../components/PortalComponents/DashboardTopBar/DashboardTopBar";
import DashboardNavbar from "../../../components/PortalComponents/DashboardNavbar/DashboardNavbar";
import Loading from "../../../components/Loading/Loading";
import { Link } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import DashboardNavigation from "../../../components/PortalComponents/DashboardNavigation/DashboardNavigation";
import { api_url } from "../../../url";
import { toast } from "react-toastify";
import axios from "axios";
import parse from "html-react-parser";
import { RotatingLines } from "react-loader-spinner";
import Swal from "sweetalert2";
import NoData from "../../../components/PortalComponents/NoData/NoData";

const WebsiteNews = () => {
  const [loadingNews, setLoadingNews] = useState(true);
  const [newsList, setNewsList] = useState([]);
  const [deletingNews, setDeletingNews] = useState(false);

  const navigations = ["Dashboard", "News"];

  const getNewsList = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
      },
    };
    await axios
      .get(`${api_url}news/`, config)
      .then((response) => {
        setNewsList(response.data);
        console.log(response.data);
        setLoadingNews(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Unable to get list. Please refresh page.");
        setLoadingNews(false);
      });
  };

  const deleteNews = async (news_id) => {
    Swal.fire({
      title: `Are you sure?`,
      text: "Once deleted, there is no going back",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setDeletingNews(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${localStorage.getItem("access")}`,
          },
        };
        await axios
        .delete(`${api_url}news/${news_id}/`, config)
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Deleted",
            text: "News deleted successfully",
          })
            .then(() => {
              getNewsList();
              setDeletingNews(false);
            })
            .catch((error) => {
              console.error(error);
              setDeletingNews(false);
            });
        }); 
      }
    });
    

    
  };

  useEffect(() => {
    getNewsList();
  }, []);

  return (
    <div>
      <DashboardTopBar />
      <DashboardNavbar />

      <div className="portal-page">
        <div className="admin-dashboard-title">
          <DashboardNavigation navigations={navigations} />
        </div>
        <div className="section-list">
          <div className="table-section-header">News</div>
          <Link
            to="/admin/website-components/add-news/"
            className="primary-button-outline w-max-content"
            style={{ marginBlock: "5px" }}
          >
            Add News
          </Link>
          {loadingNews ? (
            <Loading />
          ) : (
            <div className="section-list-table">
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Event Date</th>
                      <th>Title</th>
                      <th>Details</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {newsList &&
                      newsList.map((news, index) => (
                        <tr key={index}>
                          <td>{news.date}</td>
                          <td>{news.title}</td>
                          <td>
                            {parseInt(news.details.length) > 150 ? (
                              <>{parse(news.details.slice(0, 150))}</>
                            ) : (
                              <>{parse(news.details)}</>
                            )}
                          </td>
                          <td>
                            {deletingNews ? (
                              <div style={{ textAlign: "center" }}>
                                <RotatingLines
                                  strokeColor="#021D53"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="26"
                                  visible={true}
                                />
                              </div>
                            ) : (
                              <div className="action-buttons">
                                <Link
                                  to={`/admin/website-components/news/${news.id}`}
                                  className="view-button"
                                >
                                  View <AiOutlineEye />
                                </Link>
                                <Link
                                  onClick={() => deleteNews(news.id)}
                                  className="delete-button"
                                >
                                  Delete <AiOutlineDelete />
                                </Link>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {
                  newsList.length <= 0 && (
                    <NoData />
                  )
                }
              </>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WebsiteNews;
