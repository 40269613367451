import React, {useState} from "react";
import "./CareerItem.scss";
import { IoAddCircleOutline, IoRemoveCircleOutline } from "react-icons/io5";

const CareerItem = ({ title }) => {
    const [openTab, setOpenTab] = useState(true)
  return (
    <div className="career-item" style={{backgroundColor:`${!openTab ? "#D9E5FC" : "#FFF"}`, height:`${openTab ? "auto":"10%"}`}}>
      <div className="career-item-header" onClick={()=>setOpenTab(!openTab)}>
        <div className="header-text">
          <h3>{title}</h3>
          {title === "Professional Development Studies" ? (
            <p>
              Anell School of Excellence is committed to offering a variety of
              professional development opportunities, including but not limited
              to:
            </p>
          ) : (
            <p>
              We recognize educators' aspirations for career growth. The
              following measures will be taken to facilitate career advancement:
            </p>
          )}
        </div>
        {
            openTab ? <IoRemoveCircleOutline /> : <IoAddCircleOutline />
        }
        
      </div>
      <div className="career-item-body" style={{display:`${!openTab ? "none" : "block"}`}}>
        {title === "Professional Development Studies" ? (
          <ul style={{listStyleType:"none", margin:"0"}}>
            <li>
              Workshops and Seminars: Regular workshops and seminars on teaching
              methodologies, technology integration, student engagement,
              classroom management, and other relevant topics.
            </li>
            <li>
              Conferences: Funding and support for educators to attend relevant
              local, national, and international conferences to network and
              learn from industry experts.
            </li>
            <li>
              Online Courses: Access to online courses and resources to enhance
              subject knowledge and pedagogical skills. 
            </li>
            <li>
              In-House Training: In-house training sessions conducted by
              experienced educators or external experts, tailored to address
              specific needs.
            </li>
            <li>
              Research and Publication: Support for educators interested in
              conducting educational research and publishing articles in
              reputable journals.
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              Internal Job Postings: Openings for leadership positions or
              specialized roles within the institution will be communicated to
              educators internally before being advertised externally.
            </li>
            <li>
              Mentorship Programs: Establishment of mentorship programs to pair
              experienced educators with those seeking career guidance.
            </li>
            <li>
              Professional Evaluation: Regular performance evaluations to
              identify educators' strengths, areas for improvement, and
              potential career paths.
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default CareerItem;
